import React from 'react'
import {
    useParams,
} from "react-router-dom";


import { useCookies } from 'react-cookie';
import registrationAPI from "../api/registrationAPI";
import {   ThreeDots } from "react-loader-spinner";
import moment from 'moment-timezone';
// eslint-disable-next-line
import localization from 'moment/locale/es'

moment.locale('es');

const AccountUpdateConfirm = () => {

    let accountId = useParams().accountId;
    let auth_code = useParams().auth_code;

    // eslint-disable-next-line
    const [cookies, setCookie] = useCookies(['alani360']);

    const [fetching, setFetching] = React.useState(false);
    const [result, setResult] = React.useState();


    const fnConfirm = async () => {
        setFetching(true);
        let res = await registrationAPI.accountInfoConfirmChange({
            accountId,
            auth_code
        });
        //reemplazar el cookie si el numero de telefono cambio
        if (res.success && res.phone) {
            setCookie('alani360', {
                phoneNumber: res.phone,

            }, { path: '/' });
        }

        setFetching(false);
        setResult(res);
    }


    /* eslint-disable */
    React.useEffect(() => {

        //alert(JSON.stringify({ accountId, auth_code }))
        fnConfirm();


    }, []);
    /* eslint-enable */


    return (
        <div>

            <header
                style={{
                    backgroundColor: "#FF9400"
                }}
            >
                <div style={{
                    margin: "auto",
                    maxWidth: 1200,
                    minWidth: 350,
                    flexDirection: "row",
                    display: 'flex',
                    justifyContent: "space-between",
                    alignContent: "center",
                    alignItems: "center",

                }}>
                    <div style={{ width: 80 }} />
                    <img alt='ALANI 360 logo'
                        src='/images/alani_logos_vertical_horizontal_orange_white.svg#svgView(viewBox(320, 90, 260, 70))'
                        style={{
                            width: 120, height: 60
                        }}
                    />
                    <div style={{ width: 100 }} />
                </div>
            </header>


            <main style={{
                margin: "auto",
                maxWidth: 1200,
                minWidth: 350,
                fontFamily: 'Montserrat',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column'

            }}>

                <h1>Confirmar</h1>

                <div
                    style={{
                        width: '100%',
                        marginBottom: 20
                    }}
                >
                    <hr style={{ margin: 0 }} />
                </div>


                {
                    fetching ?

                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: ' center',
                                alignItems: 'center'
                            }}
                        >
                            <ThreeDots
                                type="ThreeDots"
                                color="#FF9400"
                                height={30}
                                width={90} 
                            />
                            Confirmando
                        </div>
                        :
                        <div>
                            {result?.msg}
                            <br /><br />
                            <a href={`/p/${result?.token}/account`}>Mi Cuenta</a>
                        </div>
                }

            </main>
        </div>
    );
}


export default AccountUpdateConfirm;
