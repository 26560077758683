import React, { useState } from 'react';

import Styles from "../../styles";

import purchaseOrderAPI from "../../api/purchaseOrderAPI";
import { ThreeDots } from "react-loader-spinner";
import { useDispatch, useSelector } from 'react-redux';

import { useNavigate } from "react-router-dom";


import {
    IoEllipsisHorizontal
} from "react-icons/io5";


var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
 
});
const List = React.memo(({
    items
}) => {
    return <div
        style={{
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: '#c0c0c0',
        }}
    >
        {
            items.map((item, ix) => {
                return <div
                    key={ix}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: 5,
                        marginTop: 5,
                        padding: 5,
                        backgroundColor: ix % 2 ? "#c0c0c0" : "#fff"
                    }}
                >

                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                        <span style={{ fontWeight: 550 }}>{item.ref2}</span>
                        <span>{item.ref3}</span>
                        <span>{item.ref4}</span>
                        <span>{item.partly_description}</span>


                    </div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'right'
                    }}>
                        <span style={{ color: 'blue', fontWeight: 'bold' }}>{formatter.format(item.quotedTotal)}</span>
                        <span>{Math.round(((item.chargeable_weight_g) / 454))} lb</span>

                    </div>
                </div>
            })
        }
    </div>
})

const SendQuoteScreen = ({
    fnClose
}) => {

    const dispatch = useDispatch();
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        purchaseOrder,
        purchaseOrderItems,
        fetching,
    } = dataReducer;


    if (!purchaseOrder || !purchaseOrderItems) return null;


    const fnQuoteSend = async () => {

        dispatch({
            type: "FETCHING",
            fetching: "QUOTE_SENDING"
        });
        let res_send = await purchaseOrderAPI.quoteSend({
            rfqId: purchaseOrder.id,
        });

        if (res_send.success) {
            alert("Quote Sent")
            let res = await purchaseOrderAPI.poGet({
                purchaseOrderId: purchaseOrder.id
            });
            if (res.success) {
                dispatch({
                    type: 'PO_SET',
                    purchaseOrder: res.po,
                });
                dispatch({
                    type: 'PO_ITEMS_SET',
                    purchaseOrderItems: res.items
                });
            }
        } else {
            alert(res_send.msg);
        }


        dispatch({
            type: "FETCHING",
            fetching: null
        });
    }

    const items = purchaseOrderItems?.filter(item => (
        item.itemIsQuoted === 1
        &&
        item.approvedDateTime == null
        &&
        item.rejectedDateTime == null
    ))

    return <div
        style={{
            marginTop: 5
        }}
    >
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end'
        }}>


            <button
                onClick={() => fnClose()}
            >
                Close
            </button>
        </div>


        <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: 10,
            backgroundColor: "#808080",
            marginBottom: 10,
            marginTop: 10,

        }}>
            <div style={{
                flexDirection: 'column',
                display: 'flex',

            }}>
                <span style={{ color: '#fff' }}>{purchaseOrder.userName}</span>
                <span style={{ color: '#fff' }}>{purchaseOrder.userEmail}</span>
                <span style={{ color: '#fff' }}>{purchaseOrder.userPhone}</span>
            </div>
            <Styles.ButtonMain
                disabled={fetching === "QUOTE_SENDING"}
                onClick={() => {
                    if (window.confirm("Are you sure you wish to send the quote to the client?")) {
                        fnQuoteSend()
                    }
                }}
            >
                {fetching === "QUOTE_SENDING" ?

                    <ThreeDots
                        type={"ThreeDots"}
                        color="#000"
                        height={10}
                        width={35} 
                    />
                    :
                    "Send"

                }
            </Styles.ButtonMain>
        </div>

        <div
            style={{
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            <span>
                {purchaseOrder.customerReference}
            </span>
            <span>
                {purchaseOrder.customerName}
            </span>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row'
                }}
            >


                <span style={{ marginRight: 10 }}>
                    No Answer<br />{purchaseOrder.items_count["1"] ? purchaseOrder.items_count["1"]["items"] : 0}
                </span>
                <span style={{ marginRight: 10 }}>
                    Yes<br />{purchaseOrder.items_count["2"] ? purchaseOrder.items_count["2"]["items"] : 0}
                </span>
                <span style={{ marginRight: 10 }}>
                    No<br />{purchaseOrder.items_count["-1"] ? purchaseOrder.items_count["-1"]["items"] : 0}
                </span>
                <span style={{ marginRight: 10 }}>
                    Not Quoted<br />{purchaseOrder.items_count["0"] ? purchaseOrder.items_count["0"]["items"] : 0}
                </span>
            </div>

        </div>


        <div
            style={{
                marginTop: 10,
                marginBottom: 10,
            }}
        >

            <List
                items={items}
            />
        </div>

    </div>
}


const DownloadedFilesScreen = ({
    fnClose,
    downloadedFiles
}) => {



    return <div
        style={{
            marginTop: 5
        }}
    >
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end'
        }}>


            <button
                onClick={() => fnClose()}
            >
                Close
            </button>
        </div>

        <div style={{
            display: 'flex',
            flexDirection: 'column',
            margin: 20
        }}>
            <div style={{ marginBottom: 10 }}>Click to download the files below</div>

            <a target="_blank" href={`${downloadedFiles.quote}`} rel="noopener noreferrer">Quote</a>
            {downloadedFiles.no_price !== null &&

                <a target="_blank" href={`${downloadedFiles.no_price}`} rel="noopener noreferrer">Not Quoted</a>

            }
        </div>

    </div>
}


const RFQAdminOptions = ({
    fnHideMainWindow
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        purchaseOrder,
        fetching,
    } = dataReducer;
    const [toggleDisplay, setToggleDisplay] = useState(false);
    const [sendQuote, setSendQuote] = useState(false);
    const [downloadedFiles, setDownloadedFiles] = useState(false);
    const [downloading, setDownloading] = useState(false);

    const fnRFQClear = async ({
        clearPricing
    }) => {

        dispatch({
            type: "FETCHING",
            fetching: "RFQ_UPDATING"
        });

        let res_reset = await purchaseOrderAPI.rfqReset({
            rfqId: purchaseOrder.id,
            clearPricing
        });

        if (res_reset.success) {
            let res = await purchaseOrderAPI.poGet({
                purchaseOrderId: purchaseOrder.id
            });
            if (res.success) {
                dispatch({
                    type: 'PO_SET',
                    purchaseOrder: res.po,
                });
                dispatch({
                    type: 'PO_ITEMS_SET',
                    purchaseOrderItems: res.items
                });
            }
        } else {
            alert(res_reset.msg);
        }


        dispatch({
            type: "FETCHING",
            fetching: null
        });

    }
    const fnRFQDelete = async () => {
        if (window.confirm("Delete RFQ ?")) {
            dispatch({
                type: "FETCHING",
                fetching: "RFQ_DELETING"
            });

            let res_reset = await purchaseOrderAPI.rfqDelete({
                rfqId: purchaseOrder.id
            });
            dispatch({
                type: "FETCHING",
                fetching: null
            });
            if (res_reset.success) {
                let res = await purchaseOrderAPI.poGet({
                    purchaseOrderId: purchaseOrder.id
                });
                if (res.success) {
                    alert("RFQ deleted")
                    navigate("/admin/rfqs");
                }
            } else {
                alert(res_reset.msg);
            }
        }
    }

    const fnCreatePOs = async () => {

        dispatch({
            type: "FETCHING",
            fetching: "RFQ_UPDATING"
        });

        let res_cpos_create = await purchaseOrderAPI.cposAutoCreate({
            rfqId: purchaseOrder.id
        });

        if (res_cpos_create.success) {
            let res = await purchaseOrderAPI.poGet({
                purchaseOrderId: purchaseOrder.id
            });
            if (res.success) {
                dispatch({
                    type: 'PO_SET',
                    purchaseOrder: res.po,
                });
                dispatch({
                    type: 'PO_ITEMS_SET',
                    purchaseOrderItems: res.items
                });
            }
        } else {
            console.warn("res_cpos_create", res_cpos_create);
            alert(res_cpos_create.msg);
        }


        dispatch({
            type: "FETCHING",
            fetching: null
        });

    }
    const fnDownload = async () => {

        setDownloadedFiles({});
        setDownloading(true);
        fnHideMainWindow(true);

        let res_download = await purchaseOrderAPI.download({
            rfqId: purchaseOrder.id
        });

        setDownloadedFiles(res_download);
        setDownloading(false);


    }
    if (downloading) {
        return <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 20
        }}>
            <ThreeDots
                type={"ThreeDots"}
                color="#FF9400"
                height={20}
                width={70}
                timeout={15000}
            />
            Downloading
        </div>
    }
    if (downloadedFiles) {

        return <DownloadedFilesScreen
            downloadedFiles={downloadedFiles}
            fnClose={() => {
                setDownloadedFiles(false);
                fnHideMainWindow(false);
            }}
        />
    }

    if (sendQuote) {
        return <SendQuoteScreen
            fnClose={() => {
                setSendQuote(false);
                fnHideMainWindow(false);
            }}
        />
    }

    if (!toggleDisplay) return <div
        style={{
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'flex-end',
            padding: 5,
        }}
    >
        <Styles.ButtonCircular
            onClick={() => setToggleDisplay(true)}
        >
            <IoEllipsisHorizontal style={{ fontSize: 30 }} size={20} />
        </Styles.ButtonCircular>
    </div>

    if (fetching === "RFQ_UPDATING" || fetching === "RFQ_DELETING") {
        return <div
            style={{
                flexDirection: 'row',
                display: 'flex',
                justifyContent: 'flex-end',
                margin: 5
            }}
        >
            <ThreeDots
                type={"ThreeDots"}
                color="#000"
                height={20}
                width={50}
                timeout={25000}
            />
        </div>
    }

    return <div
        style={{
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'space-between',
            padding: 5,
            backgroundColor: '#c0c0c0',
            borderRadius: 5,

            paddingTop: 10,
        }}
    >

        <div
        >
            <Styles.ButtonDelete
                style={{
                    marginBottom: 5,
                    width: 100
                }}
                onClick={() => fnRFQDelete()}
            >
                <span style={{ fontSize: 12 }}>Delete RFQ</span>
            </Styles.ButtonDelete>
            <Styles.ButtonDelete
                style={{
                    marginBottom: 5,
                    width: 100
                }}
                onClick={() => {
                    if (window.confirm("Clear RFQ?")) {
                        fnRFQClear({ clearPricing: 1 })
                    }
                }}
            >
                <span style={{ fontSize: 12 }}>Clear All</span>
            </Styles.ButtonDelete>
            <Styles.ButtonDelete
                style={{
                    marginBottom: 5,
                    width: 100
                }}
                onClick={() => {
                    if (window.confirm("Clear replies?")) {
                        fnRFQClear({ clearPricing: 0 })
                    }
                }}
            >
                <span style={{ fontSize: 12 }}>Clear Replies</span>
            </Styles.ButtonDelete>
        </div>
        <div
            style={{
                flexDirection: 'row',
                display: 'flex',
            }}
        >


            <div
                style={{
                    marginLeft: 5,
                }}
            >
                <Styles.ButtonDetalles
                    style={{
                        marginBottom: 5,
                        width: 150
                    }}
                    onClick={() => {

                        fnHideMainWindow(true);
                        setSendQuote(true);
                    }}

                >
                    <span style={{ fontSize: 12 }}>Send Quote</span>
                </Styles.ButtonDetalles>
                <Styles.ButtonDetalles
                    style={{
                        marginBottom: 5,
                        width: 150
                    }}
                    onClick={() => {
                        if (window.confirm("Create all POs?")) {
                            fnCreatePOs()
                        }
                    }}
                >
                    <span style={{ fontSize: 12 }}>Create Dealer POs</span>
                </Styles.ButtonDetalles>

                <Styles.ButtonDetalles
                    style={{
                        marginBottom: 5,
                        width: 150
                    }}
                    onClick={() => {
                        if (window.confirm("Download RFQ XLS files?")) {
                            fnDownload();
                        }
                    }}
                >
                    <span style={{ fontSize: 12 }}>Download XLS</span>
                </Styles.ButtonDetalles>
            </div>
            <Styles.ButtonCircular
                style={{
                    backgroundColor: '#808080',
                    marginLeft: 10,
                }}
                onClick={() => setToggleDisplay(false)}
            >
                <IoEllipsisHorizontal style={{ fontSize: 20 }} />
            </Styles.ButtonCircular>
        </div>
    </div>
}



export default RFQAdminOptions;