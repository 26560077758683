import React from 'react'
import Menu from '../app/components/Menu'; 
import {
    useParams,  
    useSearchParams
} from "react-router-dom";

import Styles from '../styles'; 

import b2cAPI from "../api/b2c";


var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
 
});
const B2CPay = () => {
    /* eslint-disable */
    const [searchParams, setSearchParams] = useSearchParams();
    /* eslint-enable */
    const token = useParams().code;
    const [fetching, setFetching] = React.useState(true) 
    const [paying, setPaying] = React.useState(false) 
    const [data, setData] = React.useState({account: {},data:[{}]})
    
    const [ step, setStep ] = React.useState(1); 
 
    const fnFetchQuote = async ({
        token
    }) => { 
        let res = await b2cAPI.fetchQuote({
            token
        });
        return res
    }

    const fnPay = async ({
        token
    }) => { 
        setPaying(true);
        let res = await b2cAPI.alaniPay({
            token
        });

        setPaying(false);
        return res
    }

    /* eslint-disable */
    React.useEffect(() => {
       
        setFetching(true);
        fnFetchQuote({
            token
        }).then( res =>  {
            setFetching(false); 
 
            if (res.account.alaniPayComplete){
                setStep(2);
            }

            setData(res);
             
        }); 
        
    }, []);
    React.useEffect(() => { 
        setSearchParams({ step  }); 
    }, [step]);
    
/* eslint-enable */
 
 
    return (
        <div
            style={{ 
                height: '100%'
            }}
        >
            <Menu />
            <header
                style={{
                    backgroundColor: "#FF9400"
                }}
            >
                <div style={{
                    margin: "auto",
                    maxWidth: 1200,
                    minWidth: 350,
                    flexDirection: "row",
                    display: 'flex',
                    justifyContent: "space-between",
                    alignContent: "center",
                    alignItems: "center",

                }}>
                    <div style={{ width: 80 }} />
                    <img alt='ALANI 360 logo'
                        src='/images/alani_logos_vertical_horizontal_orange_white.svg#svgView(viewBox(320, 90, 260, 70))'
                        style={{
                            width: 120, height: 60
                        }}
                    />
                    <div style={{ width: 100 }} />
                </div>
            </header>


            <main style={{
                margin: "auto",
                maxWidth: 1200,
                minWidth: 350,
                fontFamily: 'Montserrat',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column'

            }}>

              
                <div style={{ fontSize: 22, fontWeight: 'bold', padding: 10, color: '#FF9400' }}>Alani Pay</div>

                

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignContent: 'center',
                        flexDirection: 'column',
                        padding: 15,
                        paddingTop: 0

                    }}
                >   
                <div
                    style={{
                        textAlign: 'justify'
                    }}
                >

 
             
            
            {fetching ?
                    <div>
                      
                        <br/>
                        <br/>
                        <br/>
                        Actualizando....
                    </div>
                :
                <div>
                {
                    data !== undefined ? 
                        <div style={{
                            width: 500,
                            textAlign: 'center', 
                        }}>
                            <b>
                            {step === 1 ?
                                "Confirma tu pago con AlaniPay"
                                :
                                <span>Gracias<br/>Tu orden está en camino !</span>
                            }
                            </b>
 
                            <br/>
                            <br/>

                            <div style={{
                                maxWidth: 350,
                                margin: 'auto',
                                textAlign: 'center'
                            }}>{data.data[0].name}</div>
                            {step === 1 &&
                                <div>
                                    <br/>
                                    <b>{formatter.format(data.data[0].msrp)}</b>
                                </div>
                            }
                            <br/>
                            <br/>


                            {data.account.name}
                            <br/>
                            227 36th Street
                            <br/>
                            Suite {data.account.suite}
                            <br/>
                            Doral, FL, 33178 

 
                        </div>
                    :
                    <div>
                        Not found
                    </div>
                }
                </div>
            }
             
               
                
  
           
             
                </div>
               
            </div>

            </main>
            {!fetching && step ===1 &&
            <footer
                    style={{
                        position: 'sticky',
                        bottom: 0,
                        padding: 10,
                        backgroundColor: '#c0c0c0',
                        display: 'flex', 
                        flexDirection: 'row'
                    }}
                > 

                {step === 1 && 
                    <Styles.ButtonMain 
                    style={{
                        width: 200,
                        margin: 'auto', 
                        backgroundColor: paying ? '#000' : '#FF9400',
                        color: paying ? '#fff' : '#000',
                    }}
                    disabled={paying}
                    onClick = {() => {
                        fnPay({
                            token
                        }).then(res => {
                            setStep(2); 
                        });
                    }}
                    
                    >
                    {paying ?
                        "Pagando..."
                        :
                        "Pagar"
                    }
                    
                    </Styles.ButtonMain>
                }

                </footer>
            }
        </div>
    );
}


export default B2CPay;
 