import React from 'react'
import { useParams, useNavigate } from "react-router-dom";

import { useTranslation, Trans } from 'react-i18next';

import { useCookies } from 'react-cookie';
import Menu from '../app/components/Menu';

///import { ThreeDots } from "react-loader-spinner";
///import { useParams } from "react-router-dom";
///import pageUserAPI from '../api/pageUserAPI'; 
import moment from 'moment-timezone';
// eslint-disable-next-line
import localization from 'moment/locale/es'

import { useSelector, useDispatch } from 'react-redux';

import {
    IoPersonCircle,
} from "react-icons/io5";
import { ThreeDots } from 'react-loader-spinner';
import AlaniHeaderLogo from '../app/components/AlaniHeaderLogo';
moment.locale('es');



const Home = () => {
    let token = useParams().token;
    let cookies = useCookies(['alani360']);
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const navigate = useNavigate();
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        userData,
    } = dataReducer;

    /* eslint-disable */
    React.useLayoutEffect(() => {
        let _phone = "";
        _phone = (cookies[0] && cookies[0].alani360 && ("+" + cookies[0].alani360.phoneNumber))

        dispatch({
            type: "PHONE_SET",
            phone: _phone
        });
        dispatch({
            type: "USER_DATA_FETCH",
            token
        });

    }, [token]);
    /* eslint-enable */


    let _phone = (userData && userData.accountInfo) ? userData.accountInfo.phoneNumber : {}

    return (
        <div>

            <Menu />
            <header
                style={{
                    backgroundColor: "#FF9400"
                }}
            >
                <div style={{
                    margin: "auto",
                    maxWidth: 650,
                    minWidth: 350,
                    flexDirection: "row",
                    display: 'flex',
                    justifyContent: "space-between",
                    alignContent: "center",
                    alignItems: "center",

                }}>
                    <div style={{ width: 80 }} />
                    <AlaniHeaderLogo />

                    <div style={{ width: 100 }} />
                </div>
            </header >


            <main style={{
                margin: "auto",
                maxWidth: 650,
                minWidth: 350,
                fontFamily: 'Montserrat',
            }}>

                <div
                    className='div-column'
                    style={{
                        margin: 10,
                    }}
                >

                    {
                        userData === null

                            ?
                            <div
                                className='div-column'
                                style={{
                                    height: 50,

                                }}
                            >
                                <ThreeDots color="#c0c0c0" height={15} />
                            </div>
                            :


                            <div
                                className='div-column'
                                style={{
                                    marginTop: 0,
                                    height: 50,
                                }}>

                                {userData?.success ?
                                    <button
                                        className='div-column'
                                        onClick={() => navigate(`/p/${token}`)}
                                        style={{
                                            textAlign: 'center',
                                            flex: 1,
                                            borderStyle: 'none',
                                            backgroundColor: 'transparent'
                                        }}>
                                        <div style={{
                                            textAlign: 'center',
                                            width: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            flexDirection: 'row'
                                        }}>

                                            <IoPersonCircle
                                                size={20}
                                                style={{
                                                    marginRight: 5
                                                }}
                                            />
                                            {userData.accountInfo.firstName} {userData.accountInfo.lastName}
                                            <br />
                                            {_phone}
                                        </div>
                                    </button>
                                    :
                                    <div style={{
                                        width: 350,
                                        textAlign: 'center',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-around'
                                    }}>
                                        <button
                                            style={{
                                                width: 120,
                                                borderRadius: 5,
                                                borderStyle: 'solid',
                                                borderWidth: 0.5,
                                                borderColor: 'orange',
                                                backgroundColor: '#FF9400',
                                                padding: 5
                                            }}
                                            onClick={() => navigate(`/registro/${token || ""}`)}
                                        >
                                            <span style={{
                                                color: '#fff',
                                                fontSize: 15
                                            }}>{t('Registro')}</span>
                                        </button>

                                        <button
                                            style={{
                                                width: 120,
                                                borderRadius: 5,
                                                borderStyle: 'solid',
                                                borderWidth: 0.5,
                                                borderColor: '#808080',
                                                backgroundColor: '#4A7DB2',
                                                padding: 5
                                            }}
                                            onClick={() => navigate(`/p/${token || ""}`)}
                                        >
                                            <span style={{
                                                color: '#fff',
                                                fontSize: 15
                                            }}>{t('Mi Cuenta')}</span>
                                        </button>
                                    </div>
                                }
                            </div>

                    }



                </div>

                <div style={{
                    padding: 10,
                    borderTopStyle: "solid",
                    borderTopWidth: 1,
                    borderTopColor: '#000'
                }}>

                    <div
                        className='div-row'
                        style={{
                            justifyContent: 'space-around',
                            maxWidth: 340,
                            margin: 'auto',
                        }}
                    >

                        <img
                            src="/images/alani_logos_vertical_horizontal.svg#svgView(viewBox(0, 240, 230, 200))"
                            alt="ALANI 360 Logo"
                            style={{
                                width: "auto",
                                height: 100
                            }}
                        />
                        <h1 style={{
                            textAlign: 'center',
                            width: 200
                        }}>{t('Abundancia')}</h1>
                    </div>



                    <Trans t={t}>HomePage1</Trans>
                    <br /><br />
                    <Trans t={t}>HomePage2</Trans>




                </div>

            </main>
        </div >
    );
}


export default Home;