import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import API from "../../api/cposAPI";

import Header from '../../app/AdminComponents/Header';

import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
///import { useParams } from "react-router-dom"; 

import moment from 'moment-timezone';
import Styles from "../../styles";


function toTitleCase(str) {
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}
var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});



const PurchaseOrderList = () => {
    const dataReducer = useSelector((state) => state.dataReducer);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {
        cpos,
    } = dataReducer;


    return <div
        style={{
            marginTop: 10,
        }}
    >
        <div
            style={{
                marginTop: 10,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}
        >


            <b>Purchase Orders</b>

            <Styles.ButtonMain

                onClick={() => {
                    dispatch({
                        type: "CPO_SET",
                        cPO: null
                    });
                    navigate("/admin/pos/new");
                }}
            >
                NEW PO
            </Styles.ButtonMain>
        </div>
        <div style={{
            marginTop: 10,
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: '#c0c0c0',
            borderBottomStyle: "none"
        }}>
            {cpos.map((po, ix) => {

                return <div key={ix}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        borderStyle: 'none',
                        backgroundColor: ix % 2 ? "#ffffff" : "#f8f8f8",

                        borderBottomStyle: 'solid',
                        borderBottomWidth: 1,
                        borderBottomColor: "#c0c0c0",
                        padding: 5
                    }}
                >
                    <div style={{ width: "100%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <a href={`/admin/pos/${po.id}`}>{po.poReference}</a>
                        <span>{toTitleCase(moment(po.createdDT).tz('America/New_York').format('MMMM DD HH:mm'))}</span>
                    </div>
                    <span>{po.statusStr}</span>
                    <span>{po.itemsCount || 0} items</span>
                </div>

            })}
        </div>
    </div>
}

const POS = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();


    const [fetching2, setFetching2] = useState(false);
    const [itemsNoPOs, setItemsNoPO] = useState([]);
    const [showItems, setShowItems] = useState(false);

    let fnPOListGet = async () => {
        setFetching2(true);
        let res = await API.fetchAll({ status: 1 });
        let res2 = await API.fetchItemsNoPO();
        setFetching2(false);

        dispatch({
            type: "CPOS_SET",
            cpos: res.client_pos
        });
        setItemsNoPO(res2.items_no_po || []);
    }

    /* eslint-disable */
    React.useEffect(() => {
        fnPOListGet();
    }, []);
    /* eslint-enable */

    if (!localStorage.getItem("token")) {
        navigate("/login")
        return null
    }

    return (
        <div>

            <Header />

            <Styles.PageTitle>Purchase Orders</Styles.PageTitle>

            <main style={{
                margin: "auto",
                maxWidth: 500,
                minWidth: 350,
                fontFamily: 'Montserrat',
                padding: 5
            }}>

                {fetching2 ?
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: 50
                    }}>
                        <ThreeDots
                            type={"ThreeDots"}
                            color="#FF9400"
                            height={20}
                            width={70}
                            timeout={5000}
                        />
                    </div>
                    :

                    <div>
                        <PurchaseOrderList
                        />

                        {itemsNoPOs.length > 0 &&
                            <div>
                                <button
                                    style={{
                                        marginTop: 10,
                                        backgroundColor: showItems ? "#fff" : "red",
                                        color: showItems ? "#000" : "#fff",
                                        borderStyle: 'solid',
                                        borderColor: "#000",
                                        borderWidth: 1,
                                        padding: 5,
                                        width: 250
                                    }}
                                    onClick={() => {
                                        setShowItems(!showItems)
                                    }}
                                >
                                    {itemsNoPOs.length} items Without PO
                                </button>

                                {showItems && <div
                                    style={{
                                        borderStyle: 'solid',
                                        borderWidth: 1,
                                        borderColor: "#000",
                                        marginTop: 10

                                    }}
                                >
                                    {itemsNoPOs.map((item, ix) => {

                                        return <div
                                            key={ix}
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                borderStyle: 'none',
                                                borderBottomStyle: 'solid',
                                                borderBottomWidth: 1,
                                                borderBottomColor: "#c0c0c0",
                                                padding: 5
                                            }}

                                        >
                                            <div style={{
                                                display: 'flex', flexDirection: 'row', justifyContent: 'space-between'
                                            }}>
                                                <span>{toTitleCase(moment(item.poCreateDate).tz('America/New_York').format('MMMM DD HH:mm'))}</span>
                                                <span>{toTitleCase(moment(item.approvedDateTime).tz('America/New_York').format('MMMM DD HH:mm'))}</span>
                                            </div>
                                            <span>{item.customerName} {item.userName}</span>
                                            <div style={{ width: '99%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

                                                <span style={{ flex: 4 }}>{item.ref1}<br />{item.ref4}</span>
                                                <span style={{ flex: 3 }}>{item.customerReference}</span>
                                                <span style={{ flex: 2, textAlign: 'right' }} >{formatter.format(item.quotedTotal)}</span>

                                            </div>
                                        </div>
                                    })

                                    }
                                </div>

                                }

                            </div>

                        }


                    </div>
                }


            </main>
        </div>
    );
}


export default POS;