import React, { useState } from 'react';
import Select from 'react-select'


import purchaseOrderAPI from "../../api/purchaseOrderAPI";
import cposAPI from "../../api/cposAPI";

import { ThreeDots } from "react-loader-spinner";
import Header from '../../app/AdminComponents/Header';

import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import moment from 'moment-timezone';
// eslint-disable-next-line
import localization from 'moment/locale/es'

import Styles from "../../styles";

import { useDispatch, useSelector } from 'react-redux';

import {
    IoCheckmarkCircle
} from "react-icons/io5";

import PODetails from "../../app/AdminComponents/PODetails";


//Modal.setAppElement('#ModalElemenft');

moment.locale('en');

var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});


function toTitleCase(str) {
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}


const ItemList = (
    {
        items,
        itemsSelected,
        viewOnly,
        allSelected,
        fn_setItemSelect
    }
) => {
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        purchaseOrder,
    } = dataReducer;



    const [itemSelection, SetItemSelect] = useState(itemsSelected || {});
    const setItemSelect = (itemId) => {
        let _itemSelection = { ...itemSelection };
        if (_itemSelection[itemId]) {
            delete _itemSelection[itemId];
        } else {
            _itemSelection[itemId] = 1;
        }

        SetItemSelect(_itemSelection);
        fn_setItemSelect(_itemSelection)
    }



    if (!items) return null

    return <div style={{

        borderStyle: 'solid',
        borderColor: '#808080',
        borderWidth: 1,
        borderBottomStyle: 'none'
    }}>
        {items.map((item, ix_item) => {

            return <button
                key={"ix_item" + ix_item}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: "100%",
                    borderStyle: "none",
                    borderBottomStyle: 'solid',
                    borderBottomWidth: 1,
                    borderBottomColor: '#808080',
                    margin: 0,
                    padding: 5,
                    backgroundColor:
                        allSelected ?
                            "#58825B80"
                            :
                            itemSelection[item.id] == null ? "#fff" : "#FF9400",

                }}
                onClick={() => {
                    if (viewOnly) {
                        alert("This item can't be removed")
                    } else {
                        setItemSelect(item.id)
                    }
                }}
            >


                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        width: "100%",
                    }}
                >
                    {!allSelected &&
                        <div>
                            {itemSelection[item.id] == null ?
                                <div style={{
                                    width: 22,
                                    height: 22,
                                    borderRadius: 11,
                                    borderStyle: 'solid',
                                    borderWidth: 1,
                                    borderColor: "#000"
                                }} />
                                :
                                <IoCheckmarkCircle
                                    size={22}
                                    style={{

                                    }}
                                />

                            }
                        </div>
                    }
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10, flex: 7, textAlign: 'left' }}>

                        <span>{item.ref4}</span>

                        <span

                            style={{
                                fontWeight: purchaseOrder.customerReference === item.rfqCustomerReference ? "bold" : "normal"
                            }}

                        >{item.rfqCustomerReference}</span>

                        {item.ref1}
                        <br />
                        {item.ref2}
                    </div>
                    <span style={{ flex: 3, textAlign: 'right' }}>{
                        toTitleCase(moment(item.approvedDateTime).tz('America/New_York').format('MMM DD HH:mm'))
                    }</span>


                </div>


            </button>

        })}
    </div>

}


const PONew = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        purchaseOrderItems,
        cpos,
        cPO
    } = dataReducer;

    const [cpoId, setCpoId] = React.useState(0);

    const [reference, setReference] = React.useState();
    const [itemsSelected, setItemsSelected] = React.useState(null);
    const [fetching2, setFetching2] = useState(false);

    const fnSavePO = async () => {

        if (!cpoId && (reference || "").trim() === "") {
            alert("Please enter a PO reference")
            return;
        }

        let itemId_array = [];
        for (var prop in itemsSelected) {
            if (itemsSelected[prop] === 1) {
                itemId_array.push(parseInt(prop));
            }
        }
        let payload = {
            cpoId,
            poReference: reference,
            itemId_array,
            itemId_array_remove: []
        }
        setFetching2(true);
        let res = await cposAPI.save(payload);

        setFetching2(false);
        if (res.success) {
            alert("OK");


        } else {
            alert(res.msg)
        }


    }



    const cpoFetch = async ({
        cpoId
    }) => {
        setCpoId(parseInt(cpoId));
        if (cpoId) {
            let res = await cposAPI.fetchById({ cpoId });

            dispatch({
                type: "CPO_SET",
                cPO: res.client_po
            });
            setReference(null);
        } else {
            dispatch({
                type: "CPO_SET",
                cPO: null
            });
        }


    }

    const items = purchaseOrderItems?.filter(item => item.approvedDateTime != null && item.approvedClientPoId == null);

    let options = [
        { value: '0', label: 'New Purchase Order' }
    ]
    if (cpos) {
        cpos.map(o => {
            options.push({
                value: o.id,
                label: o.poReference
            });
            return null;
        });
    }



    return <div style={{
        padding: 10,
        display: 'flex',
        flexDirection: 'column'
    }}>
        <div style={{
            marginBottom: 10
        }}>
            <button onClick={() => navigate(-1)} >&lt; Back</button>
        </div>


        <Select
            onChange={(v) => {
                cpoFetch({
                    cpoId: v.value
                });
            }}
            options={options}
            defaultValue={{ label: "New Purchase Order", value: 0 }}
        />


        {cPO == null ?
            <div>
                <label
                    style={styles.row__label}
                >
                    Purchase Order Reference
                    <input
                        type="text"
                        value={reference || ""}
                        style={{
                            width: 270,
                            marginBottom: 5
                        }}
                        onChange={e => setReference(e.target.value)}
                        maxLength={250}
                    />

                </label>
            </div>
            :
            <div style={{
                padding: 10,
                marginTop: 10,
                borderColor: '#000',
                borderWidth: 1,
                borderStyle: "solid",
                borderRadius: 10,
                backgroundColor: '#e0e0e0',
                display: 'flex',
                flexDirection: 'column'
            }}>
                <span>{cPO.poReference}</span>

                <span>{toTitleCase(moment(cPO.createdDT).tz('America/New_York').format('MMMM DD HH:mm'))}</span>

            </div>
        }



        <h2>Items</h2>
        <ItemList
            items={items}
            itemsSelected={[]}
            fn_setItemSelect={(v) => {
                setItemsSelected(v)
            }}
        />
        {
            cPO != null && <ItemList
                items={cPO.items}
                viewOnly={true}
                allSelected={true}
            />


        }


        <div
            style={{
                marginTop: 20
            }}
        >
            <Styles.ButtonMain
                disabled={fetching2}
                style={{
                    width: 150
                }}
                onClick={() => fnSavePO()}
            >
                {fetching2 ?
                    <ThreeDots
                        type="ThreeDots"
                        color="#fff"
                        height={10}
                        width={50}
                        timeout={10000}
                    />
                    :
                    <React.Fragment>Save P/O.</React.Fragment>
                }
            </Styles.ButtonMain>
        </div>
    </div>
}



const AddItemsToPO = ({
    setAddingItems,
}) => {
    const [itemsNoPOs, setItemsNoPO] = useState([]);
    const [fetched, setFetched] = useState(false);
    const [fetching2, setFetching2] = useState(false);

    const dataReducer = useSelector((state) => state.dataReducer);
    const cPurchaseOrder = dataReducer.cPO;


    const ItemsNoPOS = ({
        items,
        fnClose
    }) => {

        const dispatch = useDispatch();
        const [fetching3, setFetching3] = useState(false);
        const [itemSelection, SetItemSelect] = useState({});
        const setItemSelect = (itemId) => {
            let _itemSelection = { ...itemSelection };
            if (_itemSelection[itemId]) {
                delete _itemSelection[itemId];
            } else {
                _itemSelection[itemId] = 1;
            }

            SetItemSelect(_itemSelection);
        }

        const fnSavePO = async () => {

            let itemId_array = [];
            for (var prop in itemSelection) {
                if (itemSelection[prop] === 1) {
                    itemId_array.push(parseInt(prop));
                }
            }
            let payload = {
                cpoId: cPurchaseOrder.id,
                poReference: null,
                itemId_array,
                itemId_array_remove: []
            }
            setFetching3(true);
            let res = await cposAPI.save(payload);

            setFetching3(false);
            if (res.success) {
                dispatch({
                    type: "CPO_SET",
                    cPO: res.client_po
                });
                alert("OK");
                fnClose();
            } else {
                alert(res.msg)
            }


        }

        if (items.length === 0) return null;

        return <div
            style={{
                marginTop: 10
            }}
        >
            <div style={{
                marginTop: 10,
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: '#c0c0c0',
                borderBottomStyle: "none"
            }}>

                {items.map((item, ix) => {

                    return <div
                        key={ix}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            borderStyle: 'none',
                            backgroundColor: itemSelection[item.id] == null ? "#fff" : "#FF9400",
                            borderBottomStyle: 'solid',
                            borderBottomWidth: 1,
                            borderBottomColor: "#c0c0c0",
                            padding: 5
                        }}
                        onClick={() => {
                            setItemSelect(item.id)

                        }}
                    >
                        <div style={{ width: '99%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <span style={{ flex: 4 }}>{item.ref1}<br />{item.ref4}</span>
                            <span style={{ flex: 3 }}>{item.customerReference}</span>
                            <span style={{ flex: 2, textAlign: 'right' }} >{formatter.format(item.quotedTotal)}</span>

                        </div>
                    </div>
                })

                }

            </div>

            <Styles.ButtonMain
                style={{
                    marginTop: 10,
                    width: 150
                }}
                disabled={fetching3}
                onClick={() => fnSavePO()}
            >
                {fetching3 ?
                    <ThreeDots
                        type="ThreeDots"
                        color="#fff"
                        height={10}
                        width={50}
                        timeout={10000}
                    />
                    :
                    <React.Fragment>Save P/O</React.Fragment>
                }
            </Styles.ButtonMain>


        </div>
    }


    let fnFetch = async () => {
        setFetching2(true);
        let res = await cposAPI.fetchItemsNoPO();
        setFetching2(false);
        setItemsNoPO(res.items_no_po || []);
        setFetched(true)

    }

    if (fetching2) {
        return <ThreeDots
            type="ThreeDots"
            color="#EA4335"
            height={10}
            width={50}
            timeout={10000}
        />
    }

    if (fetched) {
        return <div>

            <button
                onClick={() => {
                    setFetching2(false);
                    setFetched(false);
                    setAddingItems(false);
                }}
            >Close</button>

            <div
                style={{
                    color: '#000',
                    marginTop: 10,
                    marginBottom: 5,
                }}
            >
                Purchase Order
                <span style={{ fontWeight: 'bold', color: '#FF9400', }}> {cPurchaseOrder.poReference}</span>
                <br />
                <b>Add Items</b>
            </div>

            <ItemsNoPOS
                items={itemsNoPOs}
                fnClose={() => {
                    setFetching2(false);
                    setFetched(false);
                    setAddingItems(false);
                }}
            />
        </div>
    }

    return <div
        style={{
            display: 'flex',
            justifyContent: 'flex-end'
        }}
    >
        <Styles.ButtonDetalles
            style={{
                width: 120,
                marginRight: 10
            }}
            onClick={() => {
                alert("SEND PO")
            }}
        >
            Send P/O
        </Styles.ButtonDetalles>
        <Styles.ButtonSecondary
            style={{
                width: 120
            }}
            onClick={() => {
                setAddingItems(true);
                fnFetch();
            }}
        >
            Add Items
        </Styles.ButtonSecondary>



    </div>
}


const POExisting = () => {
    const navigate = useNavigate();
    const dataReducer = useSelector((state) => state.dataReducer);
    const dispatch = useDispatch();
    let cPurchaseOrder = dataReducer.cPO;
    let cpos = dataReducer.cpos;
    const [itemsSelected, setItemsSelected] = React.useState(null);
    const [fetching2, setFetching2] = useState(false);
    const [poDetails, setPODetails] = React.useState({});


    const [addingItems, setAddingItems] = React.useState(false);
    /* eslint-disable */

    const [searchParams, setSearchParams] = useSearchParams();

    React.useEffect(() => {

        if (cPurchaseOrder) {
            let _itemsSelected = {};
            cPurchaseOrder.items.map(i => {
                _itemsSelected[i.id] = 1;
                return null;
            });
            setItemsSelected(_itemsSelected);
            setPODetails({
                poReference: cPurchaseOrder.poReference
            })
        }
    }, [cPurchaseOrder]);

    /* eslint-enable */



    const fnSavePO = async () => {

        let itemId_array = [];
        let itemId_array_remove = [];
        for (var prop in itemsSelected) {
            if (itemsSelected[prop] === 1) {
                itemId_array.push(parseInt(prop));
            }
        }
        cPurchaseOrder.items.map(i => {
            if (itemsSelected[i.id] === undefined) {
                itemId_array_remove.push(i.id);
            }
            return null

        });
        let payload = {
            cpoId: cPurchaseOrder.id,
            poReference: poDetails.poReference,
            itemId_array,
            itemId_array_remove
        }
        setFetching2(true);
        let res = await cposAPI.save(payload);
        dispatch({
            type: "CPOS_SET",
            cpos: cpos.map(c => (c.id === res.client_po.id) ? res.client_po : c)
        })


        setFetching2(false);

        if (res.success) {
            alert("OK!");

        } else {
            alert(res.msg)
        }

    }

    const fnDeletePO = async () => {

        if (window.confirm("Are you sure you wish to delete this PO?")) {

            setFetching2(true);
            let res = await cposAPI.delete({
                cpoId: cPurchaseOrder.id,
            });
            let res3 = await cposAPI.fetchAll({ status: 1 });
            setFetching2(false);

            dispatch({
                type: "CPOS_SET",
                cpos: res3.client_pos
            });


            if (res.success) {

                navigate(-1);
            } else {
                alert(res.msg);
            }


        }
    }

    if (!cPurchaseOrder || !itemsSelected) return null;



    return <div style={{
        padding: 10,
        display: 'flex',
        flexDirection: 'column'
    }}>



        {!addingItems &&
            <div>
                <div>
                    <button onClick={() => {
                        navigate(searchParams.get("back") || -1);
                    }} >&lt; Back</button>
                </div>

                <div
                    style={{
                        color: '#FF9400',
                        marginTop: 5,
                        marginBottom: 5,
                        fontWeight: 'bold'
                    }}
                >
                    Purchase Order {cPurchaseOrder.poReference}
                </div>

                <PODetails
                    fnSetPoDetails={(obj) => {
                        setPODetails(obj);
                    }}
                />
                <span >{
                    toTitleCase(moment(cPurchaseOrder.createdDT).tz('America/New_York').format('MMMM DD HH:mm'))
                }</span>



                <h2>Items</h2>
                <ItemList
                    items={cPurchaseOrder.items}
                    itemsSelected={itemsSelected}
                    fn_setItemSelect={(v) => setItemsSelected(v)}
                />


                <div
                    style={{
                        marginTop: 20,
                        marginBottom: 20,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}
                >

                    <Styles.ButtonDelete
                        disabled={fetching2}
                        style={{
                            width: 100,
                        }}
                        onClick={() => fnDeletePO()}
                    >
                        {fetching2 ?
                            <ThreeDots
                                type="ThreeDots"
                                color="#EA4335"
                                height={10}
                                width={50}
                                timeout={10000}
                            />
                            :
                            <React.Fragment>Delete</React.Fragment>
                        }
                    </Styles.ButtonDelete>
                    <Styles.ButtonMain
                        disabled={fetching2}
                        style={{
                            width: 120
                        }}
                        onClick={() => fnSavePO()}
                    >
                        {fetching2 ?
                            <ThreeDots
                                type="ThreeDots"
                                color="#fff"
                                height={10}
                                width={50}
                                timeout={10000}
                            />
                            :
                            <React.Fragment>Save</React.Fragment>
                        }
                    </Styles.ButtonMain>

                </div>

            </div>
        }


        <AddItemsToPO
            setAddingItems={setAddingItems}
        />



    </div >
}

const PurchaseOrder = () => {

    const purchaseOrderId = useParams().purchaseOrderId;
    const poId = useParams().poId;
    const [fetching2, setFetching2] = useState(false);
    const dispatch = useDispatch();
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        purchaseOrder,
        purchaseOrderItems
    } = dataReducer;
    const cPurchaseOrder = dataReducer.cPO;


    let fnPOGet = async () => {
        setFetching2(true);
        if (!purchaseOrderId) {
            //no se esta consultando un RFQ sino un PO directamente
            dispatch({
                type: 'PO_SET',
                purchaseOrder: {},
            });
            dispatch({
                type: 'PO_ITEMS_SET',
                purchaseOrderItems: []
            });
        } else {
            let res = await purchaseOrderAPI.poGet({
                purchaseOrderId
            });
            if (res.success) {
                dispatch({
                    type: 'PO_SET',
                    purchaseOrder: res.po,
                });
                dispatch({
                    type: 'PO_ITEMS_SET',
                    purchaseOrderItems: res.items
                });
            }
        }
        let res_cpo = await cposAPI.fetchById({ cpoId: poId });

        dispatch({
            type: "CPO_SET",
            cPO: res_cpo.client_po
        });

        setFetching2(false);

    }

    const cposFetch = async (args) => {
        let res = await cposAPI.fetchAll(args);
        dispatch({
            type: 'CPOS_SET',
            cpos: res.client_pos
        })

    }



    /* eslint-disable */
    React.useEffect(() => {
        fnPOGet();
        cposFetch({ status: 1 });
    }, []);
    /* eslint-enable */



    return (
        <div>

            <Header />

            <Styles.PageTitle>RFQ {purchaseOrder?.customerReference} &gt; Purchase Order</Styles.PageTitle>

            <main style={{
                margin: "auto",
                maxWidth: 500,
                minWidth: 350,
                fontFamily: 'Montserrat',

            }}>

                {(fetching2 ||
                    (
                        purchaseOrderId &&
                        (purchaseOrder == null || purchaseOrderItems == null)
                    )

                ) ?
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: 50
                    }}>
                        <ThreeDots
                            type={"ThreeDots"}
                            color="#FF9400"
                            height={20}
                            width={70}
                            timeout={5000}
                        />
                    </div>
                    :
                    <div>
                        {poId === "0" ?
                            <PONew />

                            :

                            <div>
                                <POExisting
                                    cPurchaseOrder={cPurchaseOrder}

                                />

                            </div>

                        }
                    </div>

                }
            </main>
        </div>
    );
}


export default PurchaseOrder;


const styles = {
    row: {
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        margin: 10
    },
    row__label: {
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'flex-start',
        marginTop: 10
    }
}
