import React, { useState } from 'react';

import emailAPI from "../../api/emailsAPI";

import Header from '../../app/AdminComponents/Header';

import { useParams, useNavigate, } from "react-router-dom";

import moment from 'moment-timezone';


import { ThreeDots } from "react-loader-spinner";
import Styles from "../../styles";

import { useDispatch, useSelector } from 'react-redux';

import {
    IoAttach
} from "react-icons/io5";

function toTitleCase(str) {
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}


const UpdatedItems = ({ items }) => {



    return <label
        style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: 5,
            color: "#488EBB",
            fontSize: 12
        }}
    >
        Updated Items

        {items.length === 0 &&
            <span>No Updated Items</span>
        }

        {items.map((item, ix) => {

            return <div
                key={ix}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                {(
                    ix === 0
                    ||
                    items[ix - 1].purchaseOrderId !== item.purchaseOrderId
                ) &&
                    <div
                        style={{
                            padding: 5
                        }}
                    >
                        <a href={`/admin/rfq/${item.purchaseOrderId}?v=api`} style={{ fontWeight: 'bold', color: 'blue', fontSize: 14 }}>RFQ {item.rfqReference} {item.customerName}</a>
                    </div>
                }

                <div style={{
                    marginLeft: 15,
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: ix % 2 ? "#e0e0e0" : "#c0c0c0",

                    padding: 5
                }}>
                    <span>{item.partno}</span>
                    <span style={{ color: "blue" }}>{item.part_name} {item.ref3}</span>


                    <div style={{
                        marginLeft: 10,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                    }}>
                        <span style={{ width: 20 }}>MSRP</span>
                        <span style={{ marginLeft: 10, width: 50, textAlign: 'right' }}>{item.msrp_old}</span>
                        <span style={{ width: 30, textAlign: 'right', fontSize: 20 }}> → </span>
                        <span style={{ width: 50, fontWeight: 'bold', textAlign: 'right' }}>{item.msrp_new}</span>
                    </div>
                </div>
            </div>
        })

        }
    </label >
}

const EmailForm = () => {

    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        email,
    } = dataReducer;


    /* eslint-disable */
    React.useEffect(() => {

    }, []);
    /* eslint-enable */

    return <div
        style={{
            width: "100%",
        }}
    >


        <b>{toTitleCase(moment(email.dateTimeCreated).tz('America/New_York').format('MMMM DD HH:mm'))}</b>

        <label
            style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: 5,
                color: "#488EBB",
                fontSize: 12
            }}
        >
            Sender
            <span style={{ color: "#000", fontSize: 16 }}>{email.senderWithFormat}</span>
        </label>

        <label
            style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: 5,
                color: "#488EBB",
                fontSize: 12
            }}
        >
            To
            <span style={{ color: "#000", fontSize: 16 }}>{email.recipient}</span>
        </label>

        <label
            style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: 5,
                color: "#488EBB",
                fontSize: 12
            }}
        >
            Subject
            <span style={{ color: "#000", fontSize: 16 }}>{email.subject}</span>
        </label>


        <label
            style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: 5,
                color: "#488EBB",
                fontSize: 12
            }}
        >
            Body
            <div style={{ color: "#000", fontSize: 16 }} dangerouslySetInnerHTML={{ __html: email.bodyHtml }} ></div>
        </label>

        {email.outgoing === 0 &&
            <UpdatedItems
                items={email.hapi_items}
            />
        }


        {
            email.attachments.length > 0 &&

            <div style={{
                display: 'flex',
                marginTop: 10
            }}>
                {email.attachments.map((a, ix) => <a
                    style={{
                        display: 'flex',
                        backgroundColor: "#e0e0e0",
                        flexDirection: 'row',
                        alignItems: 'center',
                        padding: 5,
                        paddingRight: 10,
                        borderRadius: 10,
                        textDecoration: "none"
                    }}
                    key={ix}
                    target="_blank"
                    rel="noopener noreferrer" href={`${a.url}`}
                >

                    <IoAttach size={25} />
                    <span  >{a.name}</span>
                </a>
                )}
            </div>

        }


    </div >

}

const Email = () => {

    const navigate = useNavigate();
    const emailId = useParams().emailId;
    const [fetching2, setFetching2] = useState(true);
    const dispatch = useDispatch();
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        email,
    } = dataReducer;

    let fnEmailGet = async () => {
        setFetching2(true);
        let res = await emailAPI.emailGetById({
            emailId
        });

        setFetching2(false);
        if (res.success) {


            dispatch({
                type: 'EMAIL_SET',
                email: res.email,
            });
        }
    }


    const [fetching, setFetching] = React.useState("");
    const fnDeleteEmail = async ({ emailId }) => {
        if (window.confirm("Are you sure you wish to delete this email?")) {
            setFetching("deleting_email");
            let res_delete = await emailAPI.deleteEmail({ emailId });
            console.warn(res_delete)
            if (res_delete.success) {
                alert("Email deleted");
                navigate("/admin/emails?s=emails_hapi");
            } else {
                alert(res_delete.message)
            }


        }
    }

    /* eslint-disable */
    React.useEffect(() => {

        fnEmailGet();
    }, []);
    /* eslint-enable */


    if (!localStorage.getItem("token")) {
        navigate("/login")
        return null
    }



    return (
        <div>

            <Header />

            <Styles.PageTitle>Email</Styles.PageTitle>

            <main style={{
                margin: "auto",
                maxWidth: 500,
                minWidth: 350,
                fontFamily: 'Montserrat',

            }}>




                {fetching2 ?
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: 50
                    }}>
                        <ThreeDots
                            type={"ThreeDots"}
                            color="#FF9400"
                            height={20}
                            width={70}
                            timeout={5000}
                        />
                    </div>
                    :


                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        margin: 10
                    }}>

                        <div
                            style={{
                                marginBottom: 10
                            }}
                        >
                            <button onClick={() => navigate(-1)} >&lt; Back</button>
                        </div>
                        {email != null &&
                            <div>
                                <EmailForm />


                                <Styles.ButtonDelete
                                    disabled={fetching === "deleting_email"}
                                    style={{ height: 30, marginRight: 20, marginTop: 10 }}
                                    onClick={() => fnDeleteEmail({ emailId: email.emailId })}
                                >
                                    {fetching === "deleting_email" ?
                                        <ThreeDots
                                            type={"ThreeDots"}
                                            color="#3482B3"
                                            height={10}
                                            width={50}
                                            timeout={5000}
                                        />
                                        :
                                        <span style={{ fontSize: 13, color: 'red', }}>Delete</span>
                                    }
                                </Styles.ButtonDelete>
                            </div>
                        }

                    </div>
                }
            </main>
        </div>
    );
}


export default Email;

