import React from 'react'
import {   ThreeDots } from "react-loader-spinner";
import Styles from "../styles";
import SearchAPI from "../api/SearchAPI";

const B2CPartSearch = () => {


    const [ref2, setRef2] = React.useState("31126863785");

    const [fetching2, setFetching2] = React.useState(false);
    const [result, setResult] = React.useState();


    const fnPartLookup = async ({
        partno
    }) => {
        setFetching2(true);
        let res = await SearchAPI.partLookup({
            partno,
            destinationCity: "BOG",
            customerId: 1,
            detailed_response: 1
        });
        setFetching2(false);

        setResult(res)

    }


    return (
        <div>

            <header
                style={{
                    backgroundColor: "#FF9400"
                }}
            >
                <div style={{
                    margin: "auto",
                    maxWidth: 1200,
                    minWidth: 350,
                    flexDirection: "row",
                    display: 'flex',
                    justifyContent: "space-between",
                    alignContent: "center",
                    alignItems: "center",

                }}>
                    <div style={{ width: 80 }} />
                    <img alt='ALANI 360 logo'
                        src='/images/alani_logos_vertical_horizontal_orange_white.svg#svgView(viewBox(320, 90, 260, 70))'
                        style={{
                            width: 120, height: 60
                        }}
                    />
                    <div style={{ width: 100 }} />
                </div>
            </header>
            <Styles.PageTitle>Search</Styles.PageTitle>

            <main style={{
                margin: "auto",
                maxWidth: 400,
                minWidth: 350,
                fontFamily: 'Montserrat',

            }}>
                <div style={{
                    padding: 10,
                    display: 'flex',
                    flexDirection: 'column'
                }}>


                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-end'
                        }}
                    >
                        <label  >
                            Part Number

                            <input
                                type="text"
                                value={ref2}
                                style={{
                                    width: 290,
                                    marginBottom: 5,
                                    marginRight: 5
                                }}
                                onChange={e => setRef2(e.target.value)}

                            />
                        </label>
                    </div>

                    <Styles.ButtonMain
                        style={{
                            width: 150
                        }}
                        onClick={() => fnPartLookup({
                            partno: ref2
                        })}
                    >
                        {fetching2 ?
                            <ThreeDots
                                type={"ThreeDots"}
                                color="#FFFFFF"
                                height={10}
                                width={30}
                                timeout={5000}
                            />
                            :

                            "Search"

                        }
                    </Styles.ButtonMain>
                </div>

                {
                    !result ?
                        ""
                        :
                        <div>
                            <h2>Autoparts</h2>
                            {result.part_api.item_id != null ?
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}>

                                    <span>{result.part_api.item_name}</span>
                                    <span>{result.part_api.item_description}</span>
                                    <span>MSRP {result.part_api.item_price}</span>

                                    {(result.part_api.item_photo || "") !== "" &&

                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <img alt={result.part_api.item_name} src={"https:" + result.part_api.item_photo} width="250" />

                                        </div>

                                    }
                                </div>
                                :
                                "NA"
                            }



                            <div style={{
                                display: 'flex',
                                flexDirection: 'column'
                            }}>
                                <h2>Partly</h2>
                                <span>{result.part_api.partly_name}</span>
                                <span>{result.part_api.partly_description}</span>
                                <span>{result.part_api.weight_g} wt gr</span>
                                {result.part_api.weight_dim_g &&
                                    <span>{result.part_api.weight_dim_g} dim wt gr</span>
                                }
                                {result.part_api.length_mm != null &&

                                    <span>
                                        {result.part_api.length_mm}mm x {result.part_api.height_mm}mm x {result.part_api.width_mm}
                                    </span>
                                }



                                {(result?.part_api?.res_partly?.gapc?.search_gapc_parts) &&
                                    result?.part_api?.res_partly?.gapc?.search_gapc_parts.items.map((part, ix) => {
                                        return <div key={ix} style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            borderLeftStyle: 'solid',
                                            borderLeftWidth: 1,
                                            borderLeftColor: '#808080',
                                            padding: 5,
                                            marginBottom: 5,
                                            marginTop: 5,
                                            borderRadius: 5
                                        }}>
                                            <span style={{ color: 'blue' }}>{part.name}</span>
                                            <span>{part.gapc_brand.name}</span>
                                            <span>{part.weight_g} g</span>
                                            <span>{part.width_mm}mm x {part.height_mm}mm x {part.length_mm}</span>
                                            <span>{part.rrp_price_usd}</span>
                                            <span style={{ fontSize: 12 }}>{JSON.stringify(part)}</span>

                                        </div>
                                    })
                                }

                            </div>


                            {result.cost_estimate != null &&
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: 300
                                }}>
                                    <h2>Costs</h2>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}><span style={{ flex: 1 }}>Price</span><span>{result.cost_estimate.msrp_withSurcharge} ({result.cost_estimate.percentSurcharge}%)</span></div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}><span style={{ flex: 1 }}>MSRP</span><span>{result.cost_estimate.msrp}</span></div>


                                    <div style={{ display: 'flex', flexDirection: 'row' }}><span style={{ flex: 1 }}>Shipping</span><span>{result.cost_estimate.shippingCost}</span></div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}><span style={{ flex: 1 }}>Customs</span><span>{result.cost_estimate.customsCost}</span></div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}><span style={{ flex: 1 }}>ETA</span><span>{result.cost_estimate.etaDays} d</span></div>



                                </div>
                            }




                        </div>
                }




            </main>
            <br />
            A003TB2691G

            <br />
            51417283832

            <br />

            67627406634
        </div>
    );
}


export default B2CPartSearch;