import React from 'react'
import Header from '../../app/AdminComponents/Header';

import { ThreeDots } from "react-loader-spinner";
import { useLocation } from "react-router-dom";
import Styles from "../../styles";
import SearchAPI from "../../api/SearchAPI";

import Select from 'react-select';

const queryString = require('query-string');
const options = [

    { value: 'acura', label: 'Acura' },

    { value: 'bmw', label: 'BMW' },
    { value: 'buick', label: 'Buick' },


    { value: 'cadillac', label: 'Cadillac' },
    { value: 'chevrolet', label: 'Chevrolet' },

    { value: 'jeep', label: 'Jeep' },

    { value: 'ford', label: 'Ford' },

    { value: 'gmc', label: 'GMC' },

    { value: 'honda', label: 'Honda' },
    { value: 'hyundai', label: 'Hyundai' },
    { value: 'hummer', label: 'Hummer' },

    { value: 'kia', label: 'Kia' },

    { value: 'landrover', label: 'Land Rover' },
    { value: 'lexus', label: 'Lexus' },

    { value: 'mazda', label: 'Mazda' },
    { value: 'mercedes', label: 'Mercedes' },

    { value: 'nissan', label: 'Nissan' },

    { value: 'oldsmobile', label: 'Oldsmobile' },

    { value: 'pontiac', label: 'Pontiac' },

    { value: 'subaru', label: 'Subaru' },

    { value: 'saturn', label: 'Saturn' },
    { value: 'toyota', label: 'Toyota' },

    { value: 'vw', label: 'Volkswagen' },



]

const styles_local = {
    "p_col": {
        margin: 0,
        padding: 0
    },
    "p_row": {
        paddingTop: 5,
        paddingBottom: 5,
        borderBottomStyle: 'solid',
        borderBottomColor: '#000',
        borderBottomWidth: 1,
        padding: 5
    }
}

const PartlyResults = ({
    result
}) => <div style={{
    display: 'flex',
    flexDirection: 'column',
    padding: 5
}}>
        <h2>Partly</h2>
        <span>{result.part_api.partly_name}</span>
        <span>{result.part_api.partly_description}</span>
        <span>{result.part_api.weight_g} wt gr</span>
        {result.part_api.weight_dim_g &&
            <span>{result.part_api.weight_dim_g} dim wt gr</span>
        }
        {result.part_api.length_mm != null &&

            <span>
                {result.part_api.length_mm}mm x {result.part_api.height_mm}mm x {result.part_api.width_mm}
            </span>
        }



        {(result?.part_api?.res_partly?.gapc?.search_gapc_parts) &&
            result?.part_api?.res_partly?.gapc?.search_gapc_parts.items.map((part, ix) => {
                return <div key={ix} style={{
                    display: 'flex',
                    flexDirection: 'column',
                    borderLeftStyle: 'solid',
                    borderLeftWidth: 1,
                    borderLeftColor: '#808080',
                    padding: 5,
                    marginBottom: 5,
                    marginTop: 5,
                    borderRadius: 5
                }}>
                    <span style={{ color: 'blue' }}>{part.name}</span>
                    <span>{part.gapc_brand.name}</span>
                    <span>{part.weight_g} g</span>
                    <span>{part.width_mm}mm x {part.height_mm}mm x {part.length_mm}</span>
                    <span>{part.rrp_price_usd}</span>
                    <span style={{ fontSize: 12 }}>{JSON.stringify(part)}</span>

                </div>
            })
        }

    </div>


const ScrapeResults = ({
    res,
    partno
}) => {


    if (res.length === 0) {
        return <div>No results</div>
    }
    return <div>
        {
            res.map((item, ix) => {
                let _partno = (item.partno_found || "").replaceAll(" ", "").replaceAll("-", "").toUpperCase();
                let partno_ = partno.replaceAll(" ", "").replaceAll("-", "").toUpperCase();

                return <div
                    key={ix}
                    style={{
                        ...styles_local.p_row,
                        backgroundColor: ix % 2 ? '#c0c0c0' : '#fff'
                    }}
                >
                    <a rel="noopener noreferrer" target="_blank" href={`${item.url}`} style={styles_local.p_col}>{item.name}</a>
                    <p style={{
                        ...styles_local.p_col,
                        fontWeight: _partno === partno_ ? 'bold' : 'normal',
                        color: _partno === partno_ ? 'green' : 'red'
                    }}>{item.partno_found}</p>
                    <p style={styles_local.p_col}>{item.msrp}</p>
                    <p style={styles_local.p_col}>{item.dim}</p>
                    <p style={styles_local.p_col}>{item.weight}</p>

                    {item.fitment && item.fitment.length > 0 &&
                        <div>
                            {
                                item.fitment.map((i, ix2) => <p key={ix2} style={styles_local.p_col}>{i}</p>)
                            }

                        </div>
                    }

                </div>
            })
        }
    </div>
}

const PartSearch = () => {

    const { search } = useLocation();
    let partNumber = (queryString.parse(search)).p;

    const [ref2, setRef2] = React.useState(partNumber);
    const [brand, setBrand] = React.useState();


    const [fetching2, setFetching2] = React.useState(false);
    const [result, setResult] = React.useState();
    const [scrapeResults, setScrapeResults] = React.useState({});
    const [section, setSection] = React.useState(1);



    const fnPartLookup = async ({
        partno
    }) => {
        setFetching2(true);
        setResult()
        /*
        let P1 = SearchAPI.partLookup({
            partno,
            destinationCity: "BOG",
            customerId: 1,
            detailed_response: 1
        });
        */
        let P2 = SearchAPI.scrape({
            partno,
            brand
        });

        let results = await Promise.all([P2]);
        let res = {
            part_api: {}
        } // results[1];
        let res2 = results[0];
        setScrapeResults(res2.results)
        setResult(res);

        setFetching2(false);


    }

    return (
        <div>

            <Header />
            <Styles.PageTitle>Search</Styles.PageTitle>

            <main style={{
                margin: "auto",
                maxWidth: 650,
                minWidth: 350,
                fontFamily: 'Montserrat',

            }}>
                <div style={{
                    padding: 10,
                    display: 'flex',
                    flexDirection: 'column'
                }}>

                    <Select
                        options={options}
                        //defaultValue={{ label: "Honda", value: "honda" }}
                        onChange={(val) => {
                            setBrand(val.value)
                        }}
                    />

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-end',
                            marginTop: 20
                        }}
                    >


                        <label  >
                            Part Number
                            <br />
                            <input
                                type="text"
                                value={ref2}
                                style={{
                                    width: 290,
                                    marginBottom: 5,
                                    marginRight: 5
                                }}
                                onChange={e => setRef2(e.target.value)}

                            />
                        </label>
                    </div>

                    <Styles.ButtonMain
                        style={{
                            width: 150
                        }}
                        onClick={() => {

                            if (!brand) {
                                alert("Seleccione la marca")
                                return;
                            }
                            let _partno = ref2.replaceAll(" ", "").replaceAll("-", "").toUpperCase()
                            setRef2(_partno);

                            fnPartLookup({
                                partno: _partno,
                                brand
                            })
                        }
                        }
                    >
                        {fetching2 ?
                            <ThreeDots
                                color="#FFFFFF"
                                height={10}
                                width={30}
                                timeout={30000}
                            />
                            :

                            "Search"

                        }
                    </Styles.ButtonMain>
                </div>

                {
                    !result ?
                        ""
                        :
                        <div

                        >
                            <div
                                style={{
                                    margin: 5
                                }}
                            >
                                <button
                                    style={{
                                        borderStyle: 'solid',
                                        borderColor: '#808080',
                                        borderWidth: 1,
                                        backgroundColor: section === 1 ? '#c0c0c0' : '#fff',
                                        marginRight: 10
                                    }}
                                    onClick={() => setSection(1)}
                                >
                                    Google
                                </button>
                                <button
                                    style={{
                                        borderStyle: 'solid',
                                        borderColor: '#808080',
                                        borderWidth: 1,
                                        backgroundColor: section === 2 ? '#c0c0c0' : '#fff',
                                        marginRight: 10
                                    }}
                                    onClick={() => setSection(2)}
                                >
                                    Partly
                                </button>

                                {result.cost_estimate != null &&
                                    <button
                                        style={{
                                            borderStyle: 'solid',
                                            borderColor: '#808080',
                                            borderWidth: 1,
                                            backgroundColor: section === 3 ? '#c0c0c0' : '#FF9400',
                                            marginLeft: 10
                                        }}
                                        onClick={() => setSection(3)}
                                    >
                                        <span style={{
                                            color: '#000',
                                        }}>Pricing</span>
                                    </button>
                                }
                            </div>
                            {{
                                "1": <ScrapeResults
                                    res={scrapeResults}
                                    partno={ref2}
                                />,
                                "2": <PartlyResults
                                    result={result}
                                />,
                                "3": <div>

                                    {result.cost_estimate != null &&
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            width: 300
                                        }}>
                                            <h2>Costs</h2>
                                            <div style={{ display: 'flex', flexDirection: 'row' }}><span style={{ flex: 1 }}>Price</span><span>{result.cost_estimate.msrp_withSurcharge} ({result.cost_estimate.percentSurcharge}%)</span></div>
                                            <div style={{ display: 'flex', flexDirection: 'row' }}><span style={{ flex: 1 }}>MSRP</span><span>{result.cost_estimate.msrp}</span></div>


                                            <div style={{ display: 'flex', flexDirection: 'row' }}><span style={{ flex: 1 }}>Shipping</span><span>{result.cost_estimate.shippingCost}</span></div>
                                            <div style={{ display: 'flex', flexDirection: 'row' }}><span style={{ flex: 1 }}>Customs</span><span>{result.cost_estimate.customsCost}</span></div>
                                            <div style={{ display: 'flex', flexDirection: 'row' }}><span style={{ flex: 1 }}>ETA</span><span>{result.cost_estimate.etaDays} d</span></div>



                                        </div>
                                    }
                                </div>

                            }[section]}










                        </div>
                }




            </main>

        </div>
    );
}


export default PartSearch;