import React from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { slide as MenuBurger } from 'react-burger-menu';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import pageUserAPI from "../../api/pageUserAPI";

import { useTranslation } from 'react-i18next';
import {
    IoPersonCircleOutline,
    IoHelpCircleSharp,
    IoLogOut
} from "react-icons/io5";
const Menu = () => {
    let token = useParams().token || "";

    const { t } = useTranslation();
    // eslint-disable-next-line
    const [cookies, setCookie] = useCookies(['alani360']);

    const [isOpen, setOpen] = React.useState(false)
    const handleIsOpen = () => {
        setOpen(!isOpen)
    }
    const navigate = useNavigate();

    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        userData
    } = dataReducer;

    const navigateTo = (pg) => {

        setOpen(false);
        setTimeout(() => {
            navigate(pg);
        }, 200)


    }
    /* eslint-disable */
    React.useEffect(() => {
        if (userData && userData.accountInfo) {
            if (userData.accountInfo.accountExists || userData.accountInfo.id) {
                console.warn("why cookie?")
                setCookie('alani360', {
                    token,
                    phoneNumber: userData.accountInfo.phoneNumber,

                }, { path: '/' });
            }
        }
    }, [userData]);
    /* eslint-enable */

    return <MenuBurger
        styles={styles}
        isOpen={isOpen}
        onOpen={handleIsOpen}
        onClose={handleIsOpen}
    >
        <a href="# " style={{ height: 0, visibility: 'hidden' }}>.</a>
        <div
            style={{
                position: 'relative'
            }}
        >
            <img alt='ALANI 360 logo'
                src='/images/alani_logos_vertical_horizontal_orange_white.svg#svgView(viewBox(320, 90, 260, 70))'
                style={{
                    width: 140, height: 70,
                    marginRight: 50,
                    borderStyle: "none",
                    alignSelf: 'center'
                }}
            />
            {process.env.REACT_APP_API_LANG === 'br' &&
                <img
                    alt="Brazilian flag"
                    src='/images/brazil.svg'
                    style={{
                        width: 25,
                        position: 'absolute',
                        right: 50,
                        bottom: 5

                    }}
                />
            }
        </div>
        <a href="# " id="home" className="menu-item" onClick={() => navigateTo(`/${token}`)} >ALANI 360</a>

        <a href="# " id="cuenta" className="menu-item" onClick={() => navigateTo(`/p/${token}`)}  >{userData?.accountInfo?.firstName || t('Mi Cuenta')} <IoPersonCircleOutline style={{ fill: '#fff', marginLeft: 5 }} size="22" /></a>



        {userData?.accountInfo?.id != null &&
            <a href="# " style={{ color: '#fff' }} id="compras" className="menu-item bm-item" onClick={() => navigateTo(`/pk/${token}`)}  >{t('Compras')}</a>

        }


        {userData?.accountInfo?.id != null &&
            <a href="# " style={{ color: '#fff' }} id="cuenta" className="menu-item bm-item" onClick={() => {


                let confirm = window.confirm(t('Estás seguro que quieres cerrar la sesión?'));
                if (confirm) {
                    pageUserAPI.logout({
                        token,
                        accountId: userData.accountInfo.id
                    }).then(r => {

                        if (r.success) {
                            navigateTo(`/p/`);
                        } else {
                            alert(r.message);
                        }

                    });
                }
            }}  >Cerrar Sesión<IoLogOut color={"#fff"} style={{ fill: '#fff', marginLeft: 5 }} size="22" /></a>
        }


        <a href="# "

            style={userData?.accountInfo?.id != null &&
            {
                position: 'absolute',
                bottom: 60
            }
            }
            id="about" className="menu-item" onClick={() => navigateTo(`/registracion/${token}`)}  >{t('Registro')}</a>
        <a href="# "
            style={userData?.accountInfo?.id != null &&
            {
                position: 'absolute',
                bottom: 20
            }
            }
            id="contact" className="menu-item" onClick={() => navigateTo(`/ayuda/${token}`)}  >{t('Ayuda')} <IoHelpCircleSharp style={{ fill: '#ffffff', color: '#ffffff', marginLeft: 5 }} size="22" /></a>

    </MenuBurger>
}

export default Menu;

var styles = {

    bmBurgerButton: {
        position: 'absolute',
        width: '30px',
        height: '20px',
        left: '10px',
        top: '20px'
    },
    bmBurgerBars: {
        background: '#373a47'
    },
    bmBurgerBarsHover: {
        background: '#a90000',
    },
    bmCrossButton: {
        height: '24px',
        width: '24px'
    },
    bmCross: {
        background: '#bdc3c7'
    },
    bmMenuWrap: {
        position: 'fixed',
        height: '100%',
    },
    bmMenu: {
        background: '#373a47',
        padding: '0 1.5em 0',
        fontSize: '1.15em'
    },
    bmMorphShape: {
        fill: '#373a47'
    },
    bmItemList: {
        color: '#b8b7ad',
        padding: '0.8em',
        display: 'flex',
        flexDirection: 'column'
    },
    bmItem: {
        display: 'flex',
        alignItems: 'center',
        color: '#fff',
        marginBottom: 5,
        marginTop: 5,
        paddingTop: 5,
        paddingBottom: 5,
        textDecoration: 'underline',
    },
    bmOverlay: {
        background: 'rgba(0, 0, 0, 0.3)',
    }
}