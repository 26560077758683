import React from 'react'
import { useParams, Link } from "react-router-dom";

import { useCookies } from 'react-cookie';
import { ThreeDots } from "react-loader-spinner";
import moment from 'moment-timezone';

// eslint-disable-next-line
import localization from 'moment/locale/es'

import { useTranslation, } from 'react-i18next';
import Select from "react-select";
import {
    MdOutlineShoppingBag,
    MdWarning
    /* MdZoomIn, MdZoomOut*/
} from "react-icons/md";

import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

import b2cAPI from '../../api/b2c';

import { useDispatch, useSelector } from 'react-redux';

import Styles from "../../styles/";
import pageUserAPI from "../../api/pageUserAPI";

moment.locale('es');


function toTitleCase(str) {
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}


const LinksEnviarFactura = ({
    CEL_WHATSAPP,
    NUMERO_WHATSAPP
}) => {

    const { t } = useTranslation();

    return <div style={{
        width: 300,
        margin: "auto",
        display: 'flex',
        flexDirection: 'column'
    }}>
        <div style={{ margin: 10, textAlign: 'center' }}>
            {t('EnviaPrealerta')}
        </div>

        <div
            className='div-col'
        >
            <div
                style={{
                    margin: 'auto',
                    width: 250,
                    textAlign: 'center'
                }}
            >
                Whatsapp <a href={CEL_WHATSAPP} >{NUMERO_WHATSAPP}</a>
            </div>

        </div>
    </div>
}

const Loading = () => {
    return <div
        style={{
            margin: 50,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}
    ><ThreeDots
            type="ThreeDots"
            color="#000"
            height={50}
            width={50}
            timeout={10000}
        /></div>
}


const PrealertaForm = ({
    packageId,
}) => {
    //let token = useParams().token;
    //const cookies = useCookies(['alani360']);
    //const phone_cookie = (cookies[0] && cookies[0].alani360 && ("+" + cookies[0].alani360.phoneNumber));
    //const dispatch = useDispatch();
    const [saving, setSaving] = React.useState(false);
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        userData,
    } = dataReducer;

    let paquetes = userData?.paquetes;
    let paquete = paquetes?.find((paquete) => {
        return paquete.id === packageId
    });

    const [sender, setSender] = React.useState(paquete.sender || "");
    const [trackingNumber, setTrackingNumber] = React.useState(paquete.trackingNumber || "");
    const [declaredValue, setDclaredValue] = React.useState(paquete.declaredValue || "");
    const [description, setDescription] = React.useState(paquete.description || "");
    const [carrier, setCarrier] = React.useState(
        paquete.carrier ? {
            value: paquete.carrier,
            label: paquete.carrier
        }
            :
            ""
    );


    const fnPrealertConfirm = () => {

        let errors = [];
        if (!sender) {
            errors.push("El campo remitente es requerido");
        }

        if (!carrier) {
            errors.push("El campo transportista es requerido");
        }
        if (!trackingNumber) {
            errors.push("El campo número de guía es requerido");
        }
        if (!declaredValue) {
            errors.push("El campo valor declarado es requerido");
        }
        if (isNaN(declaredValue)) {
            errors.push("El campo valor debe ser un número");
        }
        if (!description) {
            errors.push("El campo descripción es requerido");
        }

        if (errors.length) {
            alert(errors.join("\n"));
            return;
        }

        let params = {
            sender,
            tracking: trackingNumber,
            amount: declaredValue,
            description,
            carrier: carrier.value,
            packageId: paquete.id,
            accountId: userData.accountInfo.id
        }

        console.log("params", JSON.stringify(params));

        setSaving(true);
        b2cAPI.prealertConfirm(params).then((res) => {
            console.warn(res)
            if (res.success) {
                window.location.reload();
            } else {
                setSaving(false);
                alert(JSON.stringify(res.msg));
            }


            /*
             setSaving(false);
            dispatch({
                type: "USER_DATA_FETCH",
                phone: phone_cookie,
                token
            });*/
        });


    }

    if (paquete?.transExpressPackageId !== null) {
        return <div style={{
            textAlign: 'center',
        }}
        >
            Prealerta Confirmada
        </div>
    }


    return <div>
        <label
            className='label'
        >
            Sender
            <input
                autoCapitalize="characters"
                value={sender}
                onChange={(e) => { setSender(e.target.value) }}
                placeholder='Amazon'
            />
        </label>

        <Select
            onChange={e => {
                setCarrier(e);
            }}
            value={carrier}
            placeholder={'Carrier'}
            options={[
                { value: 'UPS', label: 'UPS' },
                { value: 'DHL', label: 'DHL' },
                { value: 'FEDEX', label: 'FEDEX' },
                { value: 'USPS', label: 'USPS' },
            ]}
        >
        </Select>
        <label
            className='label'
        >
            Tracking
            <input
                value={trackingNumber}
                autoCapitalize="characters"
                onChange={(e) => { setTrackingNumber(e.target.value.toUpperCase()) }}
                placeholder='1Z1234567890123456'
            />
        </label>

        <label
            className='label'
        >
            Descripción
            <input
                value={description}
                onChange={(e) => { setDescription(e.target.value) }}
                placeholder='iPhone 12 Pro Max'
            />
        </label>


        <label
            className='label'
        >
            Valor
            <input
                style={{
                    width: 80
                }}
                keyboardType="numeric"
                pattern="\d*"
                value={declaredValue}
                onChange={(e) => { setDclaredValue(e.target.value) }}
                placeholder='250'
            />
        </label>

        <Styles.ButtonMain
            onClick={fnPrealertConfirm}
            style={{
                margin: 'auto',
                width: 250,
                marginTop: 10
            }}
            disabled={saving}
        >
            {saving ? <ThreeDots width={50} color="#fff" /> : " Confirmar Prealerta"}

        </Styles.ButtonMain>
    </div>
}


const Paquetes = ({
    token,
}) => {
    let paqueteId = useParams().paqueteId;
    const cookies = useCookies(['alani360']);
    const phone_cookie = (cookies[0] && cookies[0].alani360 && ("+" + cookies[0].alani360.phoneNumber));
    const dispatch = useDispatch();
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        CEL_WHATSAPP,
        NUMERO_WHATSAPP,
        userData,
        fetching,
    } = dataReducer;

    let paquetes = userData?.paquetes;

    const [cancelarPrealerta, setCancelarPrealerta] = React.useState(false);
    const [Paquete, setPaquete] = React.useState(null);
    const [fetching2, setFetching2] = React.useState(false);



    /* eslint-disable */
    React.useLayoutEffect(() => {
        paquetes = userData?.paquetes;
        try {
            let _paquete = paquetes?.find((paquete) => {
                return paquete.id == paqueteId
            });

            if (_paquete) {
                setPaquete(_paquete);
            }
        } catch (e) {
            console.warn(e)
        }


    }, [userData]);
    /* eslint-enable */
    const fnCancelarPrealerta = async (id) => {
        setFetching2(true);
        await pageUserAPI.prealertaCancelar({
            prealertaId: id,
            token
        });
        setFetching2(false);
        setPaquete(null);
        setCancelarPrealerta(false);

        dispatch({
            type: "USER_DATA_FETCH",
            phone: phone_cookie,
            token
        });

        dispatch({
            type: "PAGE_SECTION_SET",
            section: "main"
        });

        window.history.replaceState("", "", `/pk/${token}`);
    }

    const PrealertaCancelar = () => <div
        style={{
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'center',
            width: 200
        }}
    >
        Está seguro que desea cancelar la prealerta? <br /><br />
        <button
            style={{
                width: 100
            }}
            disabled={fetching2}
            onClick={() => {
                fnCancelarPrealerta(Paquete.id);

            }}
        >
            {!fetching2 ?
                <span style={{ color: 'green' }}>Si</span>
                :
                <ThreeDots
                    type="ThreeDots"
                    color="#FF9400"
                    height={10}
                    width={50}
                    timeout={10000}
                />
            }

        </button>

        <button
            style={{
                width: 100,
                marginTop: 20
            }}
            disabled={fetching2}
            onClick={() => {
                setCancelarPrealerta(false);

            }}
        >
            <span style={{ color: 'red' }}>No</span>
        </button>
    </div>

    if (fetching || !paquetes) {
        return <Loading />
    }
    if (Paquete) {

        return <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <a href="#123"
                onClick={(e) => {
                    e.preventDefault()
                    setPaquete(null);
                    setCancelarPrealerta(false);
                    dispatch({
                        type: "PAGE_SECTION_SET",
                        section: "main"
                    });

                    window.history.replaceState("", "", `/pk/${token}`);
                }}
                style={{
                    color: 'blue',
                    marginBottom: 10
                }}
            >&lt; Compras</a>


            <div >
                {toTitleCase(moment(Paquete.status0).tz('America/New_York').format('MMMM DD HH:mm'))}
            </div>


            <span style={{
                color: 'blue',
                margin: 10
            }}
            > {Paquete.statusStr}</span>


            {Paquete.transExpressPackageId !== null && <div
                style={{ textAlign: 'center' }}
            >
                {Paquete.sender}
                <br />
                {Paquete.description}
                <br />
                {Paquete.trackingNumber}
            </div>

            }



            <div >


                {Paquete.status !== 0 &&

                    <div
                        style={{
                            padding: 5,
                            textAlign: 'center',
                        }}
                    >
                        <div
                        >
                            {Paquete.sender}
                        </div>
                        <div>
                            {Paquete.description}
                        </div>
                    </div>

                }
            </div>



            {Paquete.transExpressPackageId === null && Paquete.status === 0 ?
                <div>
                    {!cancelarPrealerta &&
                        <PrealertaForm
                            packageId={Paquete.id}
                        />
                    }

                    <div
                        style={{
                            textAlign: 'center',
                            marginTop: 10
                        }}
                    >
                        {!cancelarPrealerta ?
                            <a href="#123" onClick={(e) => {
                                e.preventDefault();
                                setCancelarPrealerta(true);
                            }}
                                style={{ color: 'red', fontSize: 15 }}
                            >Cancelar Prealerta</a>

                            :
                            <PrealertaCancelar />
                        }
                    </div>

                </div>




                :
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    margin: 0,
                    marginTop: 10,
                    alignItems: cancelarPrealerta ? 'center' : 'flex-start'
                }}>





                    <div>

                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}>


                            {!cancelarPrealerta && <div style={{
                                flexDirection: 'column',
                                alignContent: 'center',
                                alignItems: 'center',
                                display: 'flex'
                            }}>
                                {[
                                    {
                                        status: 0,
                                        name: "Prealerta"
                                    },
                                    {
                                        status: 1,
                                        name: "Recibido"
                                    },
                                    {
                                        status: 2,
                                        name: "En Tránsito"
                                    },
                                    {
                                        status: 3,
                                        name: "En Destino"
                                    },
                                    {
                                        status: 4,
                                        name: "Entregado"
                                    }

                                ].map((i, ix) => {

                                    return <div
                                        key={ix}

                                        style={{
                                            width: 50,
                                            height: 45,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                    >

                                        <div style={{
                                            top: 0,
                                            width: 110,
                                            backgroundColor: Paquete.status >= i.status ? '#FF9400' : '#c0c0c0',
                                            textAlign: 'center',
                                            borderRadius: 5,
                                            paddingTop: 5,
                                            paddingBottom: 5
                                        }}>{i.name}</div>
                                        <span style={{
                                            width: (i.status === 4) ? 0 : 2,
                                            backgroundColor: Paquete.status > i.status ? '#FF9400' : '#c0c0c0',
                                            height: 30
                                        }}></span>

                                    </div>
                                })}
                            </div>
                            }



                        </div>

                    </div>
                </div>

            }
            <div
                style={{
                    marginTop: 20
                }}
            >

                <Zoom>
                    <img

                        src={`${(Paquete.facturaURL === 'https://' || Paquete.facturaURL === null) ? 'https://public-alani.s3.amazonaws.com/alani_logo.jpg' : Paquete.facturaURL}`}
                        style={{
                            width: 100,
                            borderRadius: 5,
                        }}
                        alt="Factura"
                    />
                </Zoom>


            </div>


        </div >
    }



    if (!userData?.accountInfo.shippingAddressConfirmed) {

        return <div
            style={{ padding: 20, textAlign: 'center' }}
        >


            Debes <Link style={{ color: 'red' }} to={`/p/${token}/account?addr=1`}>confirmar</Link> tu
            dirección
            <br />
            de envío para poder enviar prealertas
        </div>
    }


    if (userData?.paquetes.length === 0) {

        return <div>

            <div
                style={{
                    textAlign: 'center',
                }}
            >
                No tienes compras pre alertadas
            </div>


            <LinksEnviarFactura
                CEL_WHATSAPP={CEL_WHATSAPP}
                NUMERO_WHATSAPP={NUMERO_WHATSAPP}
            />
        </div>
    }

    return <div
        style={{
            width: '100%',
        }}
    >

        <div style={{
            fontWeight: 'bold',
            margin: 'auto',
            textAlign: 'center'
        }}>Compras</div>
        {paquetes.map((paquete, ix) => {

            return <div
                key={ix}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    alignContent: 'center',
                    alignItems: 'center',
                    textAlign: 'left',
                    paddingTop: 5,
                    paddingBottom: 5,
                    marginTop: 10, marginBottom: 10,
                    backgroundColor: ix % 2 ? "#fff" : "#f8f8f8"
                }}
            >

                <div style={{
                    width: 220
                }}>
                    {paquete.sender !== null &&
                        <div>
                            {paquete.sender}
                        </div>}
                    <span style={{
                        color: 'blue',
                        fontWeight: 'bold'
                    }}>{paquete.statusStr} {JSON.stringify(paquete.id)}</span>
                    <br />
                    {toTitleCase(moment(paquete["status" + paquete.status]).tz('America/New_York').format('MMMM DD HH:mm'))}

                    {paquete.transExpressPackageId === null && paquete.status === 0 && <div
                        className='div-row'
                        style={{ color: 'red', fontSize: 15 }}
                    >
                        <MdWarning
                            fill={'red'}
                            size={15}
                        /> Prealerta Pendiente
                    </div>}
                </div>


                <Styles.ButtonDetalles
                    onClick={() => {
                        dispatch({
                            type: "PAGE_SECTION_SET",
                            section: "prealerta_detalles"
                        })
                        setPaquete(paquete);
                        window.history.replaceState("", "", `/pk/${token}/${paquete.id}`);
                        //navigate(`/pk/${token}/${paquete.id}`)
                    }}
                >
                    Detalles
                    <MdOutlineShoppingBag
                        size={25}
                        style={{
                            fill: 'blue',
                            color: 'blue',
                            marginLeft: 10
                        }}
                    />
                </Styles.ButtonDetalles>



            </div>
        })}

        <div style={{
            width: '100%',
            marginTop: 20,
            marginBottom: 20
        }}>
            <hr />
        </div>

        <LinksEnviarFactura
            CEL_WHATSAPP={CEL_WHATSAPP}
            NUMERO_WHATSAPP={NUMERO_WHATSAPP}
        />
    </div >
}






const PaquetesAll = ({
    token
}) => {


    return <div>
        <Paquetes
            token={token}
        />

    </div>

}

export default PaquetesAll;