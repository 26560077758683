import React, { useState } from 'react'
import { useParams, useNavigate } from "react-router-dom";


///import { useCookies } from 'react-cookie';
///import Menu from '../app/components/Menu';

import {   ThreeDots } from "react-loader-spinner";
///import { useParams } from "react-router-dom";

// eslint-disable-next-line
import localization from 'moment/locale/es'

import { useSelector, } from 'react-redux';

import purchaseOrderAPI from "../api/purchaseOrderAPI";
import Styles from "../styles";


import moment from 'moment-timezone';
moment.locale('es');

var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

moment.locale('es');

function toTitleCase(str) {
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}


const TABS = [
    "Todos",
    "Pendientes",
    "Aceptados",
    "Rechazados",
]

const POQuote = () => {
    const navigate = useNavigate();
    let quoteCode = useParams().quoteCode;
    ///let cookies = useCookies(['alani360']);

    const dataReducer = useSelector((state) => state.dataReducer);
    const [purchaseOrder, setPurchaseOrder] = React.useState();
    const [selectedTab, setSelectedTab] = React.useState(0);
    const [fetching2, setFetching2] = useState(false);
    const [itemsCount, setItemsCount] = React.useState({});


    const {
        display_message,
    } = dataReducer;

    let fnPOGet = async () => {
        setFetching2(true);
        let res = await purchaseOrderAPI.fetch({
            t: "ref",
            ref: quoteCode
        });
        setFetching2(false);
        setPurchaseOrder(res);
    }

    /* eslint-disable */
    React.useEffect(() => {
        fnPOGet();
    }, []);
    React.useEffect(() => {
        if (purchaseOrder) {
            //fnInitPoItems({ po_items: purchaseOrder.po_items });
            let items_count = {
                0: 0,
                1: 0,
                2: 0,
                3: 0
            }
            if (purchaseOrder.po_items) {
                purchaseOrder.po_items.map(e => {
                    items_count[0] += 1;
                    if (e.approvedDateTime == null && e.rejectedDateTime == null) items_count[1] += 1;
                    if (e.approvedDateTime !== null) items_count[2] += 1;
                    if (e.rejectedDateTime !== null) items_count[3] += 1;
                });
            }
            setItemsCount(items_count)

        }
    }, [purchaseOrder]);
    /* eslint-enable */




    return (
        <div>

            <header
                style={{
                    backgroundColor: "#FF9400"
                }}
            >
                <div style={{
                    margin: "auto",
                    maxWidth: 1200,
                    minWidth: 350,
                    flexDirection: "row",
                    display: 'flex',
                    justifyContent: "space-between",
                    alignContent: "center",
                    alignItems: "center",

                }}>
                    <div style={{ width: 80 }} />
                    <img alt='ALANI 360 logo'
                        src='/images/alani_logos_vertical_horizontal_orange_white.svg#svgView(viewBox(320, 90, 260, 70))'
                        style={{
                            width: 120, height: 60
                        }}
                    />
                    <div style={{ width: 100 }} />
                </div>
            </header>


            <main style={{
                margin: "auto",
                maxWidth: 1200,
                minWidth: 350,
                fontFamily: 'Montserrat',
            }}>



                {fetching2 &&
                    <Styles.ContentDiv>
                        <ThreeDots
                            type="ThreeDots"
                            color="#FF9400"
                            height={10}
                            width={150}
                            timeout={10000}
                        />
                    </Styles.ContentDiv>
                }

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignContent: 'center',
                        flexDirection: 'column'
                    }}
                >



                    <h1>
                        {purchaseOrder
                            ?
                            `Orden de Compra ${purchaseOrder.po?.customerReference}`
                            :
                            ""
                        }
                    </h1>

                </div>


                <div style={{
                    padding: 10,
                    borderTopStyle: "solid",
                    borderTopWidth: 1,
                    borderTopColor: '#000',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'
                }}>

                    {display_message != null &&
                        <span style={{
                            margin: 10
                        }}>{display_message}</span>
                    }

                    <div
                        style={{ display: 'flex', flexDirection: 'row' }}
                    >


                        <Styles.ButtonDetalles
                            onClick={() => navigate(`/po/${quoteCode}`)}
                            style={{
                                marginLeft: 10
                            }}
                        >
                            <span style={{
                                marginRight: 20,
                                marginLeft: 20
                            }}>Solicitud &gt;</span>
                        </Styles.ButtonDetalles>

                    </div>
                </div>




                {purchaseOrder && purchaseOrder.po &&
                    <div style={{
                        padding: 10,
                        borderTopStyle: "solid",
                        borderTopWidth: 1,
                        borderTopColor: '#000',
                        marginBottom: 50
                    }}>

                        <Styles.ContentDiv>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: 380
                            }}>

                                <div style={styles.row}>
                                    <div style={styles.col}>
                                        <span style={{
                                            fontWeight: 'bold',
                                            color: '#FF9400'
                                        }}>{purchaseOrder.po.customerName}</span>

                                        <span>{purchaseOrder.po.userName}</span>
                                    </div>

                                    <div style={styles.col}>
                                        <span style={{
                                            fontWeight: 'bold',
                                            color: '#FF9400'
                                        }}>{purchaseOrder.po.customerReference}</span>

                                        <span>{toTitleCase(moment(purchaseOrder.po.poCreateDate).tz('America/New_York').format('MMMM DD HH:mm'))}</span>
                                    </div>
                                </div>
                                <div style={styles.col}>
                                    <span>{purchaseOrder.po.userEmail}</span>
                                    <span>{purchaseOrder.po.userPhone}</span>
                                </div>










                                <div
                                    style={{
                                        backgroundColor: '#ffffff'
                                    }}
                                >
                                    <div
                                        style={{
                                            ...{
                                                marginTop: 10,
                                            },
                                            ...styles.tabs
                                        }}
                                    >

                                        {TABS.map((t, ix) => {

                                            return <button
                                                key={ix}
                                                onClick={() => setSelectedTab(ix)}
                                                style={selectedTab === ix ? {
                                                    ...styles.tab,
                                                    ...styles.tab__selected
                                                } : styles.tab}
                                            >{t} {itemsCount[ix]}</button>

                                        })}

                                    </div>
                                    <div style={{
                                        textAlign: 'center',
                                        padding: 5,
                                        margin: 0,
                                    }}>
                                        {
                                            itemsCount[selectedTab] === 0 ?
                                                <div>
                                                    <br /><br />
                                                    No hay items {TABS[selectedTab]}
                                                </div>
                                                :
                                                `${itemsCount[selectedTab]} item(s)`
                                        }
                                    </div>
                                </div>

















                                <div>
                                    {purchaseOrder.po_items && purchaseOrder.po_items.map((e, ix) => {
                                        if (selectedTab !== 0) {

                                            if (selectedTab === 1 && (e.approvedDateTime !== null || e.rejectedDateTime !== null)) return null;
                                            if (selectedTab === 2 && e.approvedDateTime === null) return null;
                                            if (selectedTab === 3 && e.rejectedDateTime === null) return null;
                                        }
                                        return <div
                                            key={ix}
                                            style={{
                                                ...styles.row,
                                                ...{
                                                    backgroundColor: (e.approvedDateTime != null) ?
                                                        "#00800050" :
                                                        (e.rejectedDateTime != null) ?
                                                            "#FF000050"
                                                            :
                                                            "#e0e0e0"

                                                }
                                            }}
                                        >
                                            <div
                                                style={styles.col}
                                            >
                                                <span>{e.c_name} {e.destinationCity}</span>
                                                <span>{e.ref1}</span>
                                                <span>{e.ref2}</span>
                                                <span>{e.ref3}</span>
                                                <span>{e.ref4}</span>
                                                <span>{e.ref5}</span>
                                            </div>


                                            {e.quotedTotal == null ?
                                                <div
                                                    style={{
                                                        width: 60
                                                    }}
                                                ></div>
                                                :
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    <span style={{ fontWeight: 'bold', }}>{formatter.format(e.quotedTotal)}</span>

                                                    <span>{e.etaDays} dias</span>

                                                    {
                                                        (
                                                            e.approvedDateTime != null ||
                                                            e.rejectedDateTime != null
                                                        )
                                                            ?
                                                            <div>

                                                                {toTitleCase(moment(e.approvedDateTime || e.rejectedDateTime).tz('America/New_York').format('MMM DD HH:mm'))}

                                                            </div>
                                                            :
                                                            <div>
                                                                <span>Sin Respuesta</span>
                                                            </div>

                                                    }




                                                </div>



                                            }

                                        </div>
                                    })}
                                </div>




                            </div>





                        </Styles.ContentDiv>



                    </div>
                }


            </main>
        </div>
    );
}


export default POQuote;


const styles = {
    tabs: {
        display: 'flex',
        flexDirection: 'row',

    },
    tab: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: '#c0c0c0',
        padding: 5,
        textAlign: 'center',
        borderTopRightRadius: 5,
        borderTopLeftRadius: 5,
        borderWidth: 1,
        borderColor: '#808080',
        borderStyle: 'solid',
        marginRight: 3,
        fontSize: 14

    },
    tab__selected: {
        borderBottomColor: "#fff",
        backgroundColor: '#e0e0e0'
    },

    footer: {
        position: "fixed",
        bottom: 0,
        backgroundColor: "#3482B3",
        right: 0,
        left: 0,
        height: 50,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center'
    },
    col: {
        display: 'flex',
        flexDirection: 'column',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        minWidth: 330,
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 5,
        marginBottom: 5,
        padding: 5
    }
}