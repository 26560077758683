import React, { useState } from 'react';
import API from "../../api/usersAPI";
import { ThreeDots } from "react-loader-spinner";
import Header from '../../app/AdminComponents/Header';
import { useParams, useNavigate } from "react-router-dom";
import moment from 'moment-timezone';

import Styles from "../../styles";

import { useDispatch, useSelector } from 'react-redux';

function toTitleCase(str) {
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}



const User = () => {
    const navigate = useNavigate();

    const userId = useParams().userId;
    const [fetching2, setFetching2] = useState(false);
    const dispatch = useDispatch();
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        user
    } = dataReducer;

    let fnDataGet = async () => {
        setFetching2(true);
        let res = await API.userFetch({
            userId
        });

        setFetching2(false);
        if (res.success) {
            dispatch({
                type: 'USER_SET',
                user: res.user
            });
        }
    }

    /* eslint-disable */
    React.useEffect(() => {
        fnDataGet();
    }, []);
    /* eslint-enable */


    return (
        <div>

            <Header />

            <Styles.PageTitle>Business User</Styles.PageTitle>

            <main style={{
                margin: "auto",
                maxWidth: 500,
                minWidth: 350,
                fontFamily: 'Montserrat',

            }}>

                <div
                    style={{
                        padding: 10
                    }}
                >
                    <button onClick={() => navigate(-1)} >&lt; Back</button>
                </div>

                {fetching2 || !user ?
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: 50
                    }}>
                        <ThreeDots
                            type={"ThreeDots"}
                            color="#FF9400"
                            height={20}
                            width={70}
                            timeout={5000}
                        />
                    </div>
                    :
                    <div style={{
                        padding: 10,
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <Styles.H1>{user.userName}</Styles.H1>
                        <span>{user.customer.customerName}</span>
                        <span>{user.userEmail}</span>
                        <span>{user.userPhone}</span>


                        <Styles.ButtonSecondary
                            style={{ width: 100, marginTop: 10 }}
                        >
                            Edit
                            </Styles.ButtonSecondary>

                        <h2>Request for Quotes</h2>
                        {user?.pos?.map((po, ix) => {


                            return <div key={ix}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}
                            >
                                <span style={{ flex: 1 }}><a href={`/admin/rfq/${po.id}`} >{po.customerReference}</a></span>

                                <span style={{ flex: 1 }}>{toTitleCase(moment(po.poCreateDate).tz('America/New_York').format('MMMM DD HH:mm'))}</span>


                            </div>
                        })

                        }
                    </div>
                }
            </main>
        </div>
    );
}


export default User;

