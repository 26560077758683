import React, { useState } from 'react';

import API from "../../api/usersAPI";

import { IoChevronForward } from "react-icons/io5";
import Header from '../../app/AdminComponents/Header';

import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
///import { useParams } from "react-router-dom"; 

import moment from 'moment-timezone';
// eslint-disable-next-line
import localization from 'moment/locale/es'
import Styles from "../../styles";

import { useDispatch } from 'react-redux';
moment.locale('es');


const List = ({
    users,
    accounts
}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    return <div>

        {accounts.map((account, ix1) => {

            return <div key={ix1}>

                <div
                    style={{
                        flexDirection: 'row',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        paddingLeft: 10,
                    }}
                >
                    <b>{account.customerName}</b>
                    <button

                        style={{
                            borderStyle: "none",
                            backgroundColor: 'transparent',
                            width: 100,
                            height: 40,
                            justifyContent: 'flex-end',
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'row'
                        }}
                        onClick={() => {
                            dispatch({
                                type: "CUSTOMER_SET",
                                customer: account
                            })
                            navigate(`/admin/customer/${account.id}`);
                        }}
                    ><IoChevronForward /></button>
                </div>
                <div>
                    {users.filter(u => u.customerId === account.id).map((user, ix) => {
                        return <button
                            key={ix}
                            style={{
                                backgroundColor: ix % 2 ? '#e0e0e0' : '#f8f8f8',
                                borderStyle: 'none',
                                width: '100%',
                                textAlign: 'left',
                                padding: 5
                            }}
                            onClick={() => navigate(`/admin/user/${user.id}`)}
                        >
                            <div style={{
                                ...styles_local.col,
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}>
                                <span style={styles_local.col}>{user.userName}<br />{user.userEmail}</span>

                                <div
                                    style={{
                                        borderStyle: "none",
                                        backgroundColor: 'transparent',
                                        width: 100,
                                        height: 40,
                                        justifyContent: 'flex-end',
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexDirection: 'row'
                                    }}

                                >
                                    <IoChevronForward />
                                </div>
                            </div>
                        </button>
                    })}
                </div>
            </div>

        })

        }

    </div>
}

const Users = () => {

    const navigate = useNavigate();
    const [fetching2, setFetching2] = useState(false);
    const [users, setUsers] = useState([]);
    const [accounts, setAccounts] = useState([]);


    let fnDataGet = async () => {
        setFetching2(true);
        let res = await API.usersFetch();
        setFetching2(false);
        if (res.success) {
            setUsers(res.users);
            setAccounts(res.accounts);
        }
    }

    /* eslint-disable */
    React.useEffect(() => {
        fnDataGet();
    }, []);
    /* eslint-enable */

    if (!localStorage.getItem("token")) {
        navigate("/login")
        return null
    }

    return (
        <div>

            <Header />

            <Styles.PageTitle

            >
                Business Accounts
            </Styles.PageTitle>

            <div
                style={{
                    margin: "auto",
                    maxWidth: 500,
                    minWidth: 350,
                    padding: 10
                }}
            >
                <Styles.ButtonMain

                >
                    New Account
                </Styles.ButtonMain>
            </div>
            <main style={{
                margin: "auto",
                maxWidth: 500,
                minWidth: 350,
                fontFamily: 'Montserrat',

            }}>

                {fetching2 ?
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: 50
                    }}>
                        <ThreeDots
                            type={"ThreeDots"}
                            color="#FF9400"
                            height={20}
                            width={70}
                            timeout={5000}
                        />
                    </div>
                    :


                    <List
                        accounts={accounts}
                        users={users}
                    />


                }


            </main>
        </div>
    );
}


export default Users;

const styles_local = {
    row: {
        display: 'flex',
        flexDirection: 'column'
    },
    col: {
        display: 'flex',
    }
}