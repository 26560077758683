import React, { useState } from 'react'
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import registrationAPI from "../api/registrationAPI";

import Menu from '../app/components/Menu';

import {   ThreeDots } from "react-loader-spinner";
///import { useParams } from "react-router-dom";
///import pageUserAPI from '../api/pageUserAPI';
///import { ThreeDots } from "react-loader-spinner";
import moment from 'moment-timezone';
// eslint-disable-next-line
import localization from 'moment/locale/es'

//import { useDispatch, useSelector } from 'react-redux';
 
moment.locale('es');

const Welcome = () => {
    let accountId = useParams().accountId;

    /*
    const dispatch = useDispatch();
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        fetching
    } = dataReducer;
    */

    const [accountInfo, setAccountInfo] = useState();
    const [fetching, setFetching] = useState(false);

    /* eslint-disable */
    React.useEffect(() => {
        setFetching(true);
        registrationAPI.accountLookup({
            accountId
        }).then(res => {
            setAccountInfo(res.acct);
            setFetching(false);
        });
    }, []);
    /* eslint-enable */

 

    return (
        <div>
            <Menu />
            <header
                style={{
                    backgroundColor: "#FF9400"
                }}
            >
                <div style={{
                    margin: "auto",
                    maxWidth: 1200,
                    minWidth: 350,
                    flexDirection: "row",
                    display: 'flex',
                    justifyContent: "space-between",
                    alignContent: "center",
                    alignItems: "center",

                }}>
                    <div style={{ width: 80 }} />
                    <img alt='ALANI 360 logo'
                        src='/images/alani_logos_vertical_horizontal_orange_white.svg#svgView(viewBox(320, 90, 260, 70))'
                        style={{
                            width: 120, height: 60
                        }}
                    />
                    <div style={{ width: 80 }} />
                </div>
            </header>


            <main style={{
                margin: "auto",
                maxWidth: 1200,
                minWidth: 350,
                fontFamily: 'Montserrat'
            }}>

                {fetching ?
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignContent: 'center',
                            margin: 50
                        }}
                    >
                        <ThreeDots
                            type={"ThreeDots"}
                            color="#FF9400"
                            height={50}
                            width={50}  
                        />
                    </div>

                    :

                    accountInfo == null ?
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                alignContent: 'center',
                                margin: 50,
                                flexDirection: 'column'
                            }}
                        >
                            <img
                                src="/images/alani_logos_vertical_horizontal.svg#svgView(viewBox(0, 240, 230, 200))"
                                alt="ALANI 360 Logo"
                                style={{
                                    width: "auto",
                                    height: 130
                                }}
                            />
                            <Link to="/registracion">Registra tu cuenta en ALANI360</Link>



                        </div>
                        :
                        <div style={{
                            padding: 10
                        }}>



                            <div
                                style={{
                                    textAlign: 'center',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    paddingTop: 10
                                }}
                            >

                                <h1 style={{
                                    fontSize: 20
                                }}>Bienvenido a ALANI360</h1>
                                <img
                                    src="/images/alani_logos_vertical_horizontal.svg#svgView(viewBox(0, 240, 230, 200))"
                                    alt="ALANI 360 Logo"
                                    style={{
                                        width: "auto",
                                        height: 130
                                    }}
                                />


                                <h1>{accountInfo.firstName} {accountInfo.lastName}</h1>
 

                                <div style={{
                                    width: 280,
                                    textAlign: 'center',
                                    marginTop: 10,
                                    marginBottom: 10
                                }}>

                                    Para activar tu cuenta
                                    <br/><br/>
                                    1. Revisa tu WhatsApp 
                                    <br/>
                                    <b>+{accountInfo.phoneNumber}</b>
                                    <br/>
                                    2. Revisa tu correo electrónico
                                    <br/>
                                    <b>{accountInfo.email}</b>
                                    <br/><br/>
                                    y sigue las instrucciones.
                                 </div>



                            </div>


                        </div>
                }

            </main>
        </div>
    );
}


export default Welcome;