import React from 'react'
import Menu from './Menu';

///import { ThreeDots } from "react-loader-spinner";
///import { useParams } from "react-router-dom";
///import pageUserAPI from '../api/pageUserAPI'; 



import { useNavigate } from "react-router-dom";


const Header = () => {
    const navigate = useNavigate();

    if (!localStorage.getItem("token")) {
        navigate("/login")
        return null
    }

    return (
        <div>

            <Menu />
            <header
                style={{
                    backgroundColor: "#58825B"
                }}
            >
                <div style={{
                    margin: "auto",
                    maxWidth: 1200,
                    minWidth: 350,
                    flexDirection: "row",
                    display: 'flex',
                    justifyContent: "space-between",
                    alignContent: "center",
                    alignItems: "center",

                }}>
                    <div style={{ width: 80 }} />
                    <button
                        style={{
                            backgroundColor: 'transparent',
                            borderStyle: 'none'
                        }}
                        onClick={() => navigate("/admin/")}
                    >
                        <img alt='ALANI 360 logo'
                            src='/images/alani_logos_vertical_horizontal_orange_white.svg#svgView(viewBox(320, 90, 260, 70))'
                            style={{
                                width: 120, height: 60
                            }}
                        />

                    </button>
                    <div style={{ width: 100 }} />
                </div>
            </header>
        </div>
    )
}

export default Header


