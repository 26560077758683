import { combineReducers } from 'redux';

import dataReducerSignUp from "./reducersSignup";

let dataState = {

    CEL_WHATSAPP: "https://wa.me/18634519550",
    NUMERO_WHATSAPP: "+18634519550",
    section: "main",
    fetching: false,
    userData: null,
    accion_terminada: null,
    display_message: null,

    cpos: [],
    cPO: null,
    autoPricingStatus: { status: "STOPPED" }
}


const dataReducerB2C = (state = dataState, action) => {
    switch (action.type) {
        case "BUY_NOW":
            return {
                ...state,
                buyNow: action.buyNow
            }


        default:
            return state;
    }

}


const dataReducer = (state = dataState, action) => {
    switch (action.type) {

        case "EMAIL_SET":
            return {
                ...state,
                email: action.email
            }

        case "AUTOPRICE_STATUS_SET":
            return {
                ...state,
                autoPricingStatus: { status: action.status }
            }


        case "CUSTOMER_SET":

            return {
                ...state,
                customer: action.customer
            }

        case "USER_SET":
            return {
                ...state,
                user: action.user
            }


        case "CPO_SET":
            return {
                ...state,
                cPO: action.cPO
            }
        case "CPOS_SET":
            return {
                ...state,
                cpos: action.cpos
            }

        case "PO_SET":
            return {
                ...state,
                purchaseOrder: action.purchaseOrder
            }


        case "PO_ITEMS_SET":
            return {
                ...state,
                purchaseOrderItems: action.purchaseOrderItems
            }
        case "ACCION_PROCESADA":
            return {
                ...state,
                accion_terminada: null
            }
        case "ACCION_TERMINADA":

            return {
                ...state,
                accion_terminada: new Date(),
                fetching: null
            }

        case "FETCHING":

            return {
                ...state,
                fetching: action.fetching
            }

        case "PAGE_SECTION_SET":
            console.warn("REMOVE THIS REDUCER");
            return {
                ...state,
                section: action.section
            }

        case "USER_DATA_SET":

            return {
                ...state,
                fetching: null,
                userData: action.data
            }

        case "PHONE_SET":
            return {
                ...state,
                phone: action.phone
            }
        case "DISPLAY_MESSAGE_SET":
            return {
                ...state,
                display_message: action.message
            }

        default:
            return state;
    }

}



// Combine all the reducers
const rootReducer = combineReducers({
    dataReducer,
    dataReducerSignUp,
    dataReducerB2C
})


export default rootReducer;