import React from 'react';
import { render } from "react-dom";
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";

import App from './App';

import PageUser from './routes/Page';
import SignUp from './routes/SignUp';
import Welcome from './routes/Welcome';
import Home from './routes/Home';

import Paquete from './routes/Paquete';
import Help from './routes/Help';
import HelpAutoParts from './routes/HelpAutoparts';
import IntercashPage from './routes/IntercashPage';
import Account from './routes/Account';
import AccountUpdateWaiting from './routes/AccountUpdateWaiting';

import POQuote from './routes/POQuote';
import POQuoteView from './routes/POQuoteView';

import POQuoteItem from './routes/POQuoteItem';

import EmailConfirm from './routes/EmailConfirm';
import AccountUpdateConfirm from './routes/AccountUpdateConfirm';
import WhatsappLandingPage from "./routes/WhatsappLandingPage";

import B2CPartSearch from "./routes/B2CPartSearch";
import B2CPartWhatsApp from "./routes/B2CPartWhatsApp";
import B2CBuyNow from "./routes/B2CBuyNow";
import B2CBuyNowCofirnmation from "./routes/B2CBuyNowCofirnmation";
import B2CPay from './routes/B2CBPay';


import B2CBuyNowV2 from "./routes/B2CBuyNowV2";

import APIStats from './routes/Admin/APIStats';
import HAPIRequest from './routes/Admin/HAPIRequest';



import AdminIndex from './routes/Admin/Index';
import AdminRFQS from './routes/Admin/RequestForQuotes';
import AdminRFQ from './routes/Admin/RequestForQuote';
import AdminUsers from './routes/Admin/Users';
import AdminUser from './routes/Admin/User';
import AdminCustomer from './routes/Admin/Customer';
import AdminEmails from './routes/Admin/Emails';
import AdminPOForm from './routes/Admin/POForm';
import AdminPONew from './routes/Admin/PONew';

import PartSearch from './routes/Admin/PartSearch';

import Email from './routes/Admin/Email';
import AdminEmailHapi from './routes/Admin/EmailHapi';

import AdminPOS from './routes/Admin/POS';


import AccountActivate from './routes/AccountActivate';

import store from './app/store'
import { Provider } from 'react-redux';



import { AuthProvider } from './hooks/useAuth.jsx';

import Login from "./routes/Login";


import ProdekFilePage from './routes/Prodek/File'


import * as serviceWorker from './serviceWorker';
import { CookiesProvider } from 'react-cookie';
import './i18n';


const rootElement = document.getElementById("root");

// <Route path="/admin/" element={<AdminIndex />} />

let authed = localStorage.getItem('token')


render(
    <Provider store={store}>
        <CookiesProvider>
            <BrowserRouter>
                <AuthProvider>
                    <Routes>


                        <Route path='/prodek/:fileId'
                            element={<ProdekFilePage />}
                        />
                        <Route path="/login/" element={<Login />} />

                        <Route path='/admin/hapi/' element={
                            authed
                                ?
                                <HAPIRequest
                                />
                                :
                                <Login />
                        } />

                        <Route path='/admin/apiStats' element={
                            authed
                                ?
                                <APIStats
                                />
                                :
                                <Login />
                        } />


                        <Route path='/admin/parts' element={
                            authed
                                ?
                                <PartSearch
                                />
                                :
                                <Login />
                        } />


                        <Route path='/admin/pos/new' element={
                            authed
                                ?
                                <AdminPONew
                                />
                                :
                                <Login />
                        } />


                        <Route path='/admin/pos/:poId' element={
                            authed
                                ?
                                <AdminPOForm
                                />
                                :
                                <Login />
                        } />


                        <Route path='/admin/pos/' element={
                            authed
                                ?
                                <AdminPOS
                                />
                                :
                                <Login />
                        } />

                        <Route path='/admin/rfpo/:purchaseOrderId/po/:poId' element={
                            authed
                                ?
                                <AdminPOForm
                                />
                                :
                                <Login />
                        } />

                        <Route path='/admin/rfpo/:poId/:purchaseOrderId' element={
                            authed
                                ?
                                <AdminRFQ
                                    StartForm={"pos"}
                                />
                                :
                                <Login />
                        } />

                        <Route path='/admin/rfpo/:poId/:purchaseOrderId' element={
                            authed
                                ?
                                <AdminRFQ
                                    StartForm={"pos"}
                                />
                                :
                                <Login />
                        } />

                        <Route path='/admin/rfpo/:poId/:purchaseOrderId' element={
                            authed
                                ?
                                <AdminRFQ
                                    StartForm={"pos"}
                                />
                                :
                                <Login />
                        } />
                        <Route path='/admin/emails/:emailId/:purchaseOrderId' element={
                            authed
                                ?
                                <AdminRFQ
                                    StartForm={"emails"}
                                />
                                :
                                <Login />
                        } />

                        <Route path='/admin/emails' element={
                            authed
                                ?
                                <AdminEmails />
                                :
                                <Login />
                        } />

                        <Route path='/admin/emailh/:emailId' element={
                            authed
                                ?
                                <AdminEmailHapi />
                                :
                                <Login />
                        } />

                        <Route path='/admin/' element={
                            authed
                                ?
                                <AdminIndex />
                                :
                                <Login />
                        } />


                        <Route path='/admin/rfq/:purchaseOrderId/email' element={
                            authed
                                ?
                                <Email
                                />
                                :
                                <Login />
                        } />


                        <Route path='/admin/rfqs/' element={
                            authed
                                ?
                                <AdminRFQS />
                                :
                                <Login />
                        } />
                        <Route path='/admin/rfq/:purchaseOrderId' element={
                            authed
                                ?
                                <AdminRFQ

                                    startForm={"po"}
                                />
                                :
                                <Login />
                        } />


                        <Route path='/admin/customer/:customerId' element={
                            authed
                                ?
                                <AdminCustomer />
                                :
                                <Login />
                        } />

                        <Route path='/admin/users' element={
                            authed
                                ?
                                <AdminUsers />
                                :
                                <Login />
                        } />

                        <Route path='/admin/user/:userId' element={
                            authed
                                ?
                                <AdminUser />
                                :
                                <Login />
                        } />
                        <Route path="/po/:quoteCode" element={<POQuote />} />
                        <Route path="/po/v/:quoteCode/" element={<POQuoteView />} />
                        <Route path="/q/:quoteCode" element={<POQuote />} />
                        <Route path="/poi/:quoteCode/:itemId" element={<POQuoteItem />} />

                        <Route path="/m/:token_encoded" element={<WhatsappLandingPage />} />
                        <Route path="/:accountId/confirm/c/:auth_code" element={<AccountUpdateConfirm />} />
                        <Route path="/:accountId/confirm/e/:auth_code" element={<AccountUpdateConfirm />} />

                        <Route path="/ayuda" element={<Help />} />
                        <Route path="/ayuda/atp" element={<HelpAutoParts />} />
                        <Route path="/h/atp" element={<HelpAutoParts />} />

                        <Route path="/ayuda/:token" element={<Help />} />
                        <Route path="p/:token" element={<PageUser />} />
                        <Route path="p2/:token" element={<PageUser />} />
                        <Route path="p/:token/intercash" element={<IntercashPage />} />
                        <Route path="p/:token/account" element={<Account />} />

                        <Route path="p" element={<PageUser />} />

                        <Route path="registracion" element={<SignUp />} />
                        <Route path="registracion/:token" element={<SignUp />} />
                        <Route path="registro" element={<SignUp />} />
                        <Route path="registro/:token" element={<SignUp />} />
                        <Route path="r/:token" element={<SignUp />} />

                        <Route path="bienvenido" element={<Welcome />} />
                        <Route path="bienvenido/:accountId" element={<Welcome />} />


                        <Route path="account/:accountId/update_confirm/:token" element={<AccountUpdateWaiting />} />


                        <Route path="/email_confirm/:accountId/:token" element={<EmailConfirm />} />
                        <Route path="/:token" element={<Home />} />
                        <Route path="/" element={<Home />} />


                        <Route path="/search" element={<B2CPartSearch />} />
                        <Route path="/atp/s" element={<B2CPartSearch />} />
                        <Route path="/part/:code" element={<B2CPartWhatsApp />} />
                        <Route path="/buyNow/:code" element={<B2CBuyNow />} />
                        <Route path="/buyNow/:code/c" element={<B2CBuyNowCofirnmation />} />
                        <Route path="/pay/:code" element={<B2CPay />} />

                        <Route path="/b/:code" element={<B2CBuyNowV2 />} />


                        <Route path="/activate/:token" element={<AccountActivate />} />
                        <Route path="/a/:token" element={<AccountActivate />} />



                        <Route path="/pk/:token" element={<Paquete />} />
                        <Route path="/pk/:token/:paqueteId" element={<Paquete />} />

                        <Route
                            path="*"
                            element={<App />}
                        />
                    </Routes>
                </AuthProvider>
            </BrowserRouter>

        </CookiesProvider>
    </Provider>
    ,
    rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
