

import React from 'react';



// eslint-disable-next-line
import localization from 'moment/locale/es'


import { useSelector } from 'react-redux';


import Modal from 'react-modal';



const PODealer = () => {
    //const dataReducer = useSelector((state) => state.dataReducer);
    //  let cPurchaseOrder = dataReducer.cPO;
    const [modalIsOpen, setIsOpen] = React.useState(false);


    function closeModal() {
        setIsOpen(false);
    }
    return <div style={{
        marginBottom: 10,
    }}>
        <Modal
            isOpen={modalIsOpen}
            //onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            shouldCloseOnOverlayClick={false}
            contentLabel="Example Modal"
        >
            <div style={{
                backgroundColor: '#000',
                borderRadius: 10,
                padding: 20,
                borderColor: '#000'
            }}>
                <button onClick={() => setIsOpen(false)}>Close</button>
            </div>

        </Modal>
        <div style={{
            display: 'flex',
            flexDirection: 'column'
        }}>
            Dealer
            <div>
                <button onClick={() => setIsOpen(true)}>Select Dealer</button>
            </div>
        </div>

    </div>
}

const PODetails = ({
    fnSetPoDetails
}) => {
    const dataReducer = useSelector((state) => state.dataReducer);
    let cPO = dataReducer.cPO || {};

    const [reference, setReference] = React.useState(cPO.poReference);


    /* eslint-disable */
    React.useEffect(() => {

        fnSetPoDetails({
            poReference: reference
        });
    }, [reference]);
    /* eslint-enable */

    return <div
        style={{
            flexDirection: 'column',
            display: 'flex'
        }}
    >

        Reference
        <input
            type="text"
            value={reference || ""}
            style={{
                width: 270,
                marginBottom: 5
            }}
            onChange={e => setReference(e.target.value)}
            maxLength={250}
        />

        <PODealer />
    </div>
}

export default PODetails;


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#808080',
        width: 350,
        display: 'flex',
        flexDirection: 'column',
        color: '#000000',
        padding: 0,
        borderRadius: 10,
    },
    overlay: {
        zIndex: 1200
    }
};
