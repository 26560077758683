import React, { useState } from 'react';
import API from "../../api/usersAPI";
import { ThreeDots } from "react-loader-spinner";
import Header from '../../app/AdminComponents/Header';
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import moment from 'moment-timezone';

import Styles from "../../styles";

import { useDispatch, useSelector } from 'react-redux';

function toTitleCase(str) {
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}


const Account = () => {
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const customerId = useParams().customerId;
    const [fetching2, setFetching2] = useState(false);
    const [backURL, setBackURL] = useState(-1);
    const [section, setSection] = useState((searchParams.get("v") || "details"));

    const dispatch = useDispatch();
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        customer
    } = dataReducer;

    let fnDataGet = async () => {
        setFetching2(true);
        let res = await API.customerFetch({
            customerId
        });

        setFetching2(false);
        if (res.success) {
            dispatch({
                type: 'CUSTOMER_SET',
                customer: res.customer
            });
        }
    }

    /* eslint-disable */
    React.useEffect(() => {
        fnDataGet();
    }, []);
    /* eslint-enable */



    const Menu = () => {

        return <div

            style={{
                padding: 10,
            }}
        >
            <button onClick={() => navigate(backURL || -1)} >&lt; Back</button>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end'
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                    }}
                >

                    <button
                        style={section === "details" ? styles.menuButton__selected : styles.menuButton}
                        onClick={() => {
                            setSearchParams({ v: "details" });
                            setBackURL(backURL - 1);
                            setSection("details");
                        }} >Details</button>
                    <button
                        style={section === "rfqs" ? styles.menuButton__selected : styles.menuButton}

                        onClick={() => {
                            setSearchParams({ v: "rfqs" });
                            setBackURL(backURL - 1);
                            setSection("rfqs");

                        }} >RFQS</button>
                    <button
                        style={section === "pos" ? styles.menuButton__selected : styles.menuButton}

                        onClick={() => {
                            setSearchParams({ v: "pos" });
                            setBackURL(backURL - 1);
                            setSection("pos");
                        }} >POs</button>
                    <button
                        style={section === "emails" ? styles.menuButton__selected : styles.menuButton}

                        onClick={() => {
                            setSearchParams({ v: "emails" });
                            setBackURL(backURL - 1);
                            setSection("emails");
                        }} >Emails</button>
                </div>
            </div>


        </div>
    }

    return (
        <div>

            <Header />

            <Styles.PageTitle>Customer Account</Styles.PageTitle>

            <main style={{
                margin: "auto",
                maxWidth: 500,
                minWidth: 350,
                fontFamily: 'Montserrat',

            }}>

                <Menu />

                {fetching2 || !customer ?
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: 50
                    }}>
                        <ThreeDots 
                            color="#FF9400"
                            height={20}
                            width={70}
                            timeout={5000}
                        />
                    </div>
                    :
                    <div style={{
                        padding: 10,
                        display: 'flex',
                        flexDirection: 'column'
                    }}>

                        <Styles.H1>{customer.customerName}</Styles.H1>


                        {customer.surcharges != null &&
                            <div>
                                <span>{customer.surcharges[0].percentSurcharge}%</span>
                            </div>

                        }



                        <Styles.ButtonSecondary
                            style={{ width: 100, marginTop: 10 }}
                        >
                            Edit
                            </Styles.ButtonSecondary>

                        <h2>Users</h2>
                        {customer?.users?.map((u, ix) => {


                            return <div key={ix}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}
                            >
                                <span style={{ flex: 1 }}><a href={`/admin/user/${u.id}`} >{u.userName}</a></span>



                            </div>
                        })

                        }


                        <h2>Request for Quotes</h2>
                        <div
                            style={{
                                borderWidth: 1,
                                borderColor: "#c0c0c0",
                                borderStyle: "solid",
                                borderBottomStyle: 'none'
                            }}
                        >
                            {customer?.pos?.map((po, ix) => {


                                return <div key={ix}
                                    style={{
                                        backgroundColor: ix % 2 ? "#e0e0e0" : "#fff",
                                        display: 'flex',
                                        flexDirection: 'row',
                                        padding: 5,
                                        borderBottomStyle: 'solid',
                                        borderBottomWidth: 1,
                                        borderBottomColor: '#c0c0c0'
                                    }}
                                >
                                    <span style={{ flex: 1 }}>
                                        <a href={`/admin/rfq/${po.id}`} >{po.customerReference}</a>
                                        <br />
                                        {po.userName}
                                    </span>

                                    <span style={{ flex: 1, textAlign: 'right' }}>{toTitleCase(moment(po.poCreateDate).tz('America/New_York').format('MMMM DD HH:mm'))}</span>


                                </div>
                            })}
                        </div>
                    </div>
                }
            </main>
        </div>
    );
}


export default Account;


const styles = {


    menuButton: {
        borderStyle: "none",
        backgroundColor: 'transparent'
    },
    menuButton__selected: {
        borderStyle: 'none',
        backgroundColor: 'transparent',
        borderBottomStyle: 'solid',
        borderBottomColor: 'red',
        borderBottomWidth: 2
    },

}