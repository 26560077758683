const token = localStorage.getItem("token");

const API = {

    customerFetch: (args) => {
        ///AlaniPO/AdminBusinessGet/index.js
        //CALL businessGet( ?   )

        return new Promise((resolve, reject) => {
            let {
                customerId
            } = args;

            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/admin/customer/${customerId}`;


            var headers = new Headers();

            headers.append("Authorization", token);
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            fetch(url, {
                headers,
                method: 'GET',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    reject(err);
                });
        })
    },


    userFetch: (args, phone) => {
        //AlaniPO/AdminUserGet/index.js
        //CALL userGet( ?   )

        return new Promise((resolve, reject) => {
            let {
                userId
            } = args;

            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/admin/users/${userId}`;


            var headers = new Headers();

            headers.append("Authorization", token);
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            fetch(url, {
                headers,
                method: 'GET',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    reject(err);
                });
        })
    },

    usersFetch: (args, phone) => {


        return new Promise((resolve, reject) => {
            let {
                userType
            } = args || {
                userType: "customer"
            };


            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/admin/users?userType=${userType}`;


            var headers = new Headers();

            headers.append("Authorization", token);
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);

            fetch(url, {
                headers,
                method: 'GET',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    console.warn(err)
                    //alert(err)
                    reject(err);
                });
        })
    },


}

export default API;