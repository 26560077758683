//const token = localStorage.getItem("token");


const b2cAPI = {


    prealertConfirm: (args) => {
        let {
            packageId,
            accountId
        } = args;
        return new Promise((resolve, reject) => {
            // SP package_information_update
            //cloud9-MerxTransExpressAPI-CreateAlert-YM3KOCG51SP2


            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/account/${accountId}/packages/${packageId}/transexpressPrealert`;

            var headers = new Headers();
            //headers.append("x-api-key", process.env.REACT_APP_API_KEY);

            fetch(url, {
                headers,
                method: 'POST',
                body: JSON.stringify(args)
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })

    },




    alaniPay: ({
        token
    }) => {

        return new Promise((resolve, reject) => {
            //arn:aws:lambda:us-east-1:645160913840:function:ALANI_B2C_ALANIPAY_PAY
            // CALL alanipay_pay(?)

            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/account/b2c/wapQuote/${token}/pay`;

            var headers = new Headers();
            //headers.append("x-api-key", process.env.REACT_APP_API_KEY);

            fetch(url, {
                headers,
                method: 'POST',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })

    },


    fetchQuote: ({
        token
    }) => {


        return new Promise((resolve, reject) => {
            //arn:aws:lambda:us-east-1:645160913840:function:ALANI_B2C_AUTOPARTS_WAP_QUOTE_FETCH
            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/account/b2c/wapQuote/${token}`;

            var headers = new Headers({ 'Content-Type': 'application/json' });

            fetch(url, {
                headers,
                method: 'GET',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })

    },

    partPriceGet: ({
        phoneNumber,
        partNumber
    }) => {


        return new Promise((resolve, reject) => {


            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/account/b2c/partprice/${phoneNumber}/${partNumber}`;

            var headers = new Headers();
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            //headers.append("Authorization", token);
            fetch(url, {
                headers,
                method: 'GET',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    console.warn(err)
                    reject(err);
                });
        })
    },


    partQuoteGet: ({
        code
    }) => {


        return new Promise((resolve, reject) => {


            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/account/b2c/pquote/${code}`;

            var headers = new Headers();
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            //headers.append("Authorization", token);
            fetch(url, {
                headers,
                method: 'GET',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    console.warn(err)
                    reject(err);
                });
        })
    },


}

export default b2cAPI;