const pageUserAPI = {

    logout: (args) => {
        return new Promise((resolve, reject) => {
            let {
                token,
                accountId
            } = args;
            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/account/${accountId}/logout?token=${token}`;

            var headers = new Headers();
            //const token = Cache.getItem('Merx:authtoken');
            //headers.append("Authorization", token);
            //headers.append("x-api-key", config.apiGateway.api_key);
            fetch(url, {
                headers,
                method: 'POST',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },

    fetch: (args, phone , accountId) => {
 

        //TODO: reemplazar esta lambda cloud9-ConversationBot-PageInfoFetch-W6GL9Mxm9aiO 
        ///POR ESTA: arn:aws:lambda:us-east-1:645160913840:function:ALANI_PAGE_INFO_FETCH
        //CALL convo_infoPageGet( token , phone , checkPinCode )
 
        return new Promise((resolve, reject) => {
            let { token, checkPinCode, rememberMe } = args;

            
            if (!phone 
                &&
                !   accountId
                //&& (!token || token === undefined)
                ) {

                console.warn("pageUserAPI.fetch ==> abortar fetch", phone + " " + token)
                resolve({});
                return
            }



            if (!checkPinCode) checkPinCode = 0;

            phone = phone && phone.replace("+", "");


            if (!token) token = "0";

            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/convoPageInfo/${token}?phone=${phone || ""}&pinCode=${checkPinCode}&accountId=${accountId || ""}`;
            if (rememberMe !== undefined) url += `&rememberMe=${rememberMe}`;
 
            

            var headers = new Headers();
            //const token = Cache.getItem('Merx:authtoken');
            //headers.append("Authorization", token);
            //headers.append("x-api-key", config.apiGateway.api_key);
            fetch(url, {
                headers,
                method: 'GET',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    reject(err);
                });
        })
    },


    tokenRenovar: (args) => {


        //arn:aws:lambda:us-east-1:645160913840:function:ALANI_B2C_WHATSAPP_SENDMESSAGE
        //CALL token_renew( token_or_phone )

        return new Promise((resolve, reject) => {
            let { token_or_phone } = args;
            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/token/${token_or_phone}`;

            var headers = new Headers();
            //const token = Cache.getItem('Merx:authtoken');
            //headers.append("Authorization", token);
            //headers.append("x-api-key", config.apiGateway.api_key);
            fetch(url, {
                headers,
                method: 'POST',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },

    prealertaCancelar: (args) => {
        return new Promise((resolve, reject) => {
            let { token, prealertaId } = args;
            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/convoPageInfo/${token}/prealertas/${prealertaId}`;

            var headers = new Headers();
            //const token = Cache.getItem('Merx:authtoken');
            //headers.append("Authorization", token);
            //headers.append("x-api-key", config.apiGateway.api_key);
            fetch(url, {
                headers,
                method: 'DELETE',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },
}

export default pageUserAPI