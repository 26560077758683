const token = localStorage.getItem("token");

const API = {

    "delete": (args) => {


        //ClientPO/ClientPOSave/index.js

        return new Promise((resolve, reject) => {
            let {
                cpoId
            } = args;

            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/admin/cpos/${cpoId}/`;

            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            headers.append("Authorization", token);


            fetch(url, {
                headers,
                method: 'DELETE',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },

    save: (args) => {


        //ClientPO/ClientPOSave/index.js

        return new Promise((resolve, reject) => {
            let {
                cpoId
            } = args;

            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/admin/cpos/${cpoId}/`;

            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            headers.append("Authorization", token);


            fetch(url, {
                headers,
                method: 'POST',
                body: JSON.stringify(args)
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },


    fetchById: (args) => {

        /// /ClientPO/ClientPOFetch/index.js
        //CALL client_po_fetch( ? )

        return new Promise((resolve, reject) => {

            let {
                cpoId
            } = args;

            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/admin/cpos/${cpoId}`;


            var headers = new Headers();
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            headers.append("Authorization", token);
            fetch(url, {
                headers,
                method: 'GET',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    reject(err);
                });
        })
    },
    fetchItemsNoPO: (args) => {


        return new Promise((resolve, reject) => {
            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/admin/cpos/items_no_po/`;

            var headers = new Headers();
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            headers.append("Authorization", token);
            fetch(url, {
                headers,
                method: 'GET',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    reject(err);
                });
        })
    },

    fetchAll: (args) => {

        /// /ClientPO/ClientPOsFetch/index.js
        //CALL client_pos_fetch( ? )

        return new Promise((resolve, reject) => {

            let {
                status
            } = args;

            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/admin/cpos?status=${status || 1}`;


            var headers = new Headers();
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            headers.append("Authorization", token);
            fetch(url, {
                headers,
                method: 'GET',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    reject(err);
                });
        })
    },


}

export default API