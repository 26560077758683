import React from 'react'
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

import './App.css';

export default function App() {
  return (
    <div>
      <header
        style={{
          backgroundColor: "#FF9400"
        }}
      >
        <div style={{
          margin: "auto",
          maxWidth: 1200,
          minWidth: 350,
          flexDirection: "row",
          display: 'flex',
          justifyContent: "space-between",
          alignContent: "center",
          alignItems: "center",

        }}>
          <div style={{ width: 80 }} />
          <img alt='ALANI 360 logo'
            src='/images/alani_logos_vertical_horizontal_orange_white.svg#svgView(viewBox(320, 90, 260, 70))'
            style={{
              width: 120, height: 60
            }}
          />
          <div style={{ width: 80 }} />

        </div>
      </header>


      <main style={{
        margin: "auto",
        maxWidth: 1200,
        minWidth: 350,
        fontFamily: 'Montserrat',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        flexDirection: 'column',
        textAlign: "center",
        marginTop: 20
      }}>

        <img
          src="/images/alani_logos_vertical_horizontal.svg#svgView(viewBox(0, 240, 230, 200))"
          alt="ALANI 360 Logo"
          style={{
            width: "auto",
            height: 100
          }}
        />

        404 Oops... no hay nada
        <br /><br />
        <span style={{
          color: 'red'
        }}>{JSON.stringify(useParams()["*"])}</span>
        <br /><br />
        <Link to="/">ALANI360</Link>{" "}
        <br /><br />
        <Link to="/ayuda">Ayuda</Link>{" "}
      </main>
    </div>
  );
}
