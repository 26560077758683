import React from 'react'

import moment from 'moment-timezone';

// eslint-disable-next-line
import localization from 'moment/locale/es'


//import { useNavigate } from "react-router-dom";
// import { useDispatch, useSelector } from 'react-redux';

// import Styles from "../../styles";


moment.locale('es');


const IntercashApplyForm = () => {

    //const navigate = useNavigate();
    return <div>

        Forma de Pago

    </div>
}


export default IntercashApplyForm;