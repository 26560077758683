import styled from 'styled-components';


/* 
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 50px;
  color: #444;
  border: 1px solid #1890ff;
`;

const Button = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #1890ff;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  transition: .3s background;
  &:hover {
    background: #40a9ff;
  }
`;

*/



const H1 = styled.h1`
  margin: 0px;
  margin-bottom: 10px;
  padding: 0px; 
`

const PageTitle = styled.h1`
      
    height: 20px; 
    flex-direction: column;
    display: flex;
    align-content: center;
    align-items: center; 
    padding: 10px; 
    margin: 0px;
    background-color: #f8f8f8;
    color: #18391A;
    border-bottom-color: #808080;
    border-bottom-width: 1px;
    border-bottom-style: solid;
`;

const ModalDiv = styled.div`
    
    width: 100%;  
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.80);
    flex-direction: column;
    display: flex;
    align-content: center;
    align-items: center; 
    padding: 10px; 
`;

const ContentDiv = styled.div`
    margin: auto;
    max-width: 450px; 
    flex-direction: column;
    display: flex;
    align-content: center;
    align-items: center; 
    padding: 10px; 
`;

const ButtonDetalles = styled.button`
    flex-direction: row;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: 35px;   
    border-style: solid;
    border-width: 1px;
    border-color: #808080;
    border-radius: 5px;
    color: #000000
`;



const ButtonCircular = styled.button`
    flex-direction: row;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 36px;    
    border-style: none;
    border-width: 1px;
    border-color: #808080;
    border-radius: 18px;
    background-color: #FF9400;
    color: #fff;
    padding: 0
`;

const ButtonMain = styled.button`
    flex-direction: row;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: 35px;   
    border-style: solid;
    border-width: 1px;
    border-color: #808080;
    border-radius: 5px;
    background-color: #FF9400;
    color: #fff;
`;


const ButtonSecondary = styled.button`
    flex-direction: row;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: 35px;   
    border-style: solid;
    border-width: 1px;
    border-color: #808080;
    border-radius: 5px;
    background-color: #3482B3;
    color: #fff;
`;
const ButtonCancel = styled.button`
    flex-direction: row;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: 35px;   
    border-style: solid;
    border-width: 1px;
    border-color: #000000; 
    border-radius: 5px;
    background-color: #e0e0e0;
    color: #000; 
`;

const ButtonDelete = styled.button`
    flex-direction: row;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: 35px;   
    border-style: solid;
    border-width: 1px;
    border-color: #EA4335; 
    border-radius: 5px;
    background-color: #FFFFFF;
    color: #EA4335; 
`;

const ButtonContinuar = styled.button`,
    margin: auto;
    width: 200px; 
    border-style: solid;
    border-width: 0.5px;
    border-color: #e0e0e0; 
    padding: 5px;
    border-radius: 5px;
    height: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.fetching ? "#808080" :  "#4A7DB2"} ;
     
`;

const Styles = {
  ButtonContinuar,
  ButtonCircular,
  ButtonDetalles,
  ButtonMain, ButtonCancel, ButtonDelete,
  ContentDiv,
  ModalDiv,
  ButtonSecondary,
  PageTitle,
  H1
}



export default Styles;