import React from 'react';
import { ThreeDots } from "react-loader-spinner";

import { useSelector } from 'react-redux';

import moment from 'moment-timezone';

var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const List = () => {
    const dataReducer = useSelector((state) => state.dataReducer);
    let {
        purchaseOrder,
        purchaseOrderItems
    } = dataReducer;

    const [acceptedSelected, setAcceptedSelected] = React.useState(false);
    const [rejectedSelected, setRejectedSelected] = React.useState(false);
    const [noAnswerSelected, setNoAnswerSelected] = React.useState(false);
    const [noQuoteSelected, setNoQuoteSelected] = React.useState(false);



    const Tab = ({
        label,
        fn,
        cond,
    }) => <button
        onClick={() => fn(!cond)}
        style={
            cond ? {
                ...styles_local.tab,
                ...styles_local.tab__selected
            } : styles_local.tab}
    >
            {label}
        </button>

    if (!purchaseOrder.items_count) return null;
    return <div>
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: 10
            }}
        >
            <Tab
                label={`Accepted ${purchaseOrder.items_count["2"] ? purchaseOrder.items_count["2"].items : ""}`}
                fn={setAcceptedSelected}
                cond={acceptedSelected}
            />
            <Tab
                label={`No ${purchaseOrder.items_count["-1"] ? purchaseOrder.items_count["-1"].items : ""}`}
                fn={setRejectedSelected}
                cond={rejectedSelected}
            />
            <Tab
                label={`No Answer ${purchaseOrder.items_count["1"] ? purchaseOrder.items_count["1"].items : ""}`}
                fn={setNoAnswerSelected}
                cond={noAnswerSelected}
            />
            <Tab
                label={`Not Quoted ${purchaseOrder.items_count["0"] ? purchaseOrder.items_count["0"].items : ""}`}

                fn={() => {

                    setNoQuoteSelected(!noQuoteSelected)
                }}
                cond={noQuoteSelected}
            />

        </div>

        <div
            style={{
                borderColor: '#000',
                borderWidth: 1,
                borderStyle: 'solid',
                borderBottomStyle: 'none'
            }}
        >
            {purchaseOrderItems.filter(i =>
                noQuoteSelected ?
                    i.itemIsQuoted === 0
                    :
                    (
                        (acceptedSelected ? i.status === 2 : false)
                        ||
                        (rejectedSelected ? i.status === -1 : false)
                        ||
                        (noAnswerSelected ? (i.status === 1 && i.itemIsQuoted === 1) : false)
                    )

            ).map((item, ix) => {

                return <div
                    key={ix}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        borderBottomColor: '#000',
                        borderBottomWidth: 1,
                        borderBottomStyle: 'solid',
                        background: ix % 2 ? "#c0c0c0" : "#fff",
                        padding: 5
                    }}
                >
                    <div style={{
                        flex: 9,
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <span>{item.ref1}</span>
                        <span style={{ fontWeight: 'bold' }}>{item.ref2}</span>
                        <span>{item.ref4}</span>
                        <span>{item.ref5}</span>
                        <span>{item.ref3}</span>

                        <span>{item.api_part_name}</span>
                        <span style={{
                            color: 'blue',
                            fontWeight: 'bold'
                        }}>
                            {item.partly_name}
                        </span>
                        <span style={{
                            color: 'blue'
                        }}>
                            {item.partly_description || item.scrape_name}

                        </span>
                        <span style={{
                            color: 'blue'
                        }}>

                            {item.brand}
                        </span>
                        {item.is_oe && <span style={{ width: 50, textAlign: 'center', marginTop: 5, fontSize: 12, backgroundColor: '#3784B4', color: '#fff', padding: 5, borderRadius: 5 }}>OEM</span>}

                        <span style={{ fontSize: 10, color: '#808080' }}>{item.id}</span>

                        <span style={{ fontSize: 10, color: '#000' }}>
                            {item.scrape_url}
                        </span>
                    </div>

                    <div
                        style={{ display: 'flex', flexDirection: 'column' }}
                    >
                        {(
                            (item.msrp || 0) !== 0
                            &&
                            (
                                (item.weight || 0) !== 0
                                ||
                                (item.height_mm || 0) !== 0
                            )
                        ) &&
                            <div style={{
                                flex: 1,
                                textAlign: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        marginBottom: 10
                                    }}
                                >
                                    <span
                                        style={{
                                            background: item.approvedDateTime ? 'green' : item.rejectedDateTime ? 'red' : '#000000',
                                            color: '#fff',
                                            padding: 5,
                                            borderRadius: 5,
                                            width: 100
                                        }}
                                    >{formatter.format(item.quotedTotal)}</span>

                                    {
                                        {
                                            "2": <span style={{ color: 'green' }}>{moment(item.approvedDateTime).add(item.etaDays, 'days').format("MMM DD YYYY")}</span>,
                                            "-1": <span style={{ color: 'red' }}>Rejected</span>,
                                            "1": <span>{item.etaDays} days</span>,
                                        }[item.status]

                                    }

                                </div>

                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        fontSize: 12,
                                        textAlign: 'right',
                                        alignItems: 'flex-end',
                                        width: '100%',
                                        marginRight: 20
                                    }}
                                >

                                    <span >
                                        {formatter.format(item.customsCost)}
                                    </span>
                                    <span >
                                        {formatter.format(item.shippingCost)}
                                    </span>
                                    <span>
                                        {formatter.format(item.msrp)} {item.percentSurcharge}%
                                    </span>


                                </div>

                            </div>
                        }

                        <div>
                            {(item.msrp || 0) === 0 ?
                                <div style={styles_local.missing}>
                                    MSRP
                                </div>
                                :
                                <div style={styles_local.ok}>
                                    {item.msrp}
                                </div>
                            }
                        </div>
                        <div>
                            {(item.weight || 0) === 0 ?
                                <div style={styles_local.missing}>
                                    WEIGHT
                                </div>
                                :
                                <div style={styles_local.ok}>
                                    {item.weight} gr
                                </div>
                            }
                        </div>
                        <div>
                            {(item.height_mm || 0) === 0 ?
                                <div style={styles_local.missing}>
                                    DIMENSIONS
                                </div>
                                :
                                <div style={styles_local.ok}>
                                    {`${item.height_mm} x ${item.width_mm} x ${item.length_mm} `}
                                </div>
                            }
                        </div>
                        {(item.scrape_url || ".html") !== ".html" &&
                            <div style={styles_local.ok_blue}>
                                <a target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: '#fff' }}
                                    href={`${item.scrape_url}`}>URL</a>
                            </div>
                        }

                    </div>

                </div>

            })}
        </div >
    </div>

}


const RFQItemsList = () => {
    const dataReducer = useSelector((state) => state.dataReducer);
    let {
        purchaseOrderItems,

    } = dataReducer;


    /* eslint-disable */
    React.useEffect(() => {


    }, []);
    /* eslint-enable */

    if (!purchaseOrderItems) {
        return <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                margin: 50
            }}
        >
            <ThreeDots
                type={"ThreeDots"}
                color="#FF9400"
                height={20}
                width={70}
                timeout={10000}
            />
        </div>
    }

    return <div>


        <List
        />
    </div>
}

export default RFQItemsList;


const styles_local = {
    ok: {
        padding: 5,
        textAlign: 'center',
        backgroundColor: 'green',
        color: '#fff',
        borderRadius: 5,
        fontSize: 12,
        margin: 5,
        marginTop: 2, marginBottom: 2,
        minWidth: 100
    },
    ok_blue: {
        padding: 5,
        textAlign: 'center',
        backgroundColor: 'blue',
        color: '#fff',
        borderRadius: 5,
        fontSize: 12,
        margin: 5,
        marginTop: 2, marginBottom: 2,
        minWidth: 100
    },
    missing: {
        padding: 5,
        textAlign: 'center',
        backgroundColor: 'red',
        color: '#fff',
        borderRadius: 5,
        fontSize: 12,
        margin: 5,
        marginTop: 2, marginBottom: 2,
        minWidth: 100
    },
    tab: {
        marginRight: 5,
        borderStyle: 'solid',
        borderWidth: 1
    },
    tab__selected: {
        backgroundColor: "#FF9400"
    }
}
