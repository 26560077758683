import React from 'react'
import { useParams, useNavigate } from "react-router-dom";

import { useCookies } from 'react-cookie';

import moment from 'moment-timezone';
// eslint-disable-next-line
import localization from 'moment/locale/es'
 
import { useSelector, useDispatch } from 'react-redux';

import IntercashApplyForm from '../app/components/IntercashApplyForm';

import {
    IoPersonCircle,
    IoChevronBackOutline
} from "react-icons/io5";

import Styles from "../styles"

moment.locale('es');

const IntercashPage = () => {
    const cookies = useCookies(['alani360']);
    const phone_cookie = (cookies[0] && cookies[0].alani360 && ("+" + cookies[0].alani360.phoneNumber));
    let token = useParams().token;
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        userData,
    } = dataReducer;


    /* eslint-disable */
    React.useEffect(() => {

        dispatch({
            type: "USER_DATA_FETCH",
            phone: phone_cookie,
            token
        });

    }, [token]);
    /* eslint-enable */
 

    if (userData && !userData.success) {
        //expiro el token
        navigate("/p");
    }

    return (
        <div>
            <header
                style={{
                    backgroundColor: "#FF9400"
                }}
            >

                <div style={{
                    margin: "auto",
                    maxWidth: 1200,
                    minWidth: 350,
                    flexDirection: "row",
                    display: 'flex',
                    justifyContent: "space-between",
                    alignContent: "center",
                    alignItems: "center",

                }}>
                    <button
                        style={{
                            width: 80,
                            paddingRight: 20,
                            height: 40,
                            borderRadius: 0,
                            backgroundColor: 'transparent',
                            borderStyle: 'none'
                        }}
                        onClick={() => navigate(-1)}
                    >
                        <IoChevronBackOutline
                            size={30}
                        />
                    </button>
                    <img alt='ALANI 360 logo'
                        src='/images/alani_logos_vertical_horizontal_orange_white.svg#svgView(viewBox(320, 90, 260, 70))'
                        style={{
                            width: 120, height: 60
                        }}
                    />
                    <div style={{ width: 100 }} />
                </div>
            </header>


            <main style={{
                margin: "auto",
                maxWidth: 1200,
                minWidth: 350,
                fontFamily: 'Montserrat',
            }}>

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignContent: 'center',
                        margin: 20,

                        flexDirection: 'column'
                    }}
                >


                    <div style={{
                        height: 40
                    }}>

                        {userData && userData.accountInfo && (userData.accountInfo.firstName)
                            &&
                            <button
                                onClick={() => navigate(`/p/${token}`)}
                                style={{
                                    textAlign: 'center',
                                    flex: 1,
                                    justifyContent: 'center',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignContent: 'center',
                                    borderStyle: 'none',
                                    backgroundColor: 'transparent'
                                }}>
                                <div style={{
                                    textAlign: 'center',
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'row'
                                }}>

                                    <IoPersonCircle
                                        size={20}
                                        style={{
                                            marginRight: 5
                                        }}
                                    />
                                    {userData.accountInfo.firstName} {userData.accountInfo.lastName}

                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        marginBottom: 10,
                                        alignItems: 'center',
                                        alignContent: 'center',
                                    }}
                                >
                                    
                                </div>
                            </button>

                        }
                    </div>




                </div>
                <hr
                    style={{
                        margin: 0
                    }}
                />
                <Styles.ContentDiv

                >
                    <IntercashApplyForm />
                </Styles.ContentDiv>

            </main>
        </div>
    );
}


export default IntercashPage;