import React, { useRef } from 'react';
import { useNavigate, useParams } from "react-router-dom";

import { ThreeDots } from "react-loader-spinner";

import BotonConfirmarCorreo from "../components/BotonConfirmarCorreo";
import registrationAPI from "../../api/registrationAPI";

import { useDispatch, useSelector } from 'react-redux';
import AccountInfoConfirmActions from "../components/AccountInfoConfirmActions";

import Select from 'react-select'

import Styles from "../../styles";

import {
    IoMailSharp,
    IoLocationSharp,
    IoCheckmarkCircle,
    IoCloseCircleSharp,
    IoChevronForwardSharp,
    IoHelpCircle,
    IoNotifications
} from "react-icons/io5";


import { useCookies } from 'react-cookie';


const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};




const AccountEditInit = ({
    fnReturn,
    fnSetStep,
    fnSetCodigo
}) => {

    const textInputCodigo = useRef(null);
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        userData,
    } = dataReducer;

    const [sendBy, setSendBy] = React.useState("");
    const [tokenSent, setTokenSent] = React.useState(false);
    const [codigo, setCodigo] = React.useState("");
    const [fetching, setFetching] = React.useState("");



    const fnCodeGet = async () => {


        if (sendBy === "") {
            alert("Debes escoger como recibir el código");
            return;
        }

        setFetching(true);
        let _payload = {
            accountId: userData.accountInfo.id,
            codeType: sendBy
        }
        let res = await registrationAPI.accountInfoCodeGet(_payload);

        setFetching(false);
        if (res.success) {
            setTokenSent(true);
        } else {

            alert(res.msg);
        }
    }


    const fnCodeValidate = async () => {

        if (codigo === "") {
            alert("Debes introducir el código");
            return;
        }
        setFetching(true);
        let _payload = {
            accountId: userData.accountInfo.id,
            codeType: sendBy,
            code: codigo,
            pin: sendBy.toString().toUpperCase() === "PIN" ? codigo : null
        }
        let res = await registrationAPI.accountInfoCodeValidate(_payload);

        setFetching(false);
        if (res.success) {
            fnSetCodigo(codigo);
            fnSetStep(2);
        } else {

            alert(res.msg);
        }

    }


    if (tokenSent) {
        return <Styles.ModalDiv>
            <div style={{
                margin: 50,
                width: 300,
                backgroundColor: '#fff',
                padding: 20,
                borderRadius: 10
            }}>



                {{
                    "email": <span>Código enviado por correo<br />{userData.accountInfo.email}</span>,

                    "cel": <span>Código enviado por Whatsapp<br />{userData.accountInfo.phoneNumber}</span>,

                    "pin": <span>Introduce tu número PIN</span>,

                }[sendBy]}

                <div
                    style={{
                        width: 100,
                        margin: "auto"
                    }}
                >


                    <label
                        style={{
                            ...styles_acct.row__label,
                            ...{
                                width: 80
                            }
                        }}
                    >
                        Código
                        <input
                            type="text"
                            value={codigo}
                            style={{
                                width: 80,
                                marginBottom: 5
                            }}
                            ref={textInputCodigo}
                            onChange={e => setCodigo(e.target.value)}
                            maxLength={10}
                            autoFocus={true}
                        />

                    </label>
                </div>


                <div style={{
                    flexDirection: 'row',
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    <Styles.ButtonCancel
                        style={{
                            width: 100,
                            marginTop: 30,
                            alignSelf: 'center'
                        }}
                        disabled={fetching}
                        onClick={() => {

                            fnSetStep(1);
                            setTokenSent(false);

                        }}
                    >
                        <span style={{
                            color: '#000000'
                        }}>Cancelar</span>


                    </Styles.ButtonCancel>


                    <Styles.ButtonMain
                        style={{
                            width: 100,
                            marginTop: 30,
                            alignSelf: 'center'
                        }}
                        disabled={fetching}
                        onClick={() => fnCodeValidate()}
                    >

                        {fetching ?
                            <ThreeDots
                                color="#ffffff"
                                height={10}
                                width={50}
                                timeout={5000}
                            />
                            :
                            <span style={{
                                color: '#000000'
                            }}>Continuar</span>
                        }


                    </Styles.ButtonMain>
                </div>

            </div>
        </Styles.ModalDiv>

    }

    return <Styles.ModalDiv>
        <div style={{
            margin: 50,
            width: 320,
            backgroundColor: '#fff',
            padding: 20,
            borderRadius: 10
        }}>

            Requieres un código de acceso para modificar estos datos

            <div style={{
                fontWeight: 'bold',
                marginTop: 10
            }}>Como quieres recibir el código?</div>
            <div style={{
                flexDirection: 'column',
                justifyContent: 'center',
                alignContent: 'center',
                display: 'flex',
                margin: 'auto',
                marginTop: 10
            }}>
                <button
                    onClick={() => setSendBy("cel")}
                    style={{
                        borderStyle: "solid",
                        borderWidth: 1,
                        borderRadius: 10,
                        borderColor: sendBy === "cel" ? "#FF9400" : "#c0c0c0",
                        backgroundColor: sendBy === "cel" ? "#c0c0c0" : "#e0e0e0",
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 320,
                        marginBottom: 5,
                        marginTop: 5
                    }}
                >


                    <span style={{ marginLeft: 10 }}>
                        Celular<br />{userData.accountInfo.phoneNumber}
                    </span>
                </button>

                <button
                    onClick={() => setSendBy("email")}
                    style={{
                        borderStyle: "solid",
                        borderWidth: 1,
                        borderRadius: 10,
                        borderColor: sendBy === "email" ? "#FF9400" : "#c0c0c0",
                        backgroundColor: sendBy === "email" ? "#c0c0c0" : "#e0e0e0",
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 320,
                        marginBottom: 5,
                        marginTop: 5
                    }}
                >

                    <span style={{ marginLeft: 10 }}>
                        Email<br />{userData.accountInfo.email}

                    </span>
                </button>

                {userData?.accountInfo?.pinCodeExists === 1 &&



                    <button
                        onClick={() => setSendBy("pin")}
                        style={{
                            borderStyle: "solid",
                            borderWidth: 1,
                            borderRadius: 10,
                            borderColor: sendBy === "pin" ? "#FF9400" : "#c0c0c0",
                            backgroundColor: sendBy === "pin" ? "#c0c0c0" : "#e0e0e0",
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: 320,
                            marginBottom: 5,
                            marginTop: 5
                        }}
                    >

                        <span style={{ marginLeft: 10, padding: 5 }}>
                            Usar mi código PIN
                        </span>
                    </button>
                }
            </div>


            <div style={{
                flexDirection: 'row',
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                <Styles.ButtonCancel
                    style={{
                        width: 100,
                        marginTop: 30,
                        alignSelf: 'center'
                    }}
                    disabled={fetching}
                    onClick={() => {

                        fnReturn();

                    }}
                >
                    <span style={{
                        color: '#000000'
                    }}>Cancelar</span>


                </Styles.ButtonCancel>


                <Styles.ButtonMain
                    disabled={fetching}
                    style={{
                        width: 120,
                        marginTop: 30,
                        alignSelf: 'center'
                    }}
                    onClick={() => {
                        fnCodeGet();
                    }}
                >
                    {fetching ?
                        <ThreeDots
                            type={"ThreeDots"}
                            color="#ffffff"
                            height={10}
                            width={50}
                            timeout={5000}
                        />
                        :
                        <span style={{
                            color: '#000000'
                        }}>Continuar</span>
                    }



                </Styles.ButtonMain>
            </div>
        </div>
    </Styles.ModalDiv>
}
const AccountEdit = ({
    fnReturn
}) => {

    const cookies = useCookies(['alani360']);
    const phone_cookie = (cookies[0] && cookies[0].alani360 && ("+" + cookies[0].alani360.phoneNumber));
    const token = useParams().token || "";
    const dispatch = useDispatch();
    const textInputPhone = useRef(null);
    const textInputEmail = useRef(null);
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        userData,

    } = dataReducer;



    const [step, setStep] = React.useState(1); ///
    const [accountUpdated, setAccountUpdated] = React.useState(false); ///

    const [phone, setPhone] = React.useState(userData.accountInfo.phoneNumber);
    const [PIN, setPIN] = React.useState("");
    const [changePin, setChangePin] = React.useState(userData?.accountInfo?.pinCodeExists === 0);
    const [codigo, setCodigo] = React.useState();

    const [email, setEmail] = React.useState(userData?.accountInfo?.email || "");
    const [fetching, setFetching] = React.useState(false);

    const handleSubmit = async () => {
        let _error_msg = "";
        if (
            (phone || "").toString().trim() === ""

        ) {
            _error_msg = "Debe usar un número de celular válido\n";
            textInputPhone.current.focus();
        }
        let _email = email.toString().toLowerCase().trim();
        if (!validateEmail(_email)) {
            _error_msg += "Debe usar una direccion de correo electrónico válida\n";
            textInputEmail.current.focus();
        }

        if (changePin && PIN.length < 4) {
            _error_msg += "Debe utilizar un código PIN de 4 carácteres";
        }

        if (_error_msg !== "") {
            alert(_error_msg);
            return;
        }

        let _payload = {
            accountId: userData.accountInfo.id,
            phone: phone.replace("+", ""),
            email: email,
            PIN: changePin ? PIN : null,
            code: codigo
        }

        setFetching(true);
        let res = await registrationAPI.accountInfoUpdate(_payload);
        setFetching(false);
        if (res.success) {
            setAccountUpdated(res);
        } else {
            alert(res.msg);
        }
    }

    if (accountUpdated) {

        if (

            accountUpdated.PINUpdated === 0
            &&
            accountUpdated.emailUpdated === 0
            &&
            accountUpdated.phoneUpdated === 0
        ) {
            return <Styles.ContentDiv
                style={{
                    paddingTop: 50
                }}
            >

                No realizaste ningún cambio a tu cuenta !

            </Styles.ContentDiv>
        }


        return <Styles.ContentDiv>

            <br />
            {
                (
                    accountUpdated.emailUpdated !== 0
                    ||
                    accountUpdated.phoneUpdated !== 0
                ) &&
                <AccountInfoConfirmActions
                    accountInfo={{
                        updateRequest_email: accountUpdated.emailUpdated ? accountUpdated.newEmail : null,
                        updateRequest_phone: accountUpdated.phoneUpdated ? accountUpdated.newPhone : null

                    }}

                />

            }

            <br />
            {
                accountUpdated.PINUpdated === 1
                &&
                <div>
                    Tu código PIN ha sido cambiado
                </div>
            }


            <Styles.ButtonMain
                style={{
                    width: 100,
                    marginTop: 20,
                    alignSelf: 'center'
                }}
                onClick={() => {


                    dispatch({
                        type: "USER_DATA_FETCH",
                        token,
                        phone: userData.accountInfo.phoneNumber || phone_cookie
                    });
                    fnReturn();
                }}
            >

                <span >OK</span>


            </Styles.ButtonMain>

        </Styles.ContentDiv>
    }

    if (step === 1) {
        return <AccountEditInit
            fnReturn={fnReturn}
            fnSetStep={(v) => setStep(v)}
            fnSetCodigo={(c) => setCodigo(c)}
        />
    }

    return <Styles.ContentDiv>

        <label
            style={styles_acct.row__label}
        >
            <span style={{
                marginLeft: 40
            }}>Celular</span>

            <input
                value={phone}
                disabled={fetching}
                onChange={e => setPhone(e.target.value)}

                autoFocus
                ref={textInputPhone}
            />
        </label>


        <label
            style={styles_acct.row__label}
        >
            Correo Electrónico
            <input
                type="text"
                value={email}
                style={{
                    width: 270,
                    marginBottom: 5
                }}
                ref={textInputEmail}
                onChange={e => setEmail(e.target.value)}
                maxLength={250}
            />

        </label>
        {changePin ?
            <label
                style={styles_acct.row__label}
            >
                Código PIN
                <div
                    style={{
                        flexDirection: 'row',
                        display: 'flex',
                        alignContent: 'center'
                    }}
                >
                    <input
                        value={PIN}
                        style={{
                            width: 60,
                        }}
                        type={"text"}
                        onChange={e => setPIN(e.target.value)}
                        maxLength={4}
                    />
                    {userData?.accountInfo?.pinCodeExists === 1 &&
                        <button
                            style={{
                                marginLeft: 10,
                                backgroundColor: '#e0e0e0',
                                borderStyle: 'solid',
                                borderColor: '#c0c0c0',
                                borderWidth: 1,
                                borderRadius: 10,
                                padding: 5,
                                flexDirection: 'row',
                                display: 'flex',
                                alignContent: 'flex-start'
                            }}
                            onClick={() => setChangePin(!changePin)}
                        >
                            <span style={{
                                fontSize: 11,
                                marginRight: 10
                            }}>NO CAMBIAR</span>
                            <IoCloseCircleSharp />
                        </button>
                    }
                </div>

            </label>
            :
            <button
                style={{
                    borderStyle: 'none',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    margin: 10,
                    color: 'blue',
                    backgroundColor: 'transparent'
                }}
                onClick={() => setChangePin(!changePin)}
            >
                Cambiar PIN
            </button>
        }



        <Styles.ButtonMain
            style={{
                width: 200,
                marginTop: 20,
                alignSelf: 'center'
            }}
            disabled={false}
            onClick={() => {
                handleSubmit();

            }}
        >

            {fetching ?
                <ThreeDots
                    type={"ThreeDots"}
                    color="#ffffff"
                    height={10}
                    width={50}
                    timeout={5000}
                />
                :
                <span style={{
                    color: '#000000'
                }}>Confirmar Cambios</span>
            }


        </Styles.ButtonMain>


        <Styles.ButtonCancel
            style={{
                width: 100,
                marginTop: 30,
                alignSelf: 'center'
            }}
            disabled={false}
            onClick={() => {

                fnReturn()

            }}
        >

            <span style={{
                color: '#000000'
            }}>Cancelar</span>


        </Styles.ButtonCancel>
    </Styles.ContentDiv>
}


const AccountAddressView = (
    {
        token
    }
) => {

    const cookies = useCookies(['alani360']);
    const phone_cookie = (cookies[0] && cookies[0].alani360 && ("+" + cookies[0].alani360.phoneNumber));
    const dispatch = useDispatch();
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        userData,
    } = dataReducer;

    const [fetching2, setFetching2] = React.useState(false);


    if (!userData || !userData.accountInfo) {
        return <ThreeDots />
    }

    const fnChangeAddress = async () => {
        setFetching2(true);
        let res = await registrationAPI.addressChange({
            accountId: userData.accountInfo.id,
            alani_token: btoa(token),
        });
        setFetching2(false);
        if (res.success) {
            alert("Dirección Actualizada")
        }

        dispatch({
            type: "USER_DATA_FETCH",
            phone: userData.accountInfo.phone || phone_cookie,
            token,
            no_fetch_indicator: true
        });
    }

    return <div
        style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            alignContent: 'center',
            flexDirection: 'column'
        }}
    >

        <div style={{
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'center'
        }}>
            <IoLocationSharp />
            <span style={{ fontWeight: 'bold' }}>Dirección de Entregas</span>
        </div>
        <div
        >
            {userData.accountInfo.firstName} {userData.accountInfo.lastName}
            <br />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <span   >{userData.accountInfo.shippingAddress1}</span>
                <span >{userData.accountInfo.shippingAddress2}</span>
            </div>
            {userData.accountInfo.shippingCity}, {userData.accountInfo.shippingState},  {userData.accountInfo.shippingPostalCode}
        </div>
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            alignContent: 'center'
        }}>

            {userData.accountInfo.countryCode}
            <img
                alt={`${userData.accountInfo.countryCode} Flag`}
                style={{
                    height: 15,
                    marginLeft: 20
                }}
                src={`https://flag.pk/flags/4x3/${userData.accountInfo.countryCode}.svg`}
            />
        </div>

        <button
            style={{
                width: 230,
                marginTop: 10,
                backgroundColor: 'transparent',
                borderStyle: 'none',
                textDecoration: 'underline',
                cursor: 'pointer',
                color: 'blue',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
            }}
            disabled={fetching2}
            onClick={() => {
                let confirm = window.confirm("Deseas solicitar cambios a tu dirección de envíos?");
                if (confirm) {
                    fnChangeAddress();
                }
            }}
        >
            {fetching2 ?
                <ThreeDots
                    type="ThreeDots"
                    color="#000"
                    height={10}
                    width={50}
                    timeout={10000}
                />
                :
                "Modificar"
            }

        </button>
    </div>
}

const AccountAddressEdit = ({
    token
}) => {

    const cookies = useCookies(['alani360']);
    const phone_cookie = (cookies[0] && cookies[0].alani360 && ("+" + cookies[0].alani360.phoneNumber));
    const dispatch = useDispatch();
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        userData,
    } = dataReducer;

    const [fetching2, setFetching2] = React.useState(false);
    const [address1, setAddress1] = React.useState(userData?.accountInfo?.shippingAddress1 || "");
    const [address2, setAddress2] = React.useState(userData?.accountInfo?.shippingAddress2 || "");

    const [shippingCity, setShippingCity] = React.useState(userData?.accountInfo?.shippingCity || "");
    const [shippingState, setShippingState] = React.useState(userData?.accountInfo?.shippingState || "");

    const [shippingCityId, setShippingCityId] = React.useState(userData?.accountInfo?.shippingCityId || 0);
    const [shippingStateId, setShippingStateId] = React.useState(userData?.accountInfo?.shippingStateId || 0);



    const [shippingPostalCode, setShippingPostalCode] = React.useState(userData?.accountInfo?.shippingPostalCode || "");


    const fnAddressSave = async () => {


        if (
            (address1 || "").trim === ""
            ||
            (shippingCity || "") === ""
            ||
            (shippingState || "") === ""

        ) {
            alert("La dirección, ciudad y estado son requeridos");
            return;
        }


        setFetching2(true);
        let res = await registrationAPI.addressSave({
            accountId: userData.accountInfo.id,
            alani_token: token,
            shippingAddress1: address1,
            shippingAddress2: address2,
            shippingCity,
            shippingState,
            shippingPostalCode,
            shippingCityId,
            shippingStateId
        });

        if (!res.success) {
            alert(res.message)
        }

        let res_transExpress = await registrationAPI.transExpressCreate({
            accountId: userData.accountInfo.id
        });

        if (!res_transExpress.success) {
            alert(res_transExpress.msg)
        }
        console.warn(res_transExpress);



        dispatch({
            type: "USER_DATA_FETCH",
            phone: phone_cookie,
            token,
            no_fetch_indicator: true
        });

        //setFetching2(false);

    }

    return <div
        style={{
            paddingBottom: 200
        }}
    >
        <div
            style={{
                ...styles_acct.row,
                ...{
                    marginTop: 0,
                }
            }}
        >

            <label
                style={{
                    ...styles_acct.row__label,

                }}
            >

                <div className='div-row'
                    style={{
                        color: userData?.accountInfo.shippingAddressConfirmed === 0 ? 'red' : '#000'
                    }}
                >
                    {
                        userData?.accountInfo.shippingAddressConfirmed === 0 &&
                        <IoNotifications style={{ fill: 'red', marginRight: 5 }} />
                    }
                    Dirección de Entregas
                </div>
                <input
                    type="text"
                    value={address1}
                    style={{
                        width: 250,
                        marginBottom: 5
                    }}
                    onChange={e => setAddress1(e.target.value)}
                    maxLength={250}
                />
                <input
                    type="text"
                    value={address2}
                    style={{
                        width: 250,
                    }}
                    onChange={e => setAddress2(e.target.value)}
                    maxLength={250}
                />
            </label>


            <UserAccountStateSelect
                fnReturnValues={({ city, state, cityId, stateId }) => {
                    setShippingCity(city);
                    setShippingState(state);
                    setShippingCityId(cityId);
                    setShippingStateId(stateId);
                }}
            />



            <div
                style={{
                    flexDirection: 'row',
                    display: 'flex',
                    alignItems: 'flex-end'
                }}
            >
                <label
                    style={styles_acct.row__label}
                >
                    Código Postal
                    <input
                        type="text"
                        value={shippingPostalCode}
                        style={{
                            width: 80,
                            marginBottom: 5
                        }}
                        onChange={e => setShippingPostalCode(e.target.value)}
                        maxLength={20}
                    />

                </label>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: 8
                }}>
                    <img
                        alt={`${userData.accountInfo.countryCode} Flag`}
                        style={{
                            height: 15,
                            marginRight: 10
                        }}
                        src={`https://flag.pk/flags/4x3/${userData.accountInfo.countryCode}.svg`}
                    />
                    {userData.accountInfo.countryCode}
                </div>
            </div>
        </div>

        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 20
            }}
        >
            <Styles.ButtonMain
                style={{
                    width: 270
                }}
                onClick={fnAddressSave}
                disabled={fetching2}
                fetching={fetching2}
            >
                {fetching2 ?
                    <ThreeDots
                        color="#000"
                        height={10}
                        width={50}
                        timeout={10000}
                    />
                    :
                    <span  >Confirmar Dirección</span>
                }

            </Styles.ButtonMain>
        </div>



    </div>
}


const UserAccountStateSelect = ({
    fnReturnValues
}) => {
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        userData,
    } = dataReducer;

    const [fetching, setFetching] = React.useState(undefined);


    const [cities, setCities] = React.useState([]);
    const [city, setCity] = React.useState(undefined);

    const [states, setStates] = React.useState([]);
    const [state, setState] = React.useState(undefined);

    let countryCode = userData?.accountInfo.countryCode;



    const paramsFetch = ({
        stateId
    }) => {
        return new Promise((resolve, reject) => {
            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/shippingAddress/${countryCode}`;

            if (stateId) {
                url += `?stateId=${stateId}`
            }

            fetch(url, {
                method: "GET",
                headers: {
                    // "Content-Type": "application/json",
                }
            }).then(res => res.json()).then(res => {
                //setStates(res.states)
                let _states = res.states.map((state) => {
                    return {
                        value: state.id,
                        label: state.name_es
                    }
                })
                setStates(_states);

                let _cities = res.cities.map((city) => {
                    return {
                        value: city.id,
                        label: city.name_es
                    }
                })

                setCities(_cities);
                setFetching(undefined)
                resolve();
            })
                .catch(err => {
                    console.warn(err)
                    reject(err);

                })
        });

    }

    /* eslint-disable */
    React.useLayoutEffect(() => {
        if (userData.accountInfo.shippingStateId) {
            setState({
                value: userData.accountInfo.shippingStateId,
                label: userData.accountInfo.shippingState
            });
            setCity({
                value: userData.accountInfo.shippingCityId,
                label: userData.accountInfo.shippingCity
            });

        } else {
            if (countryCode) {
                setFetching("states")
                paramsFetch({});
            }
        }


    }, []);
    React.useLayoutEffect(() => {
        setFetching("cities")
        paramsFetch({
            stateId: state?.value
        })
    }, [state]);
    React.useLayoutEffect(() => {
        fnReturnValues({
            city: city?.label,
            cityId: city?.value,
            state: state?.label,
            stateId: state?.value
        });
    }, [city]);


    /* eslint-enable */

    return <div>


        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center', marginTop: 10,

            }}
        >
            <div
                style={{
                    width: 270
                }}
            >
                <Select
                    onChange={(e) => {
                        setState(e);
                        setCity(undefined);
                    }}
                    value={state}
                    blurInputOnSelect
                    placeholder={"Estado"}
                    options={states}
                />
            </div>

            <div
                style={{
                    width: 270,
                    marginTop: 10,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 30,
                }}
            >
                {!!fetching ?

                    <ThreeDots width={50} />
                    :
                    <div
                        style={{
                            width: 270
                        }}
                    >
                        <Select
                            onChange={(e) => {
                                setCity(e);
                            }}
                            value={city}
                            blurInputOnSelect
                            placeholder={"Ciudad"}
                            options={cities}
                        />
                    </div>
                }
            </div>


        </div>

    </div>
}

const UserAccount = ({
    token
}) => {

    //const dispatch = useDispatch();

    const navigate = useNavigate();
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        userData,
    } = dataReducer;

    // eslint-disable-next-line
    const [fetching2, setFetching2] = React.useState(false);
    const [editarCuenta, setEditarCuenta] = React.useState(false); ///

    let _phone = userData.accountInfo && userData.accountInfo.phoneNumber ? userData.accountInfo.phoneNumber : {};
    /*
        if (
            account_address_select
        ) {
            return <UserAccountStateSelect />
        }*/

    if (editarCuenta) {
        return <AccountEdit
            fnReturn={() => setEditarCuenta(false)}
        />
    }

    return <div style={{
        paddingTop: 5,
        display: 'flex',
        flexDirection: 'column'
    }}>

        <div style={{
            marginTop: 5,
            display: 'flex',
            flexDirection: 'column',
        }}>
            <span
                style={{
                    fontWeight: 'bold',
                    marginBottom: 10
                }}
            >{userData.accountInfo.firstName} {userData.accountInfo.lastName}</span>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    margin: 'auto',
                    marginBottom: 5,
                    alignItems: 'center',
                    alignContent: 'center',
                }}
            >
                {_phone}
            </div>



            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    alignSelf: 'center',
                    marginBottom: 5,

                }}
                onClick={() => {
                    if (!userData.accountInfo.email_confirmed) {
                        alert(`Debes confirmar tu cuenta de correo`);
                    }
                }}
            >

                <IoMailSharp
                    size={20}
                    style={{
                        marginRight: 5,
                        fill: userData.accountInfo.email_confirmed !== null ? 'green' : 'red'
                    }}
                />

                {userData.accountInfo.email}

            </div>

            {userData.accountInfo.email_confirmed == null && <BotonConfirmarCorreo
                accountId={userData.accountInfo.id}
                email={userData.accountInfo.email}
                fnAfterSend={(res) => {
                    alert(`Revisa tu correo para confirmarlo`)
                }}
            />}

            {
                (
                    userData.accountInfo.updateRequest_email !== null
                    ||
                    userData.accountInfo.updateRequest_phone !== null
                )
                &&
                <button
                    onClick={() => navigate(`/account/${userData?.accountInfo?.id}/update_confirm/${token}`)}
                    style={{
                        width: 320,
                        padding: 10,
                        backgroundColor: 'yellow',
                        margin: 'auto',
                        marginTop: 10,
                        borderRadius: 10,
                        borderWidth: 1,
                        borderColor: '#303030',
                        borderStyle: 'solid',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                    }}
                >
                    En espera de confirmación de cambios solicitados
                    <IoChevronForwardSharp
                        size={25}
                    />
                </button>
            }


            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    alignSelf: 'center',
                    marginTop: 10
                }}
            >
                {userData.accountInfo.pinCodeExists ?
                    <React.Fragment>
                        <IoCheckmarkCircle size={20} style={{ fill: 'green' }} />
                        PIN ****
                    </React.Fragment>
                    :
                    <button
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            borderStyle: 'none',
                            backgroundColor: 'transparent'
                        }}
                        onClick={() => {
                            if (window.confirm("Deseas crear un PIN para acceder tu cuenta?")) {
                                setEditarCuenta(true)
                            }
                        }}
                    >
                        No has creado un PIN de acceso
                        <IoHelpCircle size={25} />
                    </button>
                }

            </div>


            <button
                style={{
                    width: 230,
                    marginTop: 10,
                    alignSelf: 'center',
                    borderStyle: 'none',
                    backgroundColor: 'transparent',
                    textDecoration: 'underline',
                    color: 'blue'
                }}
                disabled={fetching2}
                onClick={() => {

                    setEditarCuenta(true)

                }}
            >
                {fetching2 ?
                    <ThreeDots
                        type="ThreeDots"
                        color="#fff"
                        height={10}
                        width={50}
                        timeout={10000}
                    />
                    :
                    <React.Fragment>Modificar Datos</React.Fragment>
                }

            </button>



        </div>

        <div
            style={{
                width: '100%'
            }}
        >
            <hr />
        </div>

        <div style={{
            marginTop: 10,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 0,
        }}>


            <div style={{
                minWidth: 320,
                maxWidth: 500,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                paddingBottom: 30,
            }}>

                {{
                    "0": <AccountAddressEdit
                        token={token}
                    />,
                    "1": <div>

                        <AccountAddressView
                            token={token}
                        />
                    </div>,
                    "2": <AccountAddressView
                        token={token}
                    />


                }[userData.accountInfo.shippingAddressConfirmed]

                }



            </div>


        </div>



    </div>
}


export default UserAccount;


const styles_acct = {
    row: {
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        margin: 10
    },
    row__label: {
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'flex-start',
        marginTop: 10
    },
    button_select: {
        borderStyle: 'none',
        backgroundColor: 'transparent',
        padding: 10,
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        width: 120,
        marginRight: '10',
        cursor: 'pointer'
    }
}