import React from "react";


const AlaniHeaderLogo = () => {

    return <div style={{ position: 'relative' }} >
        <img alt='ALANI 360 logo'
            src='/images/alani_logos_vertical_horizontal_orange_white.svg#svgView(viewBox(320, 90, 260, 70))'
            style={{
                width: 120, height: 60
            }}
        />
        {process.env.REACT_APP_API_LANG === 'br' &&
            <img
                alt="Brazilian flag"
                src='/images/brazil.svg'
                style={{
                    width: 25,
                    position: 'absolute',
                    right: -15,
                    bottom: 5

                }}
            />
        }
    </div>

}

export default AlaniHeaderLogo;