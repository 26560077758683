const token = localStorage.getItem("token");

const API = {

    infoGet: (args) => {

        //arn:aws:lambda:us-east-1:645160913840:function:Alani-HumanAPI-InfoGet

        return new Promise((resolve, reject) => {


            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/admin/hapi/info`


            var headers = new Headers();

            headers.append("Authorization", token);
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            fetch(url, {
                headers,
                method: 'GET',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    reject(err);
                });
        })
    },


    hapiRequestSend: (args) => {

        // https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/admin/hapi/request
        //arn:aws:lambda:us-east-1:645160913840:function:Pricing-RFQPrepareSendUnquoted

        return new Promise((resolve, reject) => {


            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/admin/hapi/request/?users=${args.users}&rfqs=${args.rfqs}`;
            console.warn(url)
            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            headers.append("Authorization", token);

            fetch(url, {
                headers,
                method: 'POST',
                //body: JSON.stringify(args)
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })

    },


}
export default API;

