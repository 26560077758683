import React, { useState } from 'react';

import API from "../../api/APIStats";

import Header from '../../app/AdminComponents/Header';

import { useNavigate } from "react-router-dom";
import {   ThreeDots } from "react-loader-spinner";
///import { useParams } from "react-router-dom"; 

import moment from 'moment-timezone';
// eslint-disable-next-line
import localization from 'moment/locale/es'
import Styles from "../../styles";


moment.locale('es');

const List = ({
    res
}) => {
    return <div
        style={{
            borderColor: "#808080",
            borderWidth: 1,
            borderStyle: 'solid'
        }}
    >
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: '#808080'
            }}
        >
            <span style={{
                flex: 1,
                textAlign: 'center',
                color: '#fff'
            }}>
                Week
            </span>
            <span style={{
                flex: 1,
                textAlign: 'center',
                color: '#fff'
            }}>
                RFQs
            </span>
            <span style={{
                flex: 1,
                textAlign: 'center',
                color: '#fff'
            }}>
                Parts
            </span>
            <span style={{
                flex: 1,
                textAlign: 'center',
                color: '#fff'

            }}>
                API1
            </span>
            <span style={{
                flex: 1,
                textAlign: 'center',
                color: '#fff'
            }}>
                API2
            </span>
            <span style={{
                flex: 1,
                textAlign: 'center',
                color: '#fff'
            }}>
                Quoted
            </span>
            <span style={{
                flex: 1,
                textAlign: 'center',
                color: '#fff'
            }}>
                Weight
            </span>
            <span style={{
                flex: 1,
                textAlign: 'center',
                color: '#fff'
            }}>
                Price
            </span>
        </div>
        {res?.map((r, ix) => {


            return <div key={ix}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    backgroundColor: ix % 2 ? "#fff" : "#c0c0c0"
                }}
            >


                <span style={{
                    flex: 1,
                    textAlign: 'center'
                }}>{r.year_week.substr(2)}</span>

                <span style={{
                    flex: 1,
                    textAlign: 'center'
                }}>{r.rfqsCount}</span>

                <span style={{
                    flex: 1,
                    textAlign: 'center'
                }}>{r.itemsCount}</span>

                <span style={{
                    flex: 1,
                    textAlign: 'center'
                }}>{r.foundApi1_Count}</span>
                <span style={{
                    flex: 1,
                    textAlign: 'center',
                }}>{r.foundApi2_Count}</span>
                <span style={{
                    flex: 1,
                    textAlign: 'center'
                }}>{r.quoted_Count}</span>
                <span style={{
                    flex: 1,
                    textAlign: 'center'
                }}>{r.weightFound_Count}</span>
                <span style={{
                    flex: 1,
                    textAlign: 'center'
                }}>{r.priceFound_Count}</span>
            </div>
        })

        }


    </div>
}

const APIStats = () => {

    const navigate = useNavigate();
    const [fetching2, setFetching2] = useState(false);

    // eslint-disable-next-line
    const [results, setResults] = useState();
    // eslint-disable-next-line
    const [rfqId, setRfqId] = useState();

    // eslint-disable-next-line
    const [statType, setStatType] = useState(1);


    let fnDataGet = async () => {
        setFetching2(true);
        let res = await API.apiStatsFetch({
            rfqId,
            statType
        });
        setFetching2(false);
        setResults(res);

    }



    /* eslint-disable */
    React.useEffect(() => {
        fnDataGet();
    }, []);
    /* eslint-enable */

    if (!localStorage.getItem("token")) {
        navigate("/login")
        return null
    }


    return (
        <div>

            <Header />

            <Styles.PageTitle>API Stats</Styles.PageTitle>

            <main style={{
                margin: "auto",
                maxWidth: 700,
                minWidth: 350,
                fontFamily: 'Montserrat',

            }}>


                {fetching2 ?
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: 50
                    }}>
                        <ThreeDots
                            type={"ThreeDots"}
                            color="#FF9400"
                            height={20}
                            width={70}
                            timeout={5000}
                        />
                    </div>
                    :

                    <div>
                        <div>
                            <List
                                res={results?.results[0]}
                            />
                        </div>


                        {JSON.stringify(results?.results[0])}
                        {JSON.stringify(results?.results[1])}

                    </div>
                }


            </main>
        </div>
    );
}


export default APIStats;