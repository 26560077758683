import React, { useState } from 'react';

import purchaseOrderAPI from "../../api/purchaseOrderAPI";
import emailAPI from "../../api/emailsAPI";

import Header from '../../app/AdminComponents/Header';

import { useParams, useNavigate, useSearchParams } from "react-router-dom";

// eslint-disable-next-line
import localization from 'moment/locale/es'

import { ThreeDots } from "react-loader-spinner";
import Styles from "../../styles";

import { useDispatch, useSelector } from 'react-redux';

import {
    IoAttach,
    IoCheckmarkCircleSharp
} from "react-icons/io5";
const EmailForm = () => {
    /* eslint-disable */
    const [searchParams, setSearchParams] = useSearchParams();
    const replyToEmailId = searchParams.get("replyTo");
    /* eslint-enable */
    const navigate = useNavigate();
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        purchaseOrder,
    } = dataReducer;


    const [recipients, setRecipients] = React.useState("");
    const [body, setBody] = React.useState("");
    const [subject, setSubject] = React.useState("");
    const [attachments, setAttachments] = React.useState({ 1: true });
    /* eslint-disable */
    const [emailReplyTo, setEmailReplyTo] = React.useState();
    /* eslint-enable */
    const [fetching2, setFetching2] = React.useState(false);

    const fnAttach = (id, attach) => {
        let _attachments = { ...attachments };
        _attachments[id] = attach ? true : null;
        setAttachments(_attachments);

    }

    const fnSendEmail = async () => {
        let _payload = {
            rfqId: purchaseOrder.id,
            recipients,
            subject,
            email_body: body,
            attachmentIds: attachments
        }
        setFetching2(true);
        await emailAPI.sendRFQ(_payload);
        setFetching2(false);
        navigate(`/admin/rfq/${purchaseOrder.id}?v=emails`)
    }

    /* eslint-disable */
    React.useEffect(() => {

        setRecipients(`${purchaseOrder.userName} <${purchaseOrder.userEmail}>`);
        let emailReply = purchaseOrder.emails.find(e => e.emailId == replyToEmailId);

        if (emailReply) {
            setEmailReplyTo(emailReply);
            setSubject(`RE: ${emailReply.subject}`)
        }

    }, []);
    /* eslint-enable */

    return <div
        style={{
            width: "100%",
        }}
    >


        <label
            style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: 5

            }}
        >
            To
            <input
                value={recipients}
                onChange={e => setRecipients(e.target.value)}
                type="text"

            />
        </label>

        <label
            style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: 5
            }}
        >
            Subject
            <input
                value={subject}
                onChange={e => setSubject(e.target.value)}
                type="text"

            />
        </label>


        <label
            style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: 5
            }}
        >
            Body
            <textarea
                style={{
                    height: 70
                }}
                value={body}
                onChange={e => setBody(e.target.value)}
                type="text"

            />
        </label>
        {purchaseOrder != null &&
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 5
                }}
            >
                <label>Attachments</label>

                <span
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        display: 'flex',
                        color: 'blue',
                        marginBottom: 10
                    }}
                >
                    <button
                        onClick={() => fnAttach(1, attachments[1] == null)}
                        style={{
                            backgroundColor: 'transparent',
                            borderStyle: 'none',
                            marginRight: 5,
                            height: 25,
                            width: 50
                        }}
                    >
                        {attachments[1] == null ?
                            <div style={{ width: 20, height: 20, borderRadius: 10, borderColor: 'blue', borderWidth: 2, borderStyle: 'solid' }} ></div>
                            :
                            <IoCheckmarkCircleSharp size={25} />
                        }
                    </button>
                    Quote
                    <IoAttach
                        size={25}
                        color={"blue"}
                        style={{
                            fill: 'blue'
                        }}
                    />
                </span>






            </div>
        }



        <div style={{ marginTop: 10 }}>
            <Styles.ButtonMain
                disabled={fetching2}
                onClick={() => fnSendEmail()}
                style={{ width: 100 }}
            >
                {fetching2 ?
                    <ThreeDots
                        type={"ThreeDots"}
                        color="#3482B3"
                        height={10}
                        width={50}
                        timeout={5000}
                    />
                    :
                    <span style={{ color: '##000' }}>Send</span>
                }
            </Styles.ButtonMain>
        </div>



        {JSON.stringify(purchaseOrder)}
    </div>

}

const Email = () => {

    const navigate = useNavigate();
    const purchaseOrderId = useParams().purchaseOrderId;
    const [fetching2, setFetching2] = useState(true);
    const dispatch = useDispatch();
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        purchaseOrder,
    } = dataReducer;

    let fnPOGet = async () => {
        setFetching2(true);
        let res = await purchaseOrderAPI.poGet({
            purchaseOrderId
        });

        setFetching2(false);
        if (res.success) {


            dispatch({
                type: 'PO_SET',
                purchaseOrder: res.po,
            });
            dispatch({
                type: 'PO_ITEMS_SET',
                purchaseOrderItems: res.items
            });
        }
    }

    /* eslint-disable */
    React.useEffect(() => {

        fnPOGet();
    }, []);
    /* eslint-enable */


    if (!localStorage.getItem("token")) {
        navigate("/login")
        return null
    }



    return (
        <div>

            <Header />

            <Styles.PageTitle>Email RFQ {purchaseOrder?.customerReference}</Styles.PageTitle>

            <main style={{
                margin: "auto",
                maxWidth: 500,
                minWidth: 350,
                fontFamily: 'Montserrat',

            }}>




                {fetching2 ?
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: 50
                    }}>
                        <ThreeDots
                            type={"ThreeDots"}
                            color="#FF9400"
                            height={20}
                            width={70}
                            timeout={5000}
                        />
                    </div>
                    :


                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        margin: 10
                    }}>

                        <div
                            style={{
                                marginBottom: 10
                            }}
                        >
                            <button onClick={() => navigate(-1)} >&lt; Back</button>
                        </div>
                        {purchaseOrder != null &&
                            <EmailForm />
                        }

                    </div>
                }
            </main>
        </div>
    );
}


export default Email;

