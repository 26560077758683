const token = localStorage.getItem("token");


const SearchAPI = {

    scrape: (args) => {
        return new Promise((resolve, reject) => {

            let {
                partno,
                brand
            } = args;

            let querystring = `?brand=${brand}`;
            //querystring += '&search_url=' + encodeURIComponent('https://www.mazda-parts.com/search?search_str=PEHH15200')
            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/scraper/${partno}${querystring}`;


            var headers = new Headers();
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            headers.append("Authorization", token);
            fetch(url, {
                headers,
                method: 'GET',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    reject(err);
                });
        })
    },

    partLookup: (args) => {

        //cloud9-AlaniPO-AutopartsAPILookup-1WwJpDoLBxdX 
        return new Promise((resolve, reject) => {

            let {
                partno,
                destinationCity,
                customerId,
                detailed_response
            } = args;

            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/admin/autopartsapi/${partno}?destinationCity=${destinationCity}&customerId=${customerId}&detailed_response=${detailed_response ? 1 : 0}`;

            var headers = new Headers();
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            headers.append("Authorization", token);
            fetch(url, {
                headers,
                method: 'GET',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    reject(err);
                });
        })
    },




}

export default SearchAPI