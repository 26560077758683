import React, { useState } from 'react';
import API from "../../api/APIStats";
import { ThreeDots } from "react-loader-spinner";

import { useSelector } from 'react-redux';

import moment from 'moment-timezone';

const Item = React.memo(({
    label,
    value
}) => {
    return <div style={{
        display: 'flex',
        flexDirection: 'column',
        marginRight: 10,
        alignItems: 'center'
    }}>
        <span style={{ color: '#3482B3', fontSize: 12 }}>{label}</span>
        <span style={{ fontWeight: 600 }}>{value}</span>
    </div>
})


const RFQApiSummary = ({
    data
}) => {

    return <div
        style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        }}
    >
        <Item
            label="Total"
            value={data.itemsCount}
        />

        <Item
            label="API 1"
            value={
                `${data.foundApi1_Count} / ${Math.round((data.foundApi1_Count * 100) / data.itemsCount)}%`
            }
        />

        <Item
            label="API 2"
            value={
                `${data.foundApi2_Count} / ${Math.round((data.foundApi2_Count * 100) / data.itemsCount)}%`
            }
        />

        <Item
            label="Quoted"
            value={
                `${data.quoted_Count} / ${Math.round((data.quoted_Count * 100) / data.itemsCount)}%`
            }
        />

    </div>
}

const RFQApiDetails = (
    { data }
) => {

    return <div
        style={{
            borderColor: '#000',
            borderWidth: 1,
            borderStyle: 'solid',
            borderBottomStyle: 'none'
        }}
    >
        {data.map((item, ix) => {

            return <div
                key={ix}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    borderBottomColor: '#000',
                    borderBottomWidth: 1,
                    borderBottomStyle: 'solid',
                    background: ix % 2 ? "#c0c0c0" : "#fff",
                    padding: 5
                }}
            >
                <span style={{
                    flex: 3,
                    display: "flex",
                    flexDirection: 'column'
                }}>
                    {item.ref2}
                    {item.hapi_updated &&
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            <span style={{ width: 90, textAlign: 'center', marginTop: 5, fontSize: 12, backgroundColor: 'red', color: '#fff', padding: 5, borderRadius: 5 }}>
                                {moment(item.hapi_updated).tz('America/New_York').format('MMM DD HH:mm')}

                            </span>
                            <span style={{
                                color: 'red',
                                fontSize: 10
                            }}>
                                {item.human_api_email}
                            </span>
                        </div>}


                </span>

                <div style={{
                    flex: 5,
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <span>{item.ref3}</span>

                    <span>{item.api_part_name}</span>
                    <span style={{
                        color: 'blue',
                        fontWeight: 'bold'
                    }}>
                        {item.partly_name}
                    </span>
                    <span style={{
                        color: 'blue'
                    }}>
                        {item.partly_description}
                    </span>
                    <span style={{
                        color: 'blue'
                    }}>

                        {item.brand}
                    </span>
                    {item.is_oe && <span style={{ width: 50, textAlign: 'center', marginTop: 5, fontSize: 12, backgroundColor: '#3784B4', color: '#fff', padding: 5, borderRadius: 5 }}>OEM</span>}


                </div>

                <div style={{
                    flex: 2,
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    {item.weight != null &&
                        <span>
                            {item.weight}
                        </span>
                    }
                    {item.height_mm != null &&
                        <span style={{
                            fontSize: 12
                        }}>
                            {`${item.height_mm} x ${item.width_mm} x ${item.length_mm} `}
                        </span>
                    }



                </div>
                {item.msrp != null
                    ?
                    <div style={{
                        flex: 1,
                        textAlign: 'center',

                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <span
                            style={{
                                background: 'green',
                                color: '#fff',
                                padding: 5,
                                borderRadius: 5
                            }}
                        >{item.msrp}</span>
                    </div>
                    :
                    <span style={{
                        flex: 1,
                    }}>
                    </span>
                }

            </div>

        })}
    </div >

}

const RFQApiStats = () => {
    const [fetching2, setFetching2] = useState(false);
    const dataReducer = useSelector((state) => state.dataReducer);
    let {
        purchaseOrder
    } = dataReducer;


    const [stats, setStats] = useState();

    let fnDataGet = async () => {
        setFetching2(true);
        let res = await API.apiStatsFetch({
            rfqId: purchaseOrder.id,
            statType: 1
        });
        setFetching2(false);
        setStats(res);

    }

    /* eslint-disable */
    React.useEffect(() => {
        if (stats == null && purchaseOrder != null) {
            fnDataGet();
        }

    }, [purchaseOrder]);
    /* eslint-enable */

    if (!stats || fetching2) {
        return <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                margin: 50
            }}
        >
            <ThreeDots
                type={"ThreeDots"}
                color="#FF9400"
                height={20}
                width={70}
                timeout={10000}
            />
        </div>
    }

    return <div>

        <RFQApiSummary
            data={stats.results[0][0]}
        />
        <RFQApiDetails
            data={stats.results[1]}
        />
    </div>
}

export default RFQApiStats;