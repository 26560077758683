import React, { useState } from 'react'
import { useParams } from "react-router-dom";
import { useSelector } from 'react-redux';

import {  ThreeDots } from "react-loader-spinner";
// eslint-disable-next-line
import localization from 'moment/locale/es';

import purchaseOrderAPI from "../api/purchaseOrderAPI";
import Styles from "../styles";

import moment from 'moment-timezone';
moment.locale('es');

var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

moment.locale('es');

const TABS = [
    "Todos",
    "Pendientes",
    "Aceptados",
    "Rechazados",
]

function toTitleCase(str) {
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}


const PurchaseOrderConfirm = ({
    purchaseOrder,
    approvedItems,
    poItemsFiltered,
    fnReloadPO
}) => {
    //let quoteCode = useParams().quoteCode;
    //const navigate = useNavigate();
    const [fetching2, setFetching2] = useState(false);
    const [comentarios, setComentarios] = useState("");
    const handleChange = (event) => {
        setComentarios(event.target.value);
    };
    //const dispatch = useDispatch();
    let arrayRejected = [], numberRejected = 0;
    let poItems = [];

    Object.keys(approvedItems).forEach(function (key) {
        if (approvedItems[key] === -1) {
            numberRejected++;
            arrayRejected.push(key);
            poItems.push({
                id: key,
                approved: -1
            });
        }
        if (approvedItems[key] === 1) {
            poItems.push({
                id: key,
                approved: 1
            });
        }
    });

    const fnPOConfirm = async () => {



        let _payload = {
            poId: purchaseOrder.po.id,
            userId: purchaseOrder.po.userId,
            poItems,
            comments: comentarios
        }

        setFetching2(true);
        let res = await purchaseOrderAPI.itemsApproveReject(_payload);

        setFetching2(false);

        if (res.success) {
            /*dispatch({
                type: "DISPLAY_MESSAGE_SET",
                message: "Orden de Compra Recibida"
            });*/
            alert("Orden recibida.")
            fnReloadPO();
        } else {
            alert(JSON.stringify(res))
        }
    }

    return <div>

        <div>

            {poItemsFiltered.map((e, ix) => {

                if (
                    approvedItems[e.id] !== 1
                ) {
                    return null
                }
                return <div
                    key={ix}
                    style={{
                        ...styles.row,
                        ...{
                            backgroundColor: approvedItems[e.id] === 1 ? "#00800050" : approvedItems[e.id] === -1 ? "#FF000050" : "#e0e0e0"

                        }
                    }}
                >
                    <div
                        style={styles.col}
                    >
                        <span>{e.c_name}</span>
                        <span>{e.ref1}</span>
                        <span>{e.ref2}</span>

                        <span>{e.ref5}</span>

                    </div>


                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}
                    >
                        <span style={{ fontWeight: 'bold', color: "#F58E00" }}>{formatter.format(e.quotedTotal)}</span>

                        <span>{e.etaDays} dias</span>


                    </div>

                </div>
            })}
        </div>


        <br />Comentarios
        <textarea
            style={{
                width: "90%",
                alignSelf: 'center',
                height: 50,
            }}
            hintText="xxx"
            id="noter-text-area"
            name="textarea" value={comentarios}
            onChange={handleChange}
        />

        <Styles.ButtonMain
            disabled={fetching2}
            onClick={() => fnPOConfirm()}
            style={{

                margin: 'auto'
            }}
        >
            {fetching2 ?
                <ThreeDots
                    type={"ThreeDots"}
                    color="#3482B3"
                    height={10}
                    width={50}
                    timeout={5000}
                />
                :
                <span style={{
                    marginRight: 20,
                    marginLeft: 20,
                }}>Confirmar Orden de Compra</span>
            }

        </Styles.ButtonMain>

        {numberRejected > 0 &&
            <div>
                <h2 style={{
                    textAlign: 'center',
                    color: 'red'
                }}>{numberRejected} rechazados</h2>
            </div>
        }

    </div>
}

const POQuote = () => {
    let quoteCode = useParams().quoteCode;
    ///let cookies = useCookies(['alani360']);

    const dataReducer = useSelector((state) => state.dataReducer);
    let {
        display_message
    } = dataReducer;

    const [fetching2, setFetching2] = React.useState(true);
    const [purchaseOrder, setPurchaseOrder] = React.useState({ po_items: [] });
    const [approvedItems, setApprovedItems] = React.useState({});
    const [poStep, setPoStep] = React.useState(0)
    const [selectedTab, setSelectedTab] = React.useState(1);
    const [itemsCount, setItemsCount] = React.useState({});
    const [poItemsFiltered, setPoItemsFiltered] = React.useState([])
    const SelectTab = (ix) => {
        setSelectedTab(ix);
        fnItemsFilter(ix);
    }

    const fnItemsFilter = (tab) => {

        if (!purchaseOrder || !purchaseOrder.po_items) {
            return null;
        }
        let _list = [];
        purchaseOrder.po_items.map(e => {
            if (tab !== 0) {
                if (tab === 1 && (
                    e.approvedDateTime !== null
                    ||
                    e.rejectedDateTime !== null
                    ||
                    e.itemIsQuoted === 0
                )) return null;


                if (tab === 2 && e.approvedDateTime === null) return null;
                if (tab === 3 && e.rejectedDateTime === null) return null;
            }

            if (
                poStep === 1
                &&
                !approvedItems[e.id]
            ) {
                return null
            }
            _list.push(e);
            return null;
        });
        setPoItemsFiltered(_list);
    }

    const SetApprovedItem = (id, select) => {
        let _approvedItems = {
            ...approvedItems
        };

        switch (select) {
            case 1:

                _approvedItems[id] = _approvedItems[id] === 1 ? 0 : 1;
                break;
            case -1:
                _approvedItems[id] = _approvedItems[id] === -1 ? 0 : -1;
                break;
            case 0:
                _approvedItems[id] = 0;
                break;
            default:
                break;
        }
        setApprovedItems(_approvedItems)
    }

    let fnPOGet = async () => {
        setFetching2(true);
        let res = await purchaseOrderAPI.fetch({
            t: "ref",
            ref: quoteCode
        });
        setFetching2(false);
        setPurchaseOrder(res);

    }

    const fnReloadPO = () => {
        fnPOGet();
        setPoStep(0);
        setSelectedTab(1);
    }

    /* eslint-disable */
    React.useEffect(() => {
        fnPOGet();
    }, []);
    React.useEffect(() => {
        fnItemsFilter(selectedTab)
    }, [poStep]);
    React.useEffect(() => {
        if (purchaseOrder) {
            //fnInitPoItems({ po_items: purchaseOrder.po_items });
            let items_count = {
                0: 0,
                1: 0,
                2: 0,
                3: 0
            }
            if (purchaseOrder.po_items) {
                purchaseOrder.po_items.map(e => {
                    items_count[0] += 1;
                    if (e.approvedDateTime == null && e.rejectedDateTime == null && e.itemIsQuoted === 1) items_count[1] += 1;
                    if (e.approvedDateTime !== null) items_count[2] += 1;
                    if (e.rejectedDateTime !== null) items_count[3] += 1;
                });
            }

            console.warn("TODO", "API returns purchaseOrder.items_count ! review this code")
            setItemsCount(items_count)

            fnItemsFilter(1)
        }
    }, [purchaseOrder]);

    /* eslint-enable */


    const fnPurchaseOrderStep0 = () => {
        setPoStep(0);
    }
    const fnPurchaseOrderStep1 = () => {
        let numberApproved = 0, numberRejected = 0;
        Object.keys(approvedItems).forEach(function (key) {
            if (approvedItems[key] === 1) {
                numberApproved++;
            }
            if (approvedItems[key] === -1) {
                numberRejected++;
            }
        });
        if (numberApproved === 0 && numberRejected === 0) {
            alert("Debe escoger al menos 1 item")
        } else {
            setPoStep(1);
        }
    }
    const fnPurchaseOrderStep2 = () => {
        setPoStep(2);
    }


    const fnQuoteSend = async () => {
        if (window.confirm(`Está seguro que desea enviar la cotización a ${purchaseOrder.po.userEmail}?`)) {
            setFetching2(true);
            await purchaseOrderAPI.quoteSend({
                rfqId: purchaseOrder.po.id,
            });
            setFetching2(false);
            alert(`La cotización ha sido enviada`)
        }

    }
    return (
        <div>

            <header
                style={{
                    backgroundColor: "#FF9400"
                }}
            >
                <div style={{
                    margin: "auto",
                    maxWidth: 1200,
                    minWidth: 350,
                    flexDirection: "row",
                    display: 'flex',
                    justifyContent: "space-between",
                    alignContent: "center",
                    alignItems: "center",

                }}>
                    <div style={{ width: 80 }} />
                    <img alt='ALANI 360 logo'
                        src='/images/alani_logos_vertical_horizontal_orange_white.svg#svgView(viewBox(320, 90, 260, 70))'
                        style={{
                            width: 120, height: 60
                        }}
                    />
                    <div style={{ width: 100 }} />
                </div>
            </header>

            <main style={{
                margin: "auto",
                maxWidth: 1200,
                minWidth: 350,
                fontFamily: 'Montserrat',
            }}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignContent: 'center',
                        flexDirection: 'column'
                    }}
                >


                    <h1>
                        {purchaseOrder
                            ?
                            `Cotización ${purchaseOrder.po?.customerReference || ""}`
                            :
                            ""
                        }
                    </h1>

                </div>

                {fetching2 &&
                    <Styles.ContentDiv>
                        <ThreeDots
                            type="ThreeDots"
                            color="#FF9400"
                            height={10}
                            width={150}
                            timeout={10000}
                        />
                    </Styles.ContentDiv>
                }




                {!fetching2 && purchaseOrder && purchaseOrder.po &&
                    <div style={{
                        padding: 10,
                        borderTopStyle: "solid",
                        borderTopWidth: 1,
                        borderTopColor: '#000',
                        marginBottom: 50
                    }}>

                        {display_message != null &&
                            <div style={{
                                textAlign: 'center',

                            }}>
                                <h1>{display_message}</h1>
                            </div>
                        }

                        <Styles.ContentDiv>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: 380
                            }}>




                                <div style={styles.row}>
                                    <div style={styles.col}>
                                        <span style={{
                                            fontWeight: 'bold',
                                            color: '#FF9400'
                                        }}>{purchaseOrder.po.customerName}</span>

                                        <span>{purchaseOrder.po.userName}</span>
                                    </div>

                                    <div style={styles.col}>
                                        <span style={{
                                            fontWeight: 'bold',
                                            color: '#FF9400'
                                        }}>{purchaseOrder.po.customerReference}</span>

                                        <span>{toTitleCase(moment(purchaseOrder.po.poCreateDate).tz('America/New_York').format('MMMM DD HH:mm'))}</span>
                                    </div>
                                </div>

                                <div style={styles.row}>
                                    <div style={styles.col}>
                                        <span>{purchaseOrder.po.userEmail}</span>
                                        <span>{purchaseOrder.po.userPhone}</span>
                                    </div>

                                    <div>
                                        <button
                                            onClick={() => fnQuoteSend()}
                                        >
                                            Enviar XLS
                                        </button>
                                    </div>
                                </div>

                                {poStep === 0
                                    &&
                                    <div
                                        style={{
                                            backgroundColor: '#ffffff'
                                        }}
                                    >
                                        <div
                                            style={{
                                                ...{
                                                    marginTop: 10,
                                                },
                                                ...styles.tabs
                                            }}
                                        >

                                            {TABS.map((t, ix) => {

                                                return <button
                                                    key={ix}
                                                    onClick={() => SelectTab(ix)}
                                                    style={selectedTab === ix ? {
                                                        ...styles.tab,
                                                        ...styles.tab__selected
                                                    } : styles.tab}
                                                >{t} {itemsCount[ix]}</button>

                                            })}

                                        </div>
                                        <div style={{
                                            textAlign: 'center',
                                            padding: 5,
                                            margin: 0,
                                        }}>
                                            {
                                                itemsCount[selectedTab] === 0 ?
                                                    <div>
                                                        <br /><br />
                                                        No hay items {TABS[selectedTab]}
                                                    </div>
                                                    :
                                                    `${itemsCount[selectedTab]} item(s)`
                                            }
                                        </div>
                                    </div>
                                }



                                {
                                    poStep === 2 ?

                                        <PurchaseOrderConfirm
                                            purchaseOrder={purchaseOrder}
                                            fnReloadPO={fnReloadPO}
                                            approvedItems={approvedItems}
                                            poItemsFiltered={poItemsFiltered}
                                        />

                                        :

                                        <div>

                                            {poItemsFiltered?.map((e, ix) => {


                                                return <div
                                                    key={ix}
                                                >

                                                    <div

                                                        style={
                                                            {
                                                                ...styles.row,
                                                                borderColor: '#c0c0c0',
                                                                borderWidth: 1,
                                                                borderStyle: 'solid',
                                                                marginBottom: 0,
                                                                borderBottomStyle: e.api_part_name != null ? 'none' : 'solid',
                                                                backgroundColor: (approvedItems[e.id] === 1 || e.approvedDateTime != null) ?
                                                                    "#00800050" :
                                                                    (approvedItems[e.id] === -1 || e.rejectedDateTime != null) ?
                                                                        "#FF000050"
                                                                        :
                                                                        "#e0e0e0",

                                                                marginTop: poItemsFiltered[ix - 1]?.ref5 === e.ref5 ? 0 : 20,
                                                                borderTopStyle: poItemsFiltered[ix - 1]?.ref5 === e.ref5 ? 'none' : 'solid',
                                                            }
                                                        }
                                                    >
                                                        <div
                                                            style={styles.col}
                                                        >
                                                            <span>{e.c_name} {e.destinationCity}</span>

                                                            <span style={{ color: '#F29766' }}>{e.ref5}</span>
                                                            <span>{e.ref1}</span>
                                                            <span>{e.ref2}</span>
                                                            <span>{e.ref3}</span>
                                                            <span>{e.ref4}</span>
                                                        </div>


                                                        {e.itemIsQuoted === 0 ?
                                                            <div
                                                                style={{
                                                                    width: 60
                                                                }}
                                                            >
                                                                Por Cotizar
                                                            </div>
                                                            :
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    alignItems: 'center'
                                                                }}
                                                            >
                                                                <span style={{ fontWeight: 'bold', color: "#000" }}>{formatter.format(e.quotedTotal)}</span>

                                                                <span>{e.etaDays} dias</span>

                                                                {
                                                                    (
                                                                        e.approvedDateTime != null ||
                                                                        e.rejectedDateTime != null
                                                                    )
                                                                        ?
                                                                        <div>

                                                                            {toTitleCase(moment(e.approvedDateTime || e.rejectedDateTime).tz('America/New_York').format('MMM DD HH:mm'))}

                                                                        </div>
                                                                        :

                                                                        <div>
                                                                            {poStep === 0 ?
                                                                                <div>
                                                                                    <Styles.ButtonMain
                                                                                        style={{
                                                                                            width: 50,
                                                                                            backgroundColor: approvedItems[e.id] === 1 ? "green" : '#fff'
                                                                                        }}

                                                                                        onClick={() => SetApprovedItem(e.id, 1)}
                                                                                    >
                                                                                        <span style={{ color: approvedItems[e.id] === 1 ? "#fff" : 'green' }}>Si</span>
                                                                                    </Styles.ButtonMain>

                                                                                    <Styles.ButtonMain
                                                                                        style={{
                                                                                            width: 50,
                                                                                            marginTop: 5, marginBottom: 5,
                                                                                            backgroundColor: approvedItems[e.id] === -1 ? "red" : '#fff'
                                                                                        }}

                                                                                        onClick={() => SetApprovedItem(e.id, -1)}
                                                                                    >
                                                                                        <span style={{ color: approvedItems[e.id] === -1 ? "#fff" : 'red' }}>No</span>
                                                                                    </Styles.ButtonMain>
                                                                                </div>
                                                                                :
                                                                                <div></div>
                                                                            }

                                                                        </div>
                                                                }




                                                            </div>



                                                        }


                                                    </div>

                                                    {e.api_part_name != null &&

                                                        <div
                                                            style={{
                                                                ...styles.col,

                                                                backgroundColor: (approvedItems[e.id] === 1 || e.approvedDateTime != null) ?
                                                                    "#00800050" :
                                                                    (approvedItems[e.id] === -1 || e.rejectedDateTime != null) ?
                                                                        "#FF000050"
                                                                        :
                                                                        "#e0e0e0",
                                                                borderColor: '#c0c0c0',
                                                                borderWidth: 1,
                                                                borderStyle: 'solid',
                                                                marginBottom: 0,
                                                                borderTopStyle: 'none',

                                                                padding: 10,
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            <span>{e.api_part_name}</span>
                                                            <span>{e.api_part_description}</span>
                                                            {(e.api_img_url || "") !== "" &&
                                                                <img alt={e.api_part_name} src={"https://" + e.api_img_url} width="200" />
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            })}
                                        </div>



                                }



                            </div>



                        </Styles.ContentDiv>



                    </div>
                }


            </main>


            <footer style={styles.footer} >
                {
                    {
                        0: <Styles.ButtonMain
                            onClick={() => fnPurchaseOrderStep1()}
                        >
                            <span>Continuar &gt;</span>
                        </Styles.ButtonMain>,
                        1: <div
                            style={{
                                flexDirection: 'row',
                                display: 'flex'
                            }}
                        >
                            <Styles.ButtonCancel
                                onClick={() => fnPurchaseOrderStep0()}
                            >
                                <span>&lt; Regresar</span>
                            </Styles.ButtonCancel>

                            <Styles.ButtonMain
                                onClick={() => fnPurchaseOrderStep2()}
                            >
                                <span>Confirmar &gt;</span>
                            </Styles.ButtonMain>
                        </div>,
                        2: <div
                            style={{
                                flexDirection: 'row',
                                display: 'flex'
                            }}
                        >
                            <Styles.ButtonCancel
                                onClick={() => fnPurchaseOrderStep1()}
                            >
                                <span>&lt; Regresar</span>
                            </Styles.ButtonCancel>

                        </div>
                    }[poStep]
                }

            </footer>
        </div>
    );
}


export default POQuote;


const styles = {

    tabs: {
        display: 'flex',
        flexDirection: 'row',

    },
    tab: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: '#c0c0c0',
        padding: 5,
        textAlign: 'center',
        borderTopRightRadius: 5,
        borderTopLeftRadius: 5,
        borderWidth: 1,
        borderColor: '#808080',
        borderStyle: 'solid',
        marginRight: 3,
        fontSize: 14

    },
    tab__selected: {
        borderBottomColor: "#fff",

        backgroundColor: '#ffffff'
    },


    footer: {
        position: "fixed",
        bottom: 0,
        backgroundColor: "#3482B3",
        right: 0,
        left: 0,
        height: 65,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center'
    },
    col: {
        display: 'flex',
        flexDirection: 'column',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        minWidth: 330,
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 5,
        marginBottom: 5,
        padding: 5
    }
}