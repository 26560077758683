import i18n from "i18next";
import { initReactI18next, } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    es: {
        translation: {
            "HomePage1": "<strong>Alani 360</strong> es la mejor manera de comprar por internet en USA, con más de 200 tiendas online con súper ofertas, una cuenta digital en estados unidos, envío seguro y con el mejor precio.",
            "HomePage2": "Cuando te registras en <strong>Alani 360</strong>, te asocias a una membresía que te ofrece todo lo que necesitas para comprar en las tiendas online de Estados Unidos y recibir todos tus pedidos directamente a tu puerta.",
            "Abundancia": "La abundancia del mundo en tus manos",
            "Membresía": "MEMBRESÍA",
            "Registro": "Registro",
            "Mi Cuenta": "Mi Cuenta",
            "Ayuda": "Ayuda",
            "Compras": "Compras",
            "Estás seguro que quieres cerrar la sesión?": "Estás seguro que quieres cerrar la sesión?",
            "Código de Acceso o PIN": "Código de acesso o PIN",
            "Entrar": "Entrar",
            "Si no estás registrado": "Si no estás registrado",
            "crea tu cuenta en Alani360": "crea tu cuenta en Alani360",

            "NoCodePin": "Si no tienes PIN o código de acceso, te enviamos uno a tu celular",
            "SolicitarCodigo": "Solicitar Código de Acceso",

            "EnviaPrealerta": "Envía tu factura para alertarnos sobre nuevas compras",

            "HelpAlani1": `
Con una variada procedencia, hemos generado un gran círculo de familiares y amigos en todo el mundo. Cuando se trata de productos y servicios que están disponibles fuera de Estados Unidos,
el mensaje común que escuchamos en todas partes es: “No tenemos mucha selección.” O… “Desearíamos poder encontrar una mejor calidad.” O… “Todo es más caro aquí.”
<br /><br />
Las preguntas que siguen son inevitables:
<br/><br/>
“¿Puedo comprar a través de ustedes en los EE.UU.y guardar mis compras en tu lugar hasta que viaje y las recoja ?”
<br/><br/>
"Si por casualidad estas planeando un viaje para visitarnos , ¿Puedes traernos nuestras compras ?”
<br/><br/>
A menudo se nos pide que nosotros efectuemos las compras, para que luego nos reintegren el dinero cuando nos podamos encontrar. ¿Por qué ? Porque la gran mayoria de nuestros familiares y amigos no tienen un medio de pago internacional; o piensan que lo tienen, y se frustran al no poder concretar ninguna compra por diversos motivos.
<br/><br/>
También hemos descubierto que, al hacer arreglos de viajes desde los EE.UU., los costos a menudo son significativamente menores que los mismos arreglos reservados desde el exterior.Ademas, muchos de los servicios de entretenimiento y plataformas de transmisión que damos por sentado en los EE.UU., simplemente no están disponibles en otros paises.
<br/><br/>
“Alani” significa naranja y simboliza ABUNDANCIA.
<br/><br/>
Nuestra pregunta:
¿Cómo podemos ofrecer varidad, calidad y los mejores precios de Estados Unidos al alcance de todos ?
<br/><br/>
    Nuestra respuesta:
Ofrecer un acceso ilimitado de 360 grados con todas las herramientas y servicios necesarios.Poniendo a disposición de todos el comercio electrónico de Estados Unidos, de forma ilimitada con soluciones de pago internacionales y envío seguro puerta a puerta, facilitando la navegación online con guías y herramientas inteligentes.Todo ha sido diseñado en este mismo sitio para tu seguridad y conveniencia.
<br/><br/>
Ya no tienes que esperar para hacer un viaje a Estados Unidos., o pedirle a alguien que te compre lo que deseas.La abundancia de Estados Unidos ya esta al alcance de tu mano.
<br/><br/>
Esperamos que disfrutes la experiencia de ALANI 360.`,
            "MembresiaDetails": `
Membresía ALANI WHITE
<br/><br/>
Esta membresía está diseñada para clientes que compran solo una vez al año. No tiene ningún costo de membresía anual y tienes acceso a nuestras tarifas de envío con descuento y entrega rápida. También puedes acceder a nuestra Cuenta Digital (Alani Digital Account) por solo $7.99 por dos (2) años.
<br/><br/>
Membresía ALANI ORANGE
<br/><br/>
Esta membresía Premium es ideal para compradores que hacen dos o más compras al año. Además de las tarifas de envío con descuento y entrega rápida, con esta membresía, recibirás gratis nuestra cuenta digital ALANI Digital Account y 30 días de almacenamiento de paquetes gratis.
            `,
        }
    },
    br: {
        translation: {
            "HomePage1": "Alani 360 é a melhor forma de fazer compras online nos EUA, com mais de 200 lojas online com super ofertas, conta digital nos Estados Unidos, frete seguro e o melhor preço.",
            "HomePage2": "Ao se inscrever no <strong>Alani 360</strong>, você está associado a uma assinatura que oferece tudo o que você precisa para fazer compras em lojas on-line nos Estados Unidos e receber todos os seus pedidos diretamente na sua porta.",
            "Abundancia": "A abundância do mundo em suas mãos",
            "Membresía": "FILIAÇÃO",

            "Registro": "Registro",
            "Mi Cuenta": "Minha Conta",

            "Ayuda": "Ajuda",
            "Compras": "Compras",
            "Estás seguro que quieres cerrar la sesión?": "Tem certeza que deseja sair?",
            "Código de Acceso o PIN": "Código de acesso ou PIN",
            "Entrar": "Conecte-se",

            "Si no estás registrado": "Se você não está registrado",
            "crea tu cuenta en Alani360": "crie sua conta no Alani360",
            "SolicitarCodigo": "Solicitar Código de Acesso",

            "EnviaPrealerta": "Envie sua fatura para nos alertar sobre novas compras",


            "NoCodePin": "Se você não tiver um PIN ou código de acesso, enviaremos um para seu celular",

            "HelpAlani1": `
Com uma formação variada, geramos um grande círculo de familiares e amigos em todo o mundo. Quando se trata de produtos e serviços disponíveis fora dos Estados Unidos,
a mensagem comum que ouvimos em todos os lugares é: "Não temos muitas opções". Ou… “Gostaríamos de poder encontrar melhor qualidade.” Ou… “Tudo é mais caro aqui.”
<br /><br />
As perguntas a seguir são inevitáveis:
<br/><br/>
“Posso comprar com você nos EUA e guardar minhas compras em sua casa até viajar e buscá-las?”
<br/><br/>
"Se por acaso você está planejando uma viagem para nos visitar, você pode nos trazer nossas compras?"
<br/><br/>
Muitas vezes somos solicitados a fazer as compras por conta própria, apenas para ser reembolsado mais tarde, quando pudermos nos encontrar. Porque ? Porque a grande maioria dos nossos familiares e amigos não tem meios de pagamento internacionais; ou eles acham que têm e ficam frustrados por não conseguirem fazer uma compra por vários motivos.
<br/><br/>
Também descobrimos que, ao fazer planos de viagem dos EUA, os custos geralmente são significativamente menores do que os mesmos arranjos reservados no exterior. Além disso, muitos dos serviços de entretenimento e plataformas de streaming que consideramos garantidos nos EUA são simplesmente Indisponível em outros países.
<br/><br/>
"Alani" significa laranja e simboliza ABUNDÂNCIA.
<br/><br/>
Nossa pergunta:
Como podemos oferecer variedade, qualidade e os melhores preços dos Estados Unidos ao alcance de todos?
<br/><br/>
    Nossa resposta:
Ofereça acesso ilimitado em 360 graus com todas as ferramentas e serviços necessários. Disponibilizando o e-commerce nos Estados Unidos para todos, ilimitado com soluções de pagamentos internacionais e envio seguro porta a porta, facilitando a navegação online com guias e ferramentas inteligentes.Tudo neste site foi projetado para sua segurança e conveniência.
<br/><br/>
Você não precisa mais esperar para fazer uma viagem aos Estados Unidos, ou pedir para alguém comprar o que você deseja, a abundância dos Estados Unidos já está ao seu alcance.
<br/><br/>
Esperamos que você aproveite a experiência do ALANI 360.
            `,
            "MembresiaDetails": `
<strong>Filiação ALANI WHITE</strong>
<br/><br/>
Esta associação é projetada para clientes que compram apenas uma vez por ano. Não há custo de associação anual e você tem acesso às nossas taxas de envio com desconto e entrega rápida. Você também pode acessar nossa Conta Digital (Alani Digital Account) por apenas $ 7,99 por dois (2) anos.
<br/><br/>
<strong>Filiação ALANI ORANGE</strong>
<br/><br/>
Esta assinatura Premium é ideal para compradores que fazem duas ou mais compras por ano. Além de taxas de envio com desconto e entrega rápida, com esta associação, você receberá nossa Conta Digital ALANI gratuita e 30 dias de armazenamento gratuito de pacotes.
            `
        }
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: process.env.REACT_APP_API_LANG, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;