import React from 'react'
import {   ThreeDots } from "react-loader-spinner";
import Styles from "../styles";
import b2cAPI from "../api/b2c";

import { useDispatch, useSelector } from 'react-redux';

import {
    useParams,
    useNavigate
} from "react-router-dom";
import moment from 'moment-timezone';
// eslint-disable-next-line
import localization from 'moment/locale/es'

import B2CPartQuoteComponent from "../app/components/B2CPartQuoteComponent";
 
function toTitleCase(str) {
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}

moment.locale('es');
const PartsHistory = ({
    quote
}) => {

    if (quote == null) return null;

    return <div
        style={{
            flexDirection: "column",
            display: 'flex',
            alignItems: 'flex-start',
        }}
    >

        <hr
            style={{
                marginTop: 10,
                marginBottom: 10,
                width: '100%'
            }}
        />
        {quote?.items_history.map((item, ix) => {

            return <div key={ix} style={styles_local.small}>
                <a href={`${item.code}`}>{item.partName}</a>
                <br/> 
                <span>{`${toTitleCase(moment(item.searchDT).tz('America/New_York').format('MMM DD HH:mm'))}`}</span>

            </div>
        })}
    </div>
}

const PartQuote = () => { 

    const code = useParams().code;
    const navigate = useNavigate();
    const dataReducerB2C = useSelector((state) => state.dataReducerB2C); 
    const {
        buyNow
    } = dataReducerB2C; 
    const item = buyNow?.item;
    const fnBuyNow = () => {
        navigate(`/buyNow/${code}` );
    } 
    if (item == null) {
        return <div></div>
    }

    return <div
        style={{
            flexDirection: "column",
            display: 'flex',
            alignItems: 'center',
        }}
    >  
        
        <B2CPartQuoteComponent 
            item={item}
        />


        <Styles.ButtonMain

            onClick={() => fnBuyNow()}
            style={{
                width: 180,
                margin: 20
            }}

        >
            <span style={{color: '#000'}}>Compra Ahora</span>
            </Styles.ButtonMain>
    </div>
}

const B2CPartWhatsApp = () => {

    const navigate = useNavigate();
    const code = useParams().code;

    const dispatch = useDispatch(); 
    const [ref2, setRef2] = React.useState("");
    const [fetching2, setFetching2] = React.useState(true);
    const [fetching3, setFetching3] = React.useState(true);
    const [phoneNumber, setPhoneNumber] = React.useState();
    const [whatsAppQuote, setWhatsAppQuote] = React.useState();


    const fnPartLookup = async ({
        partno
    }) => {

        if (partno.trim() === "") {
            return alert("Debe introducir un n'umero de parte")
        }

        setFetching2(true);
        let res = await b2cAPI.partPriceGet({
            phoneNumber,
            partNumber: ref2
        });
        setFetching2(false);
        if (res.success) {
            navigate(`/part/${res.code}`);
            navigate(0);
        } else {
            alert("Part not found")
        }


    }

    const fnGetPartQuote = async ({
        code
    }) => {
        setFetching3(true);
        let res = await b2cAPI.partQuoteGet({
            code
        });
        setFetching3(false);
        setFetching2(false);


        if (res.success) { 
            dispatch({
                type : "BUY_NOW",
                buyNow: res
            });
            setWhatsAppQuote(res);
            setPhoneNumber(res.item.phoneNumber)
        } else {
            setPhoneNumber(null)
        }



    }


    /* eslint-disable */
    React.useEffect(() => {
        fnGetPartQuote({ code })
    }, []);
    /* eslint-enable */


    return (
        <div>

            <header
                style={{
                    backgroundColor: "#FF9400"
                }}
            >
                <div style={{
                    margin: "auto",
                    maxWidth: 1200,
                    minWidth: 350,
                    padding: 5,
                    flexDirection: "row",
                    display: 'flex',
                    justifyContent: "space-between",
                    alignContent: "center",
                    alignItems: "center",

                }}>
                    <div style={{ width: 80 }} />
                    <img alt='ALANI 360 logo'
                        src='/images/alani_logos_vertical_horizontal_orange_white.svg#svgView(viewBox(320, 90, 260, 70))'
                        style={{
                            width: 120, height: 60
                        }}
                    />
                    <div style={{ width: 100 }} />
                </div>
            </header>
            <Styles.PageTitle
                        style={{
                            height: 40
                        }}
            >
                ALANI GARAGE
            
                {whatsAppQuote != null && whatsAppQuote.account != null &&
                    <div style={{
                        textAlign: 'center',
                        color: '#FF9400',
                        fontWeight: 'bold'
                    }}>
                        {whatsAppQuote.account.firstName} {whatsAppQuote.account.lastName}
                    </div>
                }
            </Styles.PageTitle>


            {fetching3 ?
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: 20
                    }}
                >
                    <ThreeDots 
                            color="#FF9400"
                            height={20}
                            width={80}
                            timeout={5000}
                        />
                </div>
                :
                <main style={{
                    margin: "auto",
                    maxWidth: 400,
                    minWidth: 350,
                    fontFamily: 'Montserrat',
                    padding: 5,
                }}>

                   



                    {whatsAppQuote !== null &&

                        <PartQuote
                             
                        />

                    }
                    <hr style={{
                            marginTop: 10,
                            marginBottom: 10,
                            width: '100%'
                        }}
                    />

                    {
                        phoneNumber !== null
                            ?
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column'
                            }}>


                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'flex-end',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <label  >
                                        Parte
                                    <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <input
                                                type="text"
                                                value={ref2}
                                                style={{
                                                    width: 180,
                                                    marginBottom: 5,
                                                    marginRight: 5
                                                }}
                                                onChange={e => setRef2(e.target.value)}

                                            />

                                            <Styles.ButtonSecondary
                                                style={{
                                                    width: 100,
                                                    marginBottom: 5
                                                }}
                                                onClick={() => fnPartLookup({
                                                    partno: ref2
                                                })}
                                            >
                                                {fetching2 ?
                                                    <ThreeDots
                                                        type={"ThreeDots"}
                                                        color="#FFFFFF"
                                                        height={10}
                                                        width={30}
                                                        timeout={5000}
                                                    />
                                                    :

                                                    "Buscar"

                                                }
                                            </Styles.ButtonSecondary>
                                        </div>
                                    </label>
                                </div>


                            </div>


                            :
                            <span></span>
                    }


                    {whatsAppQuote !== null &&

                        <PartsHistory
                            quote={whatsAppQuote}
                        />

                    }


                </main>
            }
        </div>
    );
}


export default B2CPartWhatsApp;

const styles_local = {
    
    small: {
        fontSize: 14,
        marginTop: 10, marginBottom: 5,
        textAlign: 'left', 
    }
}