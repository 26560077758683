import React from 'react'
import {
    useParams, 
    useLocation,
} from "react-router-dom";
 
import { useCookies } from 'react-cookie';
 

import Styles from "../styles"; 
 

import registrationAPI from "../api/registrationAPI";

const queryString = require('query-string'); 

const EmailConfirm = () => {
     
    // eslint-disable-next-line
    const [cookies, setCookie] = useCookies(['alani360']);
    const { search } = useLocation();
    let token = useParams().token;
    let accountId = useParams().accountId;
    let email = (queryString.parse(search)).e;

    
   
    const [fetching, setFetching] = React.useState(false); 
    const [ validationToken , setValidationToken ] = React.useState(null);
    const [ message , setMessage ] = React.useState("?");

     

    /* eslint-disable */
    
    React.useEffect(() => {
        setFetching(true);
        registrationAPI.emailConfirm({
            accountId,
            email,
            token
        }).then( res => { 
            setFetching(false);
            if (res.success){
                setCookie('alani360', {
                    token,
                    phoneNumber: res.phone,

                }, { path: '/' });
                setValidationToken(res.urlToken);
            }else{
                setMessage(res.msg)
            }
        })


    }, [token]);
 
    /* eslint-enable */


 
    return (
        <div> 
            <header
                style={{
                    backgroundColor: "#FF9400"
                }}
            >
                <div style={{
                    margin: "auto",
                    maxWidth: 1200,
                    minWidth: 350,
                    flexDirection: "row",
                    display: 'flex',
                    justifyContent: "space-between",
                    alignContent: "center",
                    alignItems: "center",

                }}>
                    <div style={{ width: 80 }} />
                    <img alt='ALANI 360 logo'
                        src='/images/alani_logos_vertical_horizontal_orange_white.svg#svgView(viewBox(320, 90, 260, 70))'
                        style={{
                            width: 120, height: 60
                        }}
                    />
                    <div style={{ width: 100 }} />
                </div>
            </header>

 
 

                <main style={{
                    fontFamily: 'Montserrat',
                }}>
                    <Styles.ContentDiv>


                        <img
                            src="/images/alani_logos_vertical_horizontal.svg#svgView(viewBox(0, 240, 230, 200))"
                            alt="ALANI 360 Logo"
                            style={{
                                width: "auto",
                                height: 80
                            }}
                        />

                    

                        {fetching ? 
                             "Confirmando..."
                            :
                            <div>
                                {!!validationToken  ?

                                    <div style={{textAlign : 'center'}}>
                                        <br/>
                                        Tu cuenta de correo ha sido confirmada
                                        <br/>
                                        <br/>
                                       <a href={`/p/${validationToken}`}>Continuar</a>
                                    </div>

                                    :
                                    <div>
                                        {message}
                                    </div>
                                }
                            </div>
                        }

                    </Styles.ContentDiv>

                </main> 
        </div>
    );
}


export default EmailConfirm;