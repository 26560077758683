const purchaseOrderAPI = {

    login: (args) => {

        return new Promise((resolve, reject) => {

            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/admin/login/`;

            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            fetch(url, {
                headers,
                method: 'POST',
                body: JSON.stringify(args)
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },


}

export default purchaseOrderAPI