import React from 'react';
import { useNavigate } from "react-router-dom";
import { slide as MenuBurger } from 'react-burger-menu';



const Menu = () => {

    // eslint-disable-next-line

    const [isOpen, setOpen] = React.useState(false)
    const handleIsOpen = () => {
        setOpen(!isOpen)
    }
    const navigate = useNavigate();


    const navigateTo = (pg) => {

        setOpen(false);
        setTimeout(() => {
            navigate(pg);
        }, 200)


    }

    return <MenuBurger
        styles={styles}
        isOpen={isOpen}
        onOpen={handleIsOpen}
        onClose={handleIsOpen}
    >
        <a href="# " style={{ height: 0, visibility: 'hidden' }}>.</a>
        <img alt='ALANI 360 logo'
            src='/images/alani_logos_vertical_horizontal_orange_white.svg#svgView(viewBox(320, 90, 260, 70))'
            style={{
                width: 150, height: 75,
                marginRight: 50,
                borderStyle: "none",
                alignSelf: 'center'
            }}
        />
        <span style={{ textDecoration: 'none' }}>{localStorage.getItem("userName")}</span>
        <a href="# " id="a1" className="menu-item" onClick={() => navigateTo(`/admin/`)} >Main</a>
        <a href="# " id="a3" className="menu-item" onClick={() => navigateTo(`/admin/users`)} >Businesses</a>
        <a href="# " id="a8" className="menu-item" onClick={() => navigateTo(`/admin/apiStats`)} >API</a>

        <a href="# " id="a3" className="menu-item" onClick={() => navigateTo(`/admin/parts`)} >Search</a>
        <a href="# " id="a2" className="menu-item" onClick={() => navigateTo(`/admin/rfqs`)} >RFQs</a>
        <a href="# " id="a2" className="menu-item" onClick={() => navigateTo(`/admin/pos`)} >Purchase Orders</a>
        <a href="# " id="a2" className="menu-item" onClick={() => navigateTo(`/admin/emails`)} >Emails</a>
        <a href="# " id="a4" className="menu-item" style={{ color: '#fff' }} onClick={() => {
            localStorage.removeItem('token');
            navigateTo(`/login/`);
        }} >Logout</a>
    </MenuBurger>
}

export default Menu;

var styles = {

    bmBurgerButton: {
        position: 'absolute',
        width: '30px',
        height: '20px',
        left: '10px',
        top: '20px'
    },
    bmBurgerBars: {
        background: '#373a47'
    },
    bmBurgerBarsHover: {
        background: '#a90000',
    },
    bmCrossButton: {
        height: '24px',
        width: '24px'
    },
    bmCross: {
        background: '#bdc3c7'
    },
    bmMenuWrap: {
        position: 'fixed',
        height: '100%',
    },
    bmMenu: {
        background: '#58825B',
        padding: '0 1.5em 0',
        fontSize: '1.15em'
    },
    bmMorphShape: {
        fill: '#373a47'
    },
    bmItemList: {
        color: '#b8b7ad',
        padding: '0.8em',
        display: 'flex',
        flexDirection: 'column'
    },
    bmItem: {
        display: 'flex',
        alignItems: 'center',
        color: '#fff',
        marginBottom: 5,
        marginTop: 5,
        paddingTop: 5,
        paddingBottom: 5,
        textDecoration: 'underline',
    },
    bmOverlay: {
        background: 'rgba(0, 0, 0, 0.3)',
    }
}