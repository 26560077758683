const token = localStorage.getItem("token");


const emailAPI = {

    emailGetById: (args) => {


        return new Promise((resolve, reject) => {
            let {
                emailId,
            } = args;
            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/admin/emails/${emailId}`;


            var headers = new Headers();
            headers.append("Authorization", token);
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            fetch(url, {
                headers,
                method: 'GET',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    reject(err);
                });
        })
    },


    deleteEmail: (args) => {
        //
        return new Promise((resolve, reject) => {
            let {
                emailId,
            } = args;

            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/admin/emails/${emailId}`;

            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            headers.append("Authorization", token);
            fetch(url, {
                headers,
                method: 'DELETE'
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },

    sendRFQ: (args) => {
        //
        return new Promise((resolve, reject) => {
            let {
                rfqId,
            } = args;

            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/admin/emails/rfq/${rfqId}`;

            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            headers.append("Authorization", token);

            fetch(url, {
                headers,
                method: 'POST',
                body: JSON.stringify(args)
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },


    fetch: (args) => {


        return new Promise((resolve, reject) => {

            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/admin/emails`;


            var headers = new Headers();
            //const token = Cache.getItem('Merx:authtoken');
            headers.append("Authorization", token);
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            fetch(url, {
                headers,
                method: 'GET',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    reject(err);
                });
        })
    },


}

export default emailAPI