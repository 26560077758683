const token = localStorage.getItem("token");


const purchaseOrderAPI = {

    download: (args) => {


        return new Promise((resolve, reject) => {

            let {
                rfqId
            } = args;

            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/admin/pos/${rfqId}/xls`;

            var headers = new Headers();
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            headers.append("Authorization", token);
            fetch(url, {
                headers,
                method: 'GET',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    console.warn(err)
                    reject(err);
                });
        })
    },


    quoteSend: (args) => {

        // arn:aws:lambda:us-east-1:645160913840:function:Alani-QuoteSend

        const {
            rfqId,
            token2
        } = args;
        return new Promise((resolve, reject) => {


            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/admin/pos/${rfqId}/send`;

            var headers = new Headers();
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            headers.append("Authorization", token2 || token);
            fetch(url, {
                headers,
                method: 'POST',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    console.warn(err)
                    reject(err);
                });
        })
    },

    //-ADMIN--------------------------------------


    cposAutoCreate: (args) => {

        // cloud9-Pricing-CreatePOAuto-5ST0gp6kcDFg

        const {
            rfqId
        } = args;
        return new Promise((resolve, reject) => {


            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/admin/pos/${rfqId}/cpos`;

            var headers = new Headers();
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            headers.append("Authorization", token);
            fetch(url, {
                headers,
                method: 'POST',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    console.warn(err)
                    reject(err);
                });
        })
    },


    rfqDelete: (args) => {

        const {
            rfqId
        } = args;
        return new Promise((resolve, reject) => {


            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/admin/pos/${rfqId}/`;

            var headers = new Headers();
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            headers.append("Authorization", token);
            fetch(url, {
                headers,
                method: 'DELETE',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    console.warn(err)
                    reject(err);
                });
        })
    },

    rfqReset: (args) => {

        const {
            rfqId,
            clearPricing
        } = args;
        return new Promise((resolve, reject) => {


            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/admin/pos/${rfqId}/reset?clearPricing=${parseInt(clearPricing)}`;

            var headers = new Headers();
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            headers.append("Authorization", token);
            fetch(url, {
                headers,
                method: 'POST',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    console.warn(err)
                    reject(err);
                });
        })
    },

    autoPriceStatusGet: () => {


        return new Promise((resolve, reject) => {


            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/admin/pricing/auto`;

            var headers = new Headers();
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            headers.append("Authorization", token);
            fetch(url, {
                headers,
                method: 'GET',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    console.warn(err)
                    reject(err);
                });
        })
    },

    autoPriceStart: () => {


        return new Promise((resolve, reject) => {


            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/admin/pricing/auto`;

            var headers = new Headers();
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            headers.append("Authorization", token);
            fetch(url, {
                headers,
                method: 'POST',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    console.warn(err)
                    reject(err);
                });
        })
    },




    poGet: (args) => {

        /// AlaniPO/POGetById/index.js
        // CALL purchaseOrderGet

        return new Promise((resolve, reject) => {

            let {
                purchaseOrderId
            } = args;

            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/admin/pos/${purchaseOrderId}`;


            var headers = new Headers();
            headers.append("Authorization", token);
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);


            fetch(url, {
                headers,
                method: 'GET',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    console.warn(err)
                    reject(err);
                });
        })
    },

    poListGet: (args) => {

        /// AlaniPO/POList/index.js
        //  CALL purchaseOrdersGetList ()

        return new Promise((resolve, reject) => {

            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/admin/pos?closed=0`;

            var headers = new Headers();
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            headers.append("Authorization", token);
            fetch(url, {
                headers,
                method: 'GET',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    reject(err);
                });
        })
    },


    partSave: (args) => {


        // 

        return new Promise((resolve, reject) => {
            let {
                rfqId,
                itemId,
            } = args;

            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/admin/pos/${rfqId}/${itemId}`;

            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            headers.append("Authorization", token);

            fetch(url, {
                headers,
                method: 'POST',
                body: JSON.stringify(args)
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },

    //-ADMIN--------------------------------------



    itemsApproveReject: (args) => {


        //cloud9-AlaniPO-PoConfirm-2wnmKtwke0Xq

        return new Promise((resolve, reject) => {

            let {
                poId,
            } = args;

            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/po/${poId}/items/`;

            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            fetch(url, {
                headers,
                method: 'POST',
                body: JSON.stringify(args)
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },

    fetchPartNumber: (args) => {
        return new Promise((resolve, reject) => {
            let { ref, itemId } = args;


            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/po/${ref}/${itemId}`;

            var headers = new Headers();
            //const token = Cache.getItem('Merx:authtoken');
            //headers.append("Authorization", token);
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            fetch(url, {
                headers,
                method: 'GET',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    reject(err);
                });
        })
    },

    fetch: (args) => {

        ///AlaniPO/poGet/index.js
        //CALL purchaseOrderGet( ? )

        return new Promise((resolve, reject) => {
            let { ref, t } = args;


            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/po/${ref}?t=${t}`;


            var headers = new Headers();
            //const token = Cache.getItem('Merx:authtoken');
            headers.append("Authorization", token);
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            fetch(url, {
                headers,
                method: 'GET',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    reject(err);
                });
        })
    },


}

export default purchaseOrderAPI