let dataState = {

}


const dataReducerSignUp = (state = dataState, action) => {
    switch (action.type) {

        case "PHONE_LOOKUP":
            return {
                ...state,
                accion_terminada: null
            }
        default:
            return state;
    }

}





export default dataReducerSignUp;