const registrationAPI = {


    clientLogin: (args) => {

        return new Promise((resolve, reject) => {
            //arn:aws:lambda:us-east-1:645160913840:function:ALANI_CLIENT_LOGIN

            let {
                phone,
                tokenOrPin
            } = args;

            let url = ` https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/account/b2c/login/${phone}`;



            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );

            fetch(url, {
                headers,
                method: 'POST',
                body: JSON.stringify({
                    tokenOrPin
                })
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },

    accountActivate: (args) => {

        //arn:aws:lambda:us-east-1:645160913840:function:ALANI_B2C_ACTIVATE_ACCOUNT

        return new Promise((resolve, reject) => {

            let {
                token,
            } = args;

            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/account/b2c/activate/${token}`;

            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );

            fetch(url, {
                headers,
                method: 'POST',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },
    accountInfoUpdateCancel: (args) => {
        return new Promise((resolve, reject) => {

            let {
                accountId,
            } = args;

            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/account/${accountId}/info/`;

            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );

            fetch(url, {
                headers,
                method: 'DELETE',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },

    accountInfoConfirmationsResend: (args) => {
        return new Promise((resolve, reject) => {

            let {
                accountId
            } = args;

            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/account/${accountId}/info/confirmations_resend`;

            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );

            fetch(url, {
                headers,
                method: 'POST'
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },
    accountInfoCodeValidate: (args) => {
        return new Promise((resolve, reject) => {

            let {
                accountId,
                codeType,
                code,
                pin
            } = args;

            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/account/${accountId}/info/auth_code?codeType=${codeType}&code=${code}` + (pin ? `&pin=${pin}` : "");

            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );

            fetch(url, {
                headers,
                method: 'POST'
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },

    accountInfoCodeGet: (args) => {
        return new Promise((resolve, reject) => {

            let {
                accountId,
                codeType,
            } = args;


            if (codeType === "pin") {
                codeType = "PIN";
            }

            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/account/${accountId}/info/auth_code?codeType=${codeType}`;


            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );

            fetch(url, {
                headers,
                method: 'GET',
                //body: JSON.stringify(args)
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },

    accountInfoConfirmChange: (args) => {
        return new Promise((resolve, reject) => {


            let {
                accountId,
                auth_code,
            } = args;

            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/account/${accountId}/info_confirm_change/${auth_code}`;


            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );

            fetch(url, {
                headers,
                method: 'POST',
                //body: JSON.stringify(args)
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },


    accountInfoUpdate: (args) => {
        return new Promise((resolve, reject) => {

            let {
                accountId,
            } = args;

            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/account/${accountId}/info/`;

            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );

            fetch(url, {
                headers,
                method: 'POST',
                body: JSON.stringify(args)
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },

    emailConfirm: (args) => {
        //arn:aws:lambda:us-east-1:645160913840:function:ALANI_EMAIL_CONFIRM_BY_USER

        return new Promise((resolve, reject) => {

            let {
                accountId,
                email,
                token
            } = args;

            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/account/${accountId}/email/confirm`;

            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );

            fetch(url, {
                headers,
                method: 'POST',
                body: JSON.stringify({
                    email,
                    token
                })
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },


    emailValidationSend: (args) => {
        //arn:aws:lambda:us-east-1:645160913840:function:ALANI_EMAIL_VALIDATION_SEND
        return new Promise((resolve, reject) => {

            let {
                accountId,
            } = args;

            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/account/${accountId}/email/confirm/send`;

            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );

            fetch(url, {
                headers,
                method: 'POST',
                body: JSON.stringify(args)
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },


    addressChange: (args) => {
        return new Promise((resolve, reject) => {

            let {
                accountId,
            } = args;

            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/account/${accountId}/address/change`;

            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );

            fetch(url, {
                headers,
                method: 'POST',
                body: JSON.stringify(args)
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },

    transExpressCreate: (args) => {
        return new Promise((resolve, reject) => {
            //arn:aws:lambda:us-east-1:645160913840:function:cloud9-MerxTransExpressAPI-CreatePOBox-1ASHLLL5UZ038
            let {
                accountId,
            } = args;
            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/account/${accountId}/transexpress`;

            var headers = new Headers();

            fetch(url, {
                headers,
                method: 'POST',
                //body: JSON.stringify(args)
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })

    },


    addressSave: (args) => {

        //cloud9-AlaniRegistration-AddressSave-IzCHC7MIOKby

        return new Promise((resolve, reject) => {

            let {
                accountId,
            } = args;
            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/account/${accountId}/address/`;

            var headers = new Headers(
                {
                    'Content-Type': 'application/json'
                }
            );

            fetch(url, {
                headers,
                method: 'POST',
                body: JSON.stringify(args)
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },


    accountLookup: async (args) => {




        return new Promise((resolve, reject) => {
            let { accountId } = args;
            let url = ` https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/registration/account/${accountId}`;
            var headers = new Headers();
            //const token = Cache.getItem('Merx:authtoken');
            //headers.append("Authorization", token);
            //headers.append("x-api-key", config.apiGateway.api_key);
            fetch(url, {
                headers,
                method: 'GET',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    reject(err);
                });
        })
    },

    phoneLookup: async (args) => {
        return new Promise((resolve, reject) => {
            let {
                phone,
                email
            } = args;
            email = encodeURIComponent(email);

            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/registration/phone_lookup/${phone}?email=${email}`;

            var headers = new Headers();
            //const token = Cache.getItem('Merx:authtoken');
            //headers.append("Authorization", token);
            //headers.append("x-api-key", config.apiGateway.api_key);
            fetch(url, {
                headers,
                method: 'GET',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    reject(err);
                });
        })
    },

    accountCreate: (args) => {
        //arn:aws:lambda:us-east-1:645160913840:function:ALANI_B2C_ACCOUNT_CREATE
        return new Promise((resolve, reject) => {


            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/registration/`;

            var headers = new Headers(
                { 'Content-Type': 'application/json' }
            );
            //const token = Cache.getItem('Merx:authtoken');
            //headers.append("Authorization", token);
            //headers.append("x-api-key", config.apiGateway.api_key);
            fetch(url, {
                headers,
                method: 'POST',
                body: JSON.stringify(args)
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    },
}


export default registrationAPI;