import React, { useState } from 'react';

import API from "../../api/Hapi";

import Header from '../../app/AdminComponents/Header';

import { useNavigate, } from "react-router-dom";
import {   ThreeDots } from "react-loader-spinner";
///import { useParams } from "react-router-dom"; 

import Styles from "../../styles";



const HAPIRequest = () => {


    const navigate = useNavigate();
    const [fetching2, setFetching2] = useState(false);
    const [results, setResults] = useState({
        users: [],
        countTotal: [],
        countPerRFQ: []
    });
    const [selectedUsers, setSelectedUsers] = useState({});
    const [selectedRFQ, setSelectedRFQ] = useState({});

    const fnHapiRequest = async () => {
        let users = "";
        let rfqs = "";
        for (var u in selectedUsers) {
            users += u + ","
        }
        for (var r in selectedRFQ) {
            rfqs += r + ","
        }
        rfqs += '0'; // add trailing 0 to avoid errors in SQL SP pricing_parts_without_data_get()

        setFetching2(true);
        await API.hapiRequestSend({
            users,
            rfqs
        });
        alert("Email request sent");
        setFetching2(false);
    }

    const dataFetch = async () => {
        setFetching2(true);
        let res_users = await API.infoGet();
        let _selectedUsers = {}, _selectedRFQ = {};
        res_users.users.forEach(u => _selectedUsers[u.id] = 1);
        res_users.countPerRFQ.forEach(u => _selectedRFQ[u.purchaseOrderId] = 1);
        setSelectedUsers(_selectedUsers);
        setSelectedRFQ(_selectedRFQ);
        setResults(res_users);

        setFetching2(false);
    }

    /* eslint-disable */
    React.useEffect(() => {
        dataFetch();
    }, []);
    /* eslint-enable */

    if (!localStorage.getItem("token")) {
        navigate("/login")
        return null
    }

    return (
        <div>

            <Header />

            <Styles.PageTitle>{"Human API Request"}</Styles.PageTitle>

            <main style={{
                margin: "auto",
                maxWidth: 500,
                minWidth: 350,
                fontFamily: 'Montserrat',

            }}>

                {fetching2 ?
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: 50
                    }}>
                        <ThreeDots
                            type={"ThreeDots"}
                            color="#FF9400"
                            height={20}
                            width={70}
                            timeout={35000}
                        />
                    </div>
                    :

                    <div>
                        <div style={{
                            marginTop: 10,
                            marginBottom: 10
                        }}>
                            <div style={{
                                fontWeight: 650,
                                color: "#000",
                                margin: 5,
                            }}>Missing Data</div>
                            <div
                                style={{
                                    marginBottom: 10,
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                            >


                                {results.countPerRFQ.map((i, ix) => <button key={ix}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        padding: 5,
                                        width: '100%',
                                        borderStyle: 'none'
                                    }}
                                    onClick={() => {
                                        let _selectedRFQ = {
                                            ...selectedRFQ
                                        };
                                        if (_selectedRFQ[i.purchaseOrderId] === 1) {
                                            delete _selectedRFQ[i.purchaseOrderId];
                                        } else {
                                            _selectedRFQ[i.purchaseOrderId] = 1;
                                        }

                                        setSelectedRFQ(_selectedRFQ);
                                    }}
                                >

                                    <span
                                        style={{
                                            borderColor: '#000',
                                            borderWidth: 1,
                                            borderStyle: 'solid',
                                            width: 18,
                                            height: 18,
                                            borderRadius: 9,
                                            marginRight: 10,
                                            backgroundColor: selectedRFQ[i.purchaseOrderId] === 1 ? 'green' : '#fff'
                                        }}
                                    >
                                    </span>
                                    <span style={{ width: 150, textAlign: 'left' }}>{i.customerReference}</span>

                                    <span>{i.itemsCount}</span>
                                </button>)}
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    marginTop: 10
                                }}>
                                    <span style={{ width: 150, marginLeft: 35 }}>Total</span>
                                    <span>{results.countTotal[0]?.itemsCount}</span>

                                </div>
                            </div>

                            <div style={{
                                fontWeight: 650,
                                color: "#000",
                                margin: 5
                            }}>Authorized Human API Emails</div>
                            <div
                                style={{
                                    marginBottom: 10
                                }}
                            >
                                {
                                    results.users.map((user, ix) => <button key={ix}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            padding: 5,
                                            width: '100%',
                                            borderStyle: 'none'
                                        }}
                                        onClick={() => {
                                            let _selectedUsers = {
                                                ...selectedUsers
                                            };
                                            if (_selectedUsers[user.id] === 1) {
                                                delete _selectedUsers[user.id];
                                            } else {
                                                _selectedUsers[user.id] = 1;
                                            }

                                            setSelectedUsers(_selectedUsers);
                                        }}
                                    >
                                        <span
                                            style={{
                                                borderColor: '#000',
                                                borderWidth: 1,
                                                borderStyle: 'solid',
                                                width: 18,
                                                height: 18,
                                                borderRadius: 9,
                                                marginRight: 10,
                                                backgroundColor: selectedUsers[user.id] === 1 ? 'green' : '#fff'
                                            }}
                                        >
                                        </span>


                                        <span>{user.userName}  {`<${user.human_api_email}>`}</span>

                                    </button>)
                                }
                            </div>
                            <Styles.ButtonMain
                                onClick={() => {
                                    if (window.confirm("Are you sure you wish to request data to selected users?")) {

                                        fnHapiRequest();
                                    }

                                }}
                                style={{
                                    margin: 'auto',
                                    marginTop: 30,
                                }}
                            >
                                <span style={{ color: '#fff' }}>Send Human API Request</span>
                            </Styles.ButtonMain>

                        </div>

                    </div>


                }


            </main>
        </div>
    );
}


export default HAPIRequest;

