import React from 'react';



var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const B2CPartQuoteComponent = ({
    item
}) => {


    return <React.Fragment>

        <h2 style={{
            textAlign: 'center',
        }}>{item.partNumber}<br />{item.partName}</h2>
        <span style={{
            textAlign: 'center',
        }}>{item.partDescription}</span>
        <div
            style={{ margin: 20 }}
        >
            <div style={styles_local.divRow}><span style={styles_local.divRow__label}>Precio</span><span>{formatter.format(item.price)}</span></div>
            <div style={styles_local.divRow}><span style={styles_local.divRow__label}>Envío</span><span>{formatter.format(item.shipping)}</span></div>
            <div style={styles_local.divRow}><span style={styles_local.divRow__label}>Aduana</span><span>{formatter.format(item.customs)}</span></div>

            <div style={{
                ...styles_local.divRow,
                ...{
                    marginTop: 5, marginBottom: 5
                }
            }}><span style={{
                ...styles_local.divRow__label,
                ...{ fontWeight: 'bold' }
            }}>Total</span><span style={{ fontWeight: 'bold' }}>{formatter.format(item.customs + item.shipping + item.price)}</span></div>


            <div style={styles_local.divRow}><span style={styles_local.divRow__label}>ETA</span><span>{item.eta_days} días</span></div>
        </div>
        {item.partImageURL != null && <span><img src={`http://${item.partImageURL}`} alt={`${item.partName}`}></img></span>}


    </React.Fragment>
}


export default B2CPartQuoteComponent;



const styles_local = {
    divRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: 180
    },
    divRow__label: {
        display: 'flex',
    },
    small: {
        fontSize: 14,
        marginTop: 10, marginBottom: 5,
        textAlign: 'left',
    }
}