import React from 'react'
import {   ThreeDots } from "react-loader-spinner";
import Styles from "../styles";
import b2cAPI from "../api/b2c";

import { useDispatch, useSelector } from 'react-redux';

import {
    useParams,
    useNavigate
} from "react-router-dom";
import moment from 'moment-timezone';
// eslint-disable-next-line
import localization from 'moment/locale/es'

import B2CPartQuoteComponent from "../app/components/B2CPartQuoteComponent";
 

moment.locale('es');
 
const PartQuote = () => { 

    const code = useParams().code;
    const navigate = useNavigate();
    const dataReducerB2C = useSelector((state) => state.dataReducerB2C); 
     
    const {
        buyNow
    } = dataReducerB2C; 
    const item = buyNow?.item;
    const fnBuyNow = () => {
        
        navigate(`/buyNow/${code}/c` );
    } 
    if (item == null) {
        return <div></div>
    }

     

    return <div
        style={{
            flexDirection: "column",
            display: 'flex',
            alignItems: 'center',
        }}
    >  
        
        <B2CPartQuoteComponent 
            item={item}
        />


        <Styles.ButtonSecondary

            onClick={() => fnBuyNow()}
            style={{
                width: 180,
                margin: 20
            }}

        >
            Confirmar
            </Styles.ButtonSecondary>
    </div>
}

const B2CBuyNow = () => {

    const navigate = useNavigate();
    const code = useParams().code;

    const dispatch = useDispatch(); 
    const dataReducerB2C = useSelector((state) => state.dataReducerB2C); 
    const {
        buyNow
    } = dataReducerB2C; 
     
    //const [fetching2, setFetching2] = React.useState(true);
    const [fetching3, setFetching3] = React.useState(false);
    //const [phoneNumber, setPhoneNumber] = React.useState();
    const [whatsAppQuote, setWhatsAppQuote] = React.useState();

 
    const fnGetPartQuote = async ({
        code
    }) => {
        setFetching3(true);
        let res = await b2cAPI.partQuoteGet({
            code
        });
        setFetching3(false);
          
        if (res.success) { 
            dispatch({
                type : "BUY_NOW",
                buyNow: res
            });
            setWhatsAppQuote(res);
             
        }  



    }


    /* eslint-disable */
    React.useEffect(() => {
        if(buyNow == null) { 
            fnGetPartQuote({ code });
        }
    }, []);
    /* eslint-enable */


    return (
        <div>

            <header
                style={{
                    backgroundColor: "#FF9400"
                }}
            >
                <div style={{
                    margin: "auto",
                    maxWidth: 1200,
                    minWidth: 350,
                    padding: 5,
                    flexDirection: "row",
                    display: 'flex',
                    justifyContent: "space-between",
                    alignContent: "center",
                    alignItems: "center",

                }}>
                    <div style={{ width: 80 }} />
                    <img alt='ALANI 360 logo'
                        src='/images/alani_logos_vertical_horizontal_orange_white.svg#svgView(viewBox(320, 90, 260, 70))'
                        style={{
                            width: 120, height: 60
                        }}
                    />
                    <div style={{ width: 100 }} />
                </div>
            </header>
            <Styles.PageTitle
                        style={{
                            height: 40
                        }}
            >
                ALANI GARAGE
            
                {buyNow != null && buyNow.account != null &&
                    <div style={{
                        textAlign: 'center',
                        color: '#FF9400',
                        fontWeight: 'bold'
                    }}>
                        {buyNow.account.firstName} {buyNow.account.lastName}
                    </div>
                }
            </Styles.PageTitle>
 
               
                <main style={{
                    margin: "auto",
                    maxWidth: 400,
                    minWidth: 350,
                    fontFamily: 'Montserrat',
                    padding: 5,
                }}>

                <button
                    onClick={() => navigate(-1)}
                >Regresar</button>

                {fetching3 && 
                        <div  style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: 20
                        }}
                            >
                            <ThreeDots
                                    type={"ThreeDots"}
                                    color="#FF9400"
                                    height={20}
                                    width={80}
                                    timeout={5000}
                                />
                        </div>
                }

                {whatsAppQuote !== null &&

                    <PartQuote
                         
                    />

                }
            </main>
            
        </div>
    );
}


export default B2CBuyNow;
 