import React from 'react';


import { useNavigate } from "react-router-dom";
import moment from 'moment-timezone';
// eslint-disable-next-line
import localization from 'moment/locale/es'


import { useSelector } from 'react-redux';

import {
    IoAttach,
} from "react-icons/io5";
function toTitleCase(str) {
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}
const RFQFiles = () => {

    const navigate = useNavigate();
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        purchaseOrder,
    } = dataReducer;



    /* eslint-disable */
    React.useEffect(() => {

    }, []);
    /* eslint-enable */


    if (!localStorage.getItem("token")) {
        navigate("/login")
        return null
    }


    if (!purchaseOrder) return null;

    return (
        <div style={{
            marginTop: 10,
            borderColor: '#c0c0c0',
            borderWidth: 1,
            borderStyle: 'solid'
        }}>
            {purchaseOrder.attachments.map((attachment, ix) => {

                return <div
                    key={ix}
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        display: 'flex',
                        color: 'blue',
                        backgroundColor: ix % 2 ? '#e0e0e0' : '#fff',
                        padding: 5,
                        paddingBottom: 10,
                        paddingTop: 10
                    }}
                >
                    <IoAttach
                        size={25}
                    />

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <a target="_blank" rel="noopener noreferrer" href={`${attachment.url}`}>
                            {attachment.name}
                        </a>

                        <span style={{ color: 'blue', fontSize: 12 }}>{`${toTitleCase(moment(attachment.timestamp).tz('America/New_York').format('MMM DD HH:mm'))}`}</span>
                    </div>
                </div>
            })
            }

        </div>
    );
}


export default RFQFiles;
