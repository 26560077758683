import React  from 'react';
import registrationAPI from '../api/registrationAPI';
import { useCookies } from 'react-cookie';
import {
    useParams, 
} from "react-router-dom";


const AccountActivate = () => {

    let token = useParams().token;
 
    // eslint-disable-next-line
    const [cookies, setCookie] = useCookies(['alani360']);

    const [fetching, setFetching] = React.useState(true)

    /* eslint-disable */
    React.useEffect(() => {
       
        setFetching(true);
        registrationAPI.accountActivate({
            token
        }).then( res =>  { 
            setFetching(false);
            if (res.success){
                setCookie('alani360', {
                    token,
                    phoneNumber: res.data.telephone,

                }, { path: '/' }); 
 
            }}
        ); 
    }, []);

    /* eslint-enable */



    return <div
        style={{
            paddingTop: 50,
            width: 300,
            margin: 'auto', 
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }}
    >
        <div
            style={{
                backgroundColor: '#FF9400',
                padding: 20,
                paddingTop: 10,
                paddingBottom: 10,
                margin: 20,
                borderRadius: 10
            }}
        >
            <img alt='ALANI 360 logo'
                src='/images/alani_logos_vertical_horizontal_orange_white.svg#svgView(viewBox(320, 90, 260, 70))'
                style={{
                    width: 120, height: 60
                }}
            />  
        </div>
        {fetching ? "Activando tu cuenta..."

        :
            <div
                style={{
                    textAlign: 'center'
                }}
            >
                Listo!
                <br/><br/>
                Tu cuenta ha sido activada
                <br/><br/>
                <a href={`/p/${token}`}>Continuar</a>
            </div>

        }
        
         
    </div>
}


export default AccountActivate;