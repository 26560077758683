import React, { useState } from 'react';

import cposAPI from "../../api/cposAPI";

import Header from '../../app/AdminComponents/Header';

import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
///import { useParams } from "react-router-dom"; 

import Styles from "../../styles";

import PODetails from "../../app/AdminComponents/PODetails";

import { useDispatch, useSelector } from 'react-redux';




const PONew = () => {
    const dataReducer = useSelector((state) => state.dataReducer);
    const dispatch = useDispatch();
    let cpos = dataReducer.cpos;

    const navigate = useNavigate();
    const [fetching2, setFetching2] = useState(false);
    const [poDetails, setPODetails] = React.useState({});

    /* eslint-disable */
    React.useEffect(() => {

    }, []);
    /* eslint-enable */



    const fnSavePO = async () => {

        let payload = {
            cpoId: 0,
            poReference: poDetails.poReference,
            itemId_array: [],
            itemId_array_remove: []
        }
        setFetching2(true);
        let res = await cposAPI.save(payload);
        dispatch({
            type: "CPOS_SET",
            cpos: cpos.map(c => (c.id === res.client_po.id) ? res.client_po : c)
        });
        setFetching2(false);

        if (res.success) {
            navigate(`/admin/pos/${res.client_po.id}?back=${encodeURIComponent("/admin/pos")}&addItems=1`);

        } else {
            alert(res.msg)
        }

    }


    if (!localStorage.getItem("token")) {
        navigate("/login")
        return null
    }

    return (
        <div>

            <Header />

            <Styles.PageTitle>New Purchase Order</Styles.PageTitle>

            <main style={{
                margin: "auto",
                maxWidth: 500,
                minWidth: 350,
                fontFamily: 'Montserrat',
                padding: 5
            }}>
                <button onClick={() => navigate("/admin/pos/")} >&lt; All Purchase Orders</button>

                {fetching2 ?
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: 50
                    }}>
                        <ThreeDots
                            type={"ThreeDots"}
                            color="#FF9400"
                            height={20}
                            width={70}
                            timeout={5000}
                        />
                    </div>
                    :

                    <div
                        style={{
                            marginTop: 10
                        }}
                    >

                        <PODetails
                            fnSetPoDetails={(obj) => {
                                setPODetails(obj);
                            }}
                        />

                        <Styles.ButtonMain
                            style={{
                                marginTop: 10,
                                width: 150
                            }}
                            disabled={fetching2}
                            onClick={() => fnSavePO()}
                        >
                            {fetching2 ?
                                <ThreeDots
                                    type="ThreeDots"
                                    color="#fff"
                                    height={10}
                                    width={50}
                                    timeout={10000}
                                />
                                :
                                <React.Fragment>Save P/O</React.Fragment>
                            }
                        </Styles.ButtonMain>

                    </div>
                }


            </main>
        </div>
    );
}


export default PONew;