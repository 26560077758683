import React from 'react';
import { useNavigate, useParams } from "react-router-dom";

import { ThreeDots } from "react-loader-spinner";

import registrationAPI from "../../api/registrationAPI";


import { useSelector } from 'react-redux';
import Styles from "../../styles";

const AccountInfoConfirmActions = ({
    accountInfo
}) => {
    let token = useParams().token;
    const navigate = useNavigate();
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        userData,
    } = dataReducer;

    const [fetching, setFetching] = React.useState(false);
    const fnConfirmationsResend = async () => {

        if (window.confirm("Está seguro que quiere reenviar los mensajes de confirmación?")) {
            setFetching(true);
            await registrationAPI.accountInfoConfirmationsResend({
                accountId: userData.accountInfo.id
            });
            setFetching(false);
            alert('Confirmaciones reenviadas');
        }
    }
    const fnCancelRequest = async () => {

        if (window.confirm("Está seguro que quiere cancelar la solicitud?")) {
            setFetching(true);
            await registrationAPI.accountInfoUpdateCancel({
                accountId: userData.accountInfo.id
            });
            setFetching(false);
            alert('Los cambios solicitados han sido cancelados');
            navigate(`/p/${token}/account`)
        }



    }

    return <div
        style={{
            margin: 10,
            textAlign: 'center'
        }}
    >

        <br />
        {
            accountInfo.updateRequest_email !== null
            &&
            <div>
                Revisa tu correo
                <br />
                <b>{accountInfo.updateRequest_email}</b>
                <br />
                para confirmar el cambio a<br /> tu dirección de correo
            </div>
        }
        <br />
        {
            accountInfo.updateRequest_phone !== null
            &&
            <div>
                Revisa tu número de Whatsapp
                <br />
                <b>{accountInfo.updateRequest_phone}</b>
                <br />
                para confirmar el cambio de número de celular
            </div>
        }

        <div
            style={{
                marginTop: 20,
                flexDirection: 'row',
                display: 'flex'
            }}
        >


            <Styles.ButtonCancel
                style={{
                    width: 180,
                    marginRight: 5
                }}
                disabled={fetching}
                onClick={() => fnCancelRequest()}
            >

                {fetching ?
                    <ThreeDots
                        color="#3482B3"
                        height={10}
                        width={50}
                        timeout={5000}
                    />
                    :
                    <span style={{ color: '##000' }}>Cancelar Solicitud</span>
                }
            </Styles.ButtonCancel>

            <Styles.ButtonSecondary
                style={{
                    width: 120,
                    marginLeft: 5
                }}
                disabled={fetching}
                onClick={() => fnConfirmationsResend()}
            >

                {fetching ?
                    <ThreeDots
                        color="#000"
                        height={10}
                        width={50}
                        timeout={5000}
                    />
                    :
                    <span style={{ color: '#fff' }}>Reenviar</span>
                }
            </Styles.ButtonSecondary>


        </div>
    </div>

}


export default AccountInfoConfirmActions;