import React, { useState } from 'react';

import purchaseOrderAPI from "../../api/purchaseOrderAPI";
import emailAPI from "../../api/emailsAPI";

import { ThreeDots }  from "react-loader-spinner";
import Header from '../../app/AdminComponents/Header';

import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import moment from 'moment-timezone';
// eslint-disable-next-line
import localization from 'moment/locale/es'

import Styles from "../../styles";

import { useDispatch, useSelector } from 'react-redux';
import PoSummary from '../../app/AdminComponents/POSummary';
import RFQFiles from "../../app/AdminComponents/RFQFiles";
import RFQAdminOptions from "../../app/AdminComponents/RFQAdminOptions";
import RFQApiStats from "../../app/AdminComponents/RFQApiStats";

import RFQItems from "../../app/AdminComponents/RFQItemList.v2";


import {
    IoAttach,
    IoWarning,
    IoChevronDown,
    IoChevronUp
} from "react-icons/io5";



//Modal.setAppElement('#ModalElement');

moment.locale('en');


var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

function toTitleCase(str) {
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}



const POEmails = ({
    fnReloadRFQ
}) => {

    const navigate = useNavigate();
    const [fetching, setFetching] = React.useState("");

    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        purchaseOrder,
    } = dataReducer;


    const fnDeleteEmail = async ({ emailId }) => {

        setFetching("deleting_email");
        await emailAPI.deleteEmail({ emailId });
        await fnReloadRFQ();
        setFetching("");
    }

    if (!purchaseOrder) {
        return null
    }
    return <div  >

        <div
            style={{
                justifyContent: 'flex-end',
                display: 'flex',
                flexDirection: 'row'
            }}
        >
            <Styles.ButtonSecondary
                onClick={() => navigate(`/admin/rfq/${purchaseOrder.id}/email/`)}
            >
                New Email
            </Styles.ButtonSecondary>
        </div>
        <hr style={{ width: "100%" }} />

        {purchaseOrder?.emails?.map((email, ix) => {

            let attachments = purchaseOrder.attachments.filter(a => a.emailId === email.emailId);
            // TODO: factorize code below
            return <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
                key={ix}
            >

                <div style={{
                    padding: 5,
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <span style={{ fontSize: 13, color: '#3482B3' }}>
                        {email.outgoing === 1
                            ?
                            "Sent"
                            :
                            "Received"
                        }
                    </span>

                    {toTitleCase(moment(email.dateTimeCreated).tz('America/New_York').format('MMMM DD HH:mm'))}
                </div>
                <div
                    style={{
                        backgroundColor: '#e0e0e0',
                        padding: 5,
                        borderRadius: 5,
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <span style={{ fontSize: 13, color: '#3482B3' }}>
                        Sender
                    </span>
                    {email.sender}
                </div>
                <div
                    style={{
                        backgroundColor: '#e0e0e0',
                        padding: 5,
                        borderRadius: 5,
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <span style={{ fontSize: 13, color: '#3482B3' }}>
                        Recipient
                    </span>
                    {email.recipient}
                </div>
                <div
                    style={{
                        backgroundColor: '#e0e0e0',
                        marginTop: 10,
                        marginBottom: 10,
                        padding: 5,
                        borderRadius: 5,
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <span style={{ fontSize: 13, color: '#3482B3' }}>Subject</span>
                    {email.subject}
                </div>
                <div
                    style={{
                        backgroundColor: '#e0e0e0',
                        marginBottom: 10,
                        padding: 5,
                        borderRadius: 5
                    }}
                    dangerouslySetInnerHTML={{ __html: email.bodyHtml }}></div>

                {attachments.length >= 0 &&

                    <div >
                        {attachments.map((attachment, ix2) => {
                            return <span key={ix2}
                                style={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    display: 'flex',
                                    color: 'blue'
                                }}
                            >
                                <IoAttach
                                    size={25}
                                />
                                <a target="_blank" rel="noopener noreferrer" href={`${attachment.url}`}>
                                    {attachment.name}
                                </a>

                            </span>
                        })}
                    </div>

                }
                <div
                    style={{
                        justifyContent: 'flex-end',
                        display: 'flex',
                        flexDirection: 'row'
                    }}
                >

                    <Styles.ButtonDelete
                        disabled={fetching === "deleting_email"}
                        style={{ height: 30, marginRight: 20 }}
                        onClick={() => fnDeleteEmail({ emailId: email.emailId })}
                    >
                        {fetching === "deleting_email" ?
                            <ThreeDots
                                color="#3482B3"
                                height={10}
                                width={50}
                                timeout={5000}
                            />
                            :
                            <span style={{ fontSize: 13, color: 'red', }}>Delete</span>
                        }
                    </Styles.ButtonDelete>

                    {email.outgoing === 0 &&
                        <Styles.ButtonSecondary
                            style={{ fontSize: 13, height: 30, color: '#fff', marginRight: 10 }}
                            onClick={() => navigate(`/admin/rfq/${purchaseOrder.id}/email/?replyTo=${email.emailId}`)}
                        >
                            Reply
                        </Styles.ButtonSecondary>
                    }
                </div>

                <hr style={{ width: "100%" }} />
            </div>
        })}
    </div>
}

const PurchaseOrdersForRFQ = ({
    purchaseOrder,
}) => {
    const navigate = useNavigate();

    if (purchaseOrder?.cpos.length === 0) {
        return <div style={{ textAlign: 'center' }}>
            <hr />
            <span>No Purchase Orders for this RFQ</span>
        </div>
    }

    return <div>
        <hr />
        Purchase Orders
        {purchaseOrder.cpos.map((po, ix) => {

            return <div
                key={ix}
                style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                    <h1 style={{ color: "#FF9400" }}>{po.poReference}</h1>


                    <span style={{
                        color: "#FF9400"
                    }}>{toTitleCase(moment(po.createdDT).tz('America/New_York').format('MMMM DD HH:mm'))}</span>
                    <Styles.ButtonMain
                        style={{
                            width: 100
                        }}
                        onClick={() => navigate(`/admin/rfpo/${purchaseOrder.id}/po/${po.id}?v=pos`)}

                    >View</Styles.ButtonMain>
                </div>


            </div>
        })

        }
    </div>
}


const RFQPurchaseOrder = () => {

    const navigate = useNavigate();
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        purchaseOrder,
        purchaseOrderItems
    } = dataReducer;


    const [itemsPOs, setItemsPOs] = useState({});


    /* eslint-disable */
    React.useEffect(() => {
        if (purchaseOrderItems) {

            let _items = {}
            purchaseOrderItems.map(item => {
                let _i = _items[item.approvedClientPoId || (item.rejectedDateTime ? "-1" : "0")] || [];
                _i.push(item);
                _items[item.approvedClientPoId || (item.rejectedDateTime ? "-1" : "0")] = _i
            });

            setItemsPOs(_items);
        }
    }, [purchaseOrderItems]);
    /* eslint-enable */


    if (!purchaseOrder || !purchaseOrderItems) {
        return null
    }


    const ItemList = ({
        items,
        title,
        color
    }) => {

        const [viewDetails, setViewDetails] = useState(false);

        if (!items) return null


        if (!viewDetails) {
            return <div
                style={{
                    marginTop: 10
                }}
            >
                {items.length > 0 ? <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row'
                    }}
                >
                    <div style={{
                        marginRight: 10,
                        marginLeft: 10,
                        width: 150,
                        color: color || "#000",
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>{title}</div>
                    <button
                        style={{
                            marginBottom: 10,
                            backgroundColor: '#fff',
                            borderColor: color || '#000',
                            borderStyle: 'solid',
                            borderWidth: 1,
                            width: 180,
                            color: color || '#000',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                        onClick={() => setViewDetails(true)}
                    >{items.length} items
                        <IoChevronDown
                            color={color || '#000'}

                        />
                    </button>
                </div>
                    :
                    <span>None</span>
                }
            </div>
        }

        return <div style={{
            padding: 5,
        }}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row'
                }}
            >
                <div style={{
                    marginRight: 10,
                    width: 150,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>{title}</div>
                <button
                    style={{
                        marginBottom: 10,
                        backgroundColor: '#c0c0c0',
                        borderColor: '#000',
                        borderStyle: 'solid',
                        borderWidth: 1,
                        width: 180,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                    onClick={() => setViewDetails(false)}
                >Hide

                    <IoChevronUp />
                </button>
            </div>
            <div
                style={{
                    borderColor: '#000',
                    borderStyle: 'solid',
                    borderWidth: 1,
                    padding: 5,
                    backgroundColor: '#f8f8f8'
                }}
            >
                {items.map((item, ix_item) => <div
                    key={"ix_item" + ix_item}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        borderBottomStyle: 'solid',
                        borderBottomColor: '#c0c0c0',
                        borderBottomWidth: 1,
                        paddingTop: 5, paddingBottom: 5,
                    }}
                >
                    <span style={{ flex: 1 }}>{item.ref1}</span>
                    <span style={{ flex: 3 }}>{item.ref2}</span>
                    <span style={{ flex: 2 }}>{item.ref4}!</span>
                    <span style={{ flex: 3 }}>{
                        (item.approvedDateTime || item.rejectedDateTime)
                            ?
                            (toTitleCase(moment(item.approvedDateTime || item.rejectedDateTime).tz('America/New_York').format('MMM DD HH:mm')))
                            :
                            ""
                    }</span>
                    <div style={{ flex: 1, alignItems: 'flex-end', display: 'flex', flexDirection: 'column' }}>
                        <span ><b>{formatter.format(item.quotedTotal)}</b></span>
                        <span>{formatter.format(item.shippingCost)}</span>
                        <span>{formatter.format(item.customsCost)}</span>
                        <span>{formatter.format(item.msrp)}</span>

                    </div>

                </div>

                )}
            </div>
        </div>

    }

    const ItemsWithoutPO = (
        { items }
    ) => {
        if (!items) return null;
        if (items.length === 0) return null;

        return <div>
            <Styles.ButtonMain
                style={{
                    paddingLeft: 20,
                    paddingRight: 20,
                    margin: "auto"
                }}
                onClick={() => navigate(`/admin/rfpo/${purchaseOrder.id}/po/0?v=pos`)}

            >
                Add Items to Purchase Order
                <IoWarning size={20} style={{ fill: '#fff', marginLeft: 5 }} />
            </Styles.ButtonMain>
            <ItemList
                items={itemsPOs["0"]?.filter(item => item.approvedDateTime != null)}
                title="Not in P/O"
                color="red"
            />
        </div>
    }

    const ItemsWithoutAnswer = ({ items }) => {

        if (!items) return null;
        if (items.length === 0) return null;

        return <ItemList
            items={items}
            title={"No Answer"}
        />
    }


    const ItemsRejected = ({ items }) => {
        return <>

            <ItemList
                items={items}
                title={"Rejected"}
            />
        </>

    }

    return <div>
        <ItemsWithoutPO
            items={itemsPOs["0"]?.filter(item => item.approvedDateTime != null)}
        />

        <ItemsWithoutAnswer
            items={itemsPOs["0"]?.filter(item => item.msrp !== null && (item.shippingCost || 0) !== 0 && item.approvedDateTime == null)}
        />


        <ItemsRejected
            items={itemsPOs["-1"]}
        />




        <PurchaseOrdersForRFQ
            purchaseOrder={purchaseOrder}
        />

    </div>
}


const RFQDetails = () => {
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        purchaseOrder,

    } = dataReducer;

    if (!purchaseOrder) {
        return null
    }

    return <PoSummary
        po={purchaseOrder}
        showDetails={true}
    />
}


const RequestForQuote = ({
    StartForm
}) => {
    /* eslint-disable */
    const [searchParams, setSearchParams] = useSearchParams();
    /* eslint-enable */

    const navigate = useNavigate();
    const purchaseOrderId = useParams().purchaseOrderId;

    const [hideMainWindow, setHideMainWindow] = useState(false);///

    const [fetching2, setFetching2] = useState(false);
    const [backURL, setBackURL] = useState(-1);
    const [section, setSection] = useState(StartForm || (searchParams.get("v") || "rfq"));
    const dispatch = useDispatch();
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        purchaseOrder,
        fetching
    } = dataReducer;

    let fnPOGet = async () => {
        setFetching2(true);
        let res = await purchaseOrderAPI.poGet({
            purchaseOrderId
        });

        setFetching2(false);
        if (res.success) {
            dispatch({
                type: 'PO_SET',
                purchaseOrder: res.po,
            });
            dispatch({
                type: 'PO_ITEMS_SET',
                purchaseOrderItems: res.items
            });
        }
    }

    /* eslint-disable */
    React.useEffect(() => {
        fnPOGet();
    }, []);
    /* eslint-enable */



    const Menu = () => {

        return <div

        >
            <button
                style={{
                    marginLeft: 5
                }}
                onClick={() => {
                    dispatch({
                        type: 'PO_SET',
                        purchaseOrder: {},
                    });
                    dispatch({
                        type: 'PO_ITEMS_SET',
                        purchaseOrderItems: []
                    });
                    navigate("/admin/rfqs");
                }}
            >
                Back
            </button>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end'
                }}
            >


                <button
                    style={section === "rfq" ? styles.menuButton__selected : styles.menuButton}
                    onClick={() => {
                        setSearchParams({ v: "rfq" });
                        setBackURL(backURL - 1);
                        setSection("rfq");
                    }} >Details</button>
                <button
                    style={section === "pos" ? styles.menuButton__selected : styles.menuButton}

                    onClick={() => {
                        setSearchParams({ v: "pos" });
                        setBackURL(backURL - 1);
                        setSection("pos");

                    }} >POs</button>
                <button
                    style={section === "emails" ? styles.menuButton__selected : styles.menuButton}

                    onClick={() => {
                        setSearchParams({ v: "emails" });
                        setBackURL(backURL - 1);
                        setSection("emails");
                    }} >Emails</button>


                <button
                    style={section === "files" ? styles.menuButton__selected : styles.menuButton}

                    onClick={() => {
                        setSearchParams({ v: "files" });
                        setBackURL(backURL - 1);
                        setSection("files");
                    }} >Files</button>


                <button
                    style={section === "api" ? styles.menuButton__selected : styles.menuButton}

                    onClick={() => {
                        setSearchParams({ v: "api" });
                        setBackURL(backURL - 1);
                        setSection("api");
                    }} >API</button>
            </div>


        </div>
    }

    return (
        <div>

            <Header />

            <Styles.PageTitle>RFQ {purchaseOrder?.customerReference}</Styles.PageTitle>

            <main style={{
                margin: "auto",
                maxWidth: 600,
                minWidth: 350,
                fontFamily: 'Montserrat',
                marginBottom: 50
            }}>

                {!hideMainWindow &&
                    <div style={{ marginBottom: 10, marginTop: 10 }}>
                        <Menu />
                    </div>
                }


                {section === "rfq" &&
                    <RFQAdminOptions
                        fnHideMainWindow={(val) => setHideMainWindow(val)}
                    />
                }

                {fetching2 || fetching === "RFQ_UPDATING" ?
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: 50
                    }}>
                        <ThreeDots
                            type={"ThreeDots"}
                            color="#FF9400"
                            height={20}
                            width={70}
                            timeout={5000}
                        />
                    </div>
                    :
                    <div

                    >
                        {!hideMainWindow && <div style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}>


                            {section === "files" &&
                                <RFQFiles

                                />
                            }

                            {section === "emails" &&
                                <POEmails
                                    fnReloadRFQ={fnPOGet}
                                />
                            }


                            {section === "rfq" && <div>
                                <RFQDetails />
                                <h3 style={{ margin: 0, padding: 10 }}>Items</h3>

                                <RFQItems />
                            </div>
                            }



                            {section === "pos" && <RFQPurchaseOrder />

                            }

                            {section === "api" &&
                                <RFQApiStats />

                            }
                        </div>
                        }
                    </div>
                }
            </main>
        </div>
    );
}


export default RequestForQuote;


const styles = {


    menuButton: {
        borderStyle: "none",
        backgroundColor: 'transparent'
    },
    menuButton__selected: {
        borderStyle: 'none',
        backgroundColor: 'transparent',
        borderBottomStyle: 'solid',
        borderBottomColor: 'red',
        borderBottomWidth: 2
    },

    itemIndex: {
        fontSize: 18,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 40,
        width: 70,
        borderRadius: 15,
        color: "#000",
        backgroundColor: "#FF9400",
        fontWeight: 'bold'
    },
    row: {
        borderStyle: 'none',
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        color: '#ffffff'
    },
    col: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
    },
    col_status: {
        color: '#fff',
        padding: 5,
        borderRadius: 5,
        textAlign: 'center'
    },
    tabs: {
        display: 'flex',
        flexDirection: 'row',

    },
    tab: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: '#c0c0c0',
        padding: 5,
        textAlign: 'center',
        borderTopRightRadius: 5,
        borderTopLeftRadius: 5,
        borderWidth: 1,
        borderColor: '#808080',
        borderStyle: 'solid',
        marginRight: 3,
        fontSize: 14,
        minWidth: 50

    },
    tab__selected: {
        borderBottomColor: "#fff",
        backgroundColor: '#ffffff'
    },
}
