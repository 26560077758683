import React, { useRef, useState } from 'react'
import { useTranslation, } from 'react-i18next';

import { useCookies } from 'react-cookie';
import {
    useParams,
    useLocation,
    useNavigate,
    Link
} from "react-router-dom";

import pageUserAPI from '../api/pageUserAPI';
import { ThreeDots } from "react-loader-spinner";
import moment from 'moment-timezone';
import Styles from "../styles";
// eslint-disable-next-line
import localization from 'moment/locale/es'
import {
    IoReload,
    IoPersonCircleOutline,
    IoNotifications,

    IoChevronForwardSharp,
} from "react-icons/io5";



import { useDispatch, useSelector } from 'react-redux';

import Menu from '../app/components/Menu';
import Paquetes from "../app/components/Paquetes";
import registrationAPI from '../api/registrationAPI';
import { t } from 'i18next';
const queryString = require('query-string');

moment.locale('es');

const tokenValidate = (token) => {
    //arn:aws:lambda:us-east-1:645160913840:function:ALANI_TOKEN_VALIDATE
    return new Promise((resolve, reject) => {

        let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/token/${token}`;
        var headers = new Headers();
        //headers.append("x-api-key", config.apiGateway.api_key);
        fetch(url, {
            headers,
            method: 'GET',
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (jsonResponse) {
                resolve(jsonResponse);
            }).catch(err => {
                reject(err);
            });
    })
}

const Loading = () => {
    return <div
        style={{
            margin: 50,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}
    ><ThreeDots
            type="ThreeDots"
            color="#FF9400"
            height={50}
            width={50}
            timeout={10000}
        /></div>
}



const CodigoIntroducir = ({
    setTokenSent,
    titulo
}) => {

    // eslint-disable-next-line
    const [cookies, setCookie] = useCookies(['alani360']);
    const navigate = useNavigate();
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        phone,
    } = dataReducer;


    ///const [rememberMe, setRememberMe] = React.useState(true);
    const [tokenReceived, setTokenReceived] = React.useState("");
    const [fetching, setFetching] = React.useState(false);

    return <div>


        <label
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',

            }}
        >
            {titulo || "Código"}

            <div style={{
                fontWeight: 'bold'
            }}>
                {phone}
            </div>
            <input
                type="text"
                value={tokenReceived}
                autoCapitalize={"off"}
                style={{
                    width: 50,
                    marginTop: 5
                }}
                onChange={e => setTokenReceived(e.target.value)}
                maxLength={4}
            />
            <Styles.ButtonMain

                style={{
                    width: 250,
                    marginTop: 10,

                }}
                disabled={fetching}
                onClick={() => {
                    let _msg = "";
                    let _phone = "+" + (phone || "").replace("+", "");

                    if (
                        _phone.indexOf('+1') === -1
                        &&
                        _phone.indexOf('+57') === -1
                        &&
                        _phone.indexOf('+52') === -1
                    ) {
                        _msg += 'El número debe comenzar con el prefijo del país, 57, 52 o 1\n';
                    }
                    if (
                        !tokenReceived
                        ||
                        tokenReceived.length !== 4
                    ) {
                        _msg += "Debes introducir un código de acceso válido"
                    }



                    if (_msg !== "") {

                        alert(_msg);
                        return;
                    }


                    setFetching(true);


                    registrationAPI.clientLogin({
                        tokenOrPin: tokenReceived,
                        phone: _phone.replace("+", "")
                    },
                        _phone
                    ).then(res => {

                        setFetching(false);
                        if (
                            !res.success
                        ) {

                            alert("El código o el número celular no es válido")

                        } else {
                            setCookie('alani360', {
                                token: res.data.urlToken,
                                phoneNumber: res.data.phoneNumber,

                            }, { path: '/' });
                            navigate(`/p2/${res.data.urlToken}`);
                            window.location.reload(false);
                        }

                    });

                }}
            >
                {
                    fetching ?
                        <ThreeDots
                            type="ThreeDots"
                            color="#000"
                            height={10}
                            width={50}
                            timeout={10000}
                        />
                        :
                        <div
                            style={{
                                flexDirection: 'row',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-around'
                            }}
                        >
                            <span style={{ color: '#000' }}>{t('Entrar')}</span>
                        </div>
                }

            </Styles.ButtonMain>

        </label>

    </div>
}

const PageInfo = ({
    token,
    token_phone
}) => {
    // eslint-disable-next-line
    const [cookies, setCookie] = useCookies(['alani360']);
    const dataReducer = useSelector((state) => state.dataReducer);
    const { t } = useTranslation();
    const {
        userData,
        fetching,
    } = dataReducer;
    const dispatch = useDispatch();
    const [fetching2, setFetching2] = React.useState(false);
    const [tokenSent, setTokenSent] = React.useState(false);
    const textInput = useRef(null);

    const navigate = useNavigate();

    const [phone, setPhone] = useState(
        (
            userData &&
            userData.accountInfo &&
            userData.accountInfo.phoneNumber
        )

        ||
        (
            cookies && cookies.alani360 && ("+" + cookies.alani360.phoneNumber)
        )
        ||

        ""
    );


    /* eslint-disable */
    React.useLayoutEffect(() => {
        setPhone(token_phone);

    }, [token_phone]);
    /* eslint-enable */



    const fnTokenRenovar = (_phone) => {

        setFetching2(true);

        pageUserAPI.tokenRenovar({
            token_or_phone: _phone || token
        }).then(res => {
            setFetching2(false);
            if (res.success) {
                setTokenSent(true);
            } else {
                setTimeout(() => {
                    //alert(res.message);
                    alert(`El celular "${_phone}" no está registrado`)
                }, 200)

            }

        });
    }

    if (fetching || fetching2 || !userData) {
        return <Loading />
    }
    if (tokenSent) {
        return <div style={{
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center',
            padding: 50,
            textAlign: 'center',
            flexDirection: 'column'
        }}>
            Te hemos enviado un nuevo link por Whatsapp
            <br /><br />
            Úsalo para acceder tu cuenta.
            <br /><br />
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    alignContent: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column'
                }}
            >

                <CodigoIntroducir
                    setTokenSent={setTokenSent}
                />


            </div>

        </div>
    }

    if (!userData.success || userData?.accountInfo.accountExists === 0) {
        return <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignContent: 'center',
                flex: 1,
                alignItems: 'center',
                paddingTop: 10
            }}
        >

            <div
                style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: "auto",
                    textAlign: 'center',
                    padding: 10,
                }}
            >
                Número de Celular
                <input
                    value={phone}
                    disabled={fetching2}
                    onBlur={() => {
                        dispatch({
                            type: "PHONE_SET",
                            phone
                        });
                    }}
                    onChange={e => setPhone(e.target.value)}
                    ref={textInput}
                    style={{
                        width: 150,
                        fontSize: 18,
                        textAlign: 'center'
                    }}
                />
            </div>


            <CodigoIntroducir
                titulo={t("Código de Acceso o PIN")}
                setTokenSent={setTokenSent}
            />

            <div
                style={{
                    width: "100%",
                    marginTop: 10
                }}
            >
                <hr />
            </div>

            <div style={{
                margin: 10,
                textAlign: 'center'
            }}>
                {t("NoCodePin")}
                <br />
                {phone}
            </div>
            <Styles.ButtonSecondary
                style={{
                    width: 250,
                }}

                onClick={() => {

                    let _phone = (phone ? phone.toString().trim() : "").replace("+", "");

                    setCookie('alani360', {
                        phoneNumber: _phone,

                    }, { path: '/' });
                    dispatch({
                        type: "PHONE_SET",
                        phone: _phone
                    })

                    if (window.confirm(`Enviaremos un código de acceso al número ${_phone}`)) {
                        fnTokenRenovar(_phone);
                    }


                }}
            >
                <span style={{
                    color: '#fff',
                    fontSize: 14
                }}>{t("SolicitarCodigo")}</span>
            </Styles.ButtonSecondary>



            <div
                style={{
                    width: "100%",
                    marginTop: 10
                }}
            >
                <hr />
            </div>
            <div style={{
                padding: 10,
                textAlign: 'center',
            }}>
                {t("Si no estás registrado")},
                <br />
                <button style={{
                    backgroundColor: 'transparent',
                    borderStyle: 'none'
                }}

                    onClick={() => {
                        navigate(`/registracion/?tel=${phone?.replace("+", "")}`)
                    }}
                >
                    <span style={{ textDecoration: 'underline', color: 'blue' }} >{t("crea tu cuenta en Alani360")}</span>
                </button>
            </div>

        </div>


    }

    return <div
        style={{
            padding: 0,
            textAlign: 'center'
        }}

    >
        <div
            style={{
                flexDirection: 'row',
                display: 'flex',
                justifyContent: 'space-between',
                alignContent: 'center',
                alignItems: 'center',
                borderBottomStyle: "solid",
                borderBottomColor: "#c0c0c0",
                borderBottomWidth: 1,
                padding: 5,
                backgroundColor: '#e0e0e0',

            }}
            onClick={() => navigate(`/p/${token}/account`)}
        >
            <span style={{
                fontWeight: 'bold',
            }}>{userData.accountInfo.firstName} {userData.accountInfo.lastName}</span>
            <div
                style={{
                    flexDirection: 'row',
                    display: 'flex',
                    alignContent: 'center',
                    alignItems: 'center',

                }}
            >
                <span style={{
                    marginRight: 10
                }}>
                    {userData.accountInfo.transexpressPoBox}
                </span>



                <IoPersonCircleOutline
                    style={{ fill: userData.accountInfo.shippingAddressConfirmed === 0 ? 'red' : userData.accountInfo.shippingAddressConfirmed === 1 ? 'orange' : 'blue' }}
                    size={30}
                />


            </div>

        </div>


        <div
            className='div-row'
            style={{
                padding: 10,
                paddingTop: 20
            }}
        >
            <div style={{ flex: 1 }} >
                <Link
                    to={`/p/${token}/account`}
                    style={{ color: 'blue' }}
                >
                    <IoPersonCircleOutline size={50} />
                </Link>
            </div>


            <div style={{ flex: 4 }}>
                <b>{userData.accountInfo.firstName} {userData.accountInfo.lastName}</b>
                <br />
                7801 NW 37 St
                <br />
                Suite ALANI{userData.accountInfo.alaniId}
                <br />
                Doral, FL, 33195-6503
            </div>
            <div style={{ flex: 1 }} >
                <Link
                    to={`/p/${token}/account`}
                    style={{ color: 'blue', padding: 10 }}
                >
                    <IoChevronForwardSharp size={20} />
                </Link>
            </div>
        </div>
        {
            userData.accountInfo.shippingAddressConfirmed === 0
            &&
            <button className='div-row'
                style={{
                    backgroundColor: 'red',
                    borderRadius: 5,
                    width: 250,
                    padding: 10,
                    margin: 'auto',
                    justifyContent: 'center',
                    borderStyle: 'none',
                }}

                onClick={() => navigate(`/p/${token}/account?addr=1`)}
            >

                <IoNotifications size={20} style={{ fill: '#fff', marginRight: 10 }} />
                <div style={{
                    color: '#fff',
                }}>
                    Debes Confirmar tu
                    <br />
                    Dirección de Entregas
                </div>

            </button>
        }




        <hr style={{ margin: 0 }} />

        <div style={{
            paddingTop: 10,
            width: '100%',
        }}>
            <Paquetes
                token={token}
            />
        </div>


    </div>
}


const Page = () => {

    let token = useParams().token;
    const { search } = useLocation();

    // eslint-disable-next-line
    const [cookies, setCookie] = useCookies(['alani360']);
    const [token_phone, setTokenPhone] = React.useState(null);
    let confirm = (queryString.parse(search)).confirm;

    const dispatch = useDispatch();
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        fetching,
        phone,
        userData
    } = dataReducer;


    /* eslint-disable */
    React.useLayoutEffect(() => {


        let _phone = (cookies && cookies.alani360 && ("+" + cookies.alani360.phoneNumber));

        dispatch({
            type: "PHONE_SET",
            phone: _phone
        });


        dispatch({
            type: "USER_DATA_FETCH",
            token,
            confirm,
            phone
        });

    }, [token]);

    React.useLayoutEffect(() => {

        if (userData && !userData.success & token !== "") {
            tokenValidate(token).then(res => {
                if (res.success) {
                    dispatch({
                        type: "PHONE_SET",
                        phone: res.tel
                    });
                    setTokenPhone(res.tel)
                }
            })
        } /*else {
            //TODO: remove this
            console.log("navigate")
            if (token) {
                navigate(`/p/${token}?${Date.now()}=${Date.now()}`);
            }
        }*/

    }, [userData]);
    /* eslint-enable */
    return (
        <div><Menu />
            <header
                style={{
                    backgroundColor: "#FF9400"
                }}
            >

                <div style={{
                    margin: "auto",
                    maxWidth: 650,
                    minWidth: 350,
                    flexDirection: "row",
                    display: 'flex',
                    justifyContent: "space-between",
                    alignContent: "center",
                    alignItems: "center",

                }}>
                    <div style={{
                        width: 50
                    }} ></div>

                    <div style={{ position: 'relative' }}>
                        <img alt='ALANI 360 logo'
                            src='/images/alani_logos_vertical_horizontal_orange_white.svg#svgView(viewBox(320, 90, 260, 70))'
                            style={{
                                width: 120, height: 60
                            }}
                        />
                        {process.env.REACT_APP_API_LANG === 'br' &&
                            <img
                                alt="Brazilian flag"
                                src='/images/brazil.svg'
                                style={{
                                    width: 25,
                                    position: 'absolute',
                                    right: -15,
                                    bottom: 5

                                }}
                            />
                        }
                    </div>
                    <button
                        onClick={() => {
                            let _phone = (cookies && cookies.alani360 && ("+" + cookies.alani360.phoneNumber))
                            dispatch({
                                type: "PAGE_SECTION_SET",
                                section: "main"
                            })
                            dispatch({
                                type: "USER_DATA_FETCH",
                                phone: _phone,
                                token
                            });
                        }
                        }
                        style={{
                            backgroundColor: 'transparent',
                            borderWidth: 0,
                            width: 70
                        }}
                    >
                        <IoReload
                            size={25}
                        />
                    </button>

                </div>
            </header>

            <main style={{
                margin: "auto",
                maxWidth: 650,
                minWidth: 350,
                fontFamily: 'Montserrat'
            }}>
                {fetching ?
                    <Loading />
                    :
                    <PageInfo
                        token={token}
                        token_phone={token_phone}
                    />
                }

            </main>


        </div>
    );
}


export default Page;

