import React from 'react';
//import { useParams, useNavigate } from "react-router-dom";

import { ThreeDots } from "react-loader-spinner";

import registrationAPI from "../../api/registrationAPI";



const BotonConfirmarCorreo = ({
    accountId,
    email,
    fnAfterSend
}) => {


    const fnCorreoConfirmar = async () => {
        if (!window.confirm(`Deseas que te reenviemos el correo de confirmación a ${email} ?`)) {
            return;
        }

        setFetching2(true);

        let res = await registrationAPI.emailValidationSend({
            accountId
        });
        setFetching2(false);
        if (res.success) {

            if (fnAfterSend) {
                fnAfterSend(res);
            }
        } else {
            alert(res.message)
        }


    }

    const [fetching2, setFetching2] = React.useState(false);
    return <button
        disabled={fetching2}
        onClick={() => fnCorreoConfirmar()}
        style={{
            backgroundColor: 'transparent',
            textDecoration: 'underline',
            borderStyle: 'none',
            fontSize: 15,
            margin: 10,
            marginTop: 5,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}
    >

        {fetching2 ?
            <ThreeDots
                type="ThreeDots"
                color="red"
                height={10}
                width={150}
                timeout={10000}
            />
            :
            <span style={{
                color: 'blue'
            }}>Reenviar Correo de Confirmación</span>
        }

    </button>
}


export default BotonConfirmarCorreo;