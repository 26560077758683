import React, { useState } from 'react';

import API from "../../api/emailsAPI";

import Header from '../../app/AdminComponents/Header';

import { useNavigate, useSearchParams } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
///import { useParams } from "react-router-dom"; 

import Styles from "../../styles";
import moment from 'moment-timezone';



function toTitleCase(str) {
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}
const EmailListRFQ = ({
    list,
}) => {

    const navigate = useNavigate();

    return <div>
        {
            list?.map((email, ix) => {

                return <button
                    key={ix}
                    style={{
                        backgroundColor: ix % 2 ? '#e0e0e0' : '#fff',
                        borderStyle: 'none',
                        width: '100%',
                        padding: 5,

                    }}
                    onClick={() => navigate(`/admin/emails/${email.emailId}/${email.purchaseOrderId}`)}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start'
                        }}
                    >
                        <span
                            style={{
                                color: '#3482B3',
                                fontWeight: 600
                            }}
                        >{toTitleCase(moment(email.dateTimeCreated).tz('America/New_York').format('MMMM DD HH:mm'))}</span>
                        <span>{email.customerName}</span>
                        <span>{email.userName} &lt;{email.sender}&gt;</span>
                        <span>{email.subject}</span>
                        <div style={{
                            marginTop: 10,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}>
                            <div style={
                                {
                                    ...styles.badge_count,
                                    width: 80,
                                    fontSize: 12
                                }
                            }>{email.itemCount} items</div>

                            <span style={{
                                marginLeft: 10
                            }}>
                                {email.customerReference}
                            </span>
                        </div>



                    </div>
                </button>
            })
        }

    </div>
}

const EmailListHAPI = ({
    list,
}) => {

    const navigate = useNavigate();
    const [selectedTab, setSelectedTab] = React.useState(2)

    const Tab = ({
        label,
        value
    }) => {

        return <button
            onClick={() => setSelectedTab(value)}
            style={{
                margin: 5,
                backgroundColor: value === selectedTab ? "#c0c0c0" : "#fff",
                borderWidth: 1,
                borderColor: '#000',
                borderStyle: 'solid',
                borderRadius: 0,
                width: 100
            }}
        >
            {label}
        </button>
    }

    const Item = ({
        label,
        value
    }) => <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: 5 }}>
            <span style={{ fontSize: 12, color: '#488EBB' }}>{label}</span>
            <span>{value}</span>
        </div>

    return <div>



        <div>
            <Tab
                label={"Sent"}
                value={1}
            />
            <Tab
                label={"Received"}
                value={2}
            />

        </div>


        <div style={{
            marginBottom: 10,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end'
        }}>

            <Styles.ButtonSecondary
                onClick={() => navigate("/admin/hapi")}
            >
                Request Human API
            </Styles.ButtonSecondary>
        </div>



        {
            list.filter(email => (selectedTab === 1) ? email.outgoing : !email.outgoing).map((email, ix) => {


                return <button
                    key={ix}
                    style={{
                        backgroundColor: ix % 2 ? '#e0e0e0' : '#fff',
                        borderStyle: 'none',
                        width: '100%',
                        padding: 5,

                    }}
                    onClick={() => navigate(`/admin/emailh/${email.emailId}`)}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start'
                        }}
                    >

                        <span
                            style={{
                                color: email.outgoing ? '#FF0000' : "blue",
                                fontWeight: 600
                            }}
                        >{toTitleCase(moment(email.dateTimeCreated).tz('America/New_York').format('MMMM DD HH:mm'))}</span>

                        <span
                            style={{
                                backgroundColor: '#c0c0c0',
                                padding: 5,
                                borderRadius: 10,
                                width: 70,
                                fontSize: 12,
                                margin: 5
                            }}
                        >{email.request_code}</span>

                        <Item
                            value={email.senderWithFormat}
                            label="Sender"
                        />
                        <Item
                            value={email.recipient}
                            label="To"
                        />
                        <Item
                            value={email.subject}
                            label="Subject"
                        />
                        <span style={
                            {
                                ...styles.badge_count_2,
                                fontSize: 12,
                                backgroundColor: email.outgoing ? '#FF0000' : "blue",
                            }
                        }>{email.humanAPI_itemCount || email.itemCount || "No"} items</span>

                    </div>
                </button>
            })
        }

    </div>
}
const TITLE = {
    "emails_rfq": "RFQ Emails",
    "emails_hapi": "Human API Emails",

}
const Emails = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    let Section = searchParams.get("s");


    const navigate = useNavigate();
    const [fetching2, setFetching2] = useState(false);
    const [emailList_rfq, setEmailList_rfq] = useState([]);
    const [emailList_hapi, setEmailList_hapi] = useState([]);

    const [section, setSection] = useState(Section || "emails_rfq");

    const EmailMenu = () => {
        return <div
            style={{
                display: 'flex',
                flexDirection: 'row',
            }}
        >

            <button
                style={section === "emails_rfq" ? styles.menuButton__selected : styles.menuButton}
                onClick={() => {
                    setSection("emails_rfq");
                    setSearchParams({ s: "emails_rfq" });
                }} >RFQ</button>

            <button
                style={section === "emails_hapi" ? styles.menuButton__selected : styles.menuButton}
                onClick={() => {
                    setSection("emails_hapi");
                    setSearchParams({ s: "emails_hapi" });
                }} >Human API</button>

        </div>
    }

    let fnListGet = async () => {
        setFetching2(true);
        let res = await API.fetch();

        setFetching2(false);

        setEmailList_rfq(res.emails_rfq);
        setEmailList_hapi(res.emails_hapi);
    }

    /* eslint-disable */
    React.useEffect(() => {
        fnListGet();
    }, []);
    /* eslint-enable */

    if (!localStorage.getItem("token")) {
        navigate("/login")
        return null
    }

    return (
        <div>

            <Header />

            <Styles.PageTitle>{TITLE[section]}</Styles.PageTitle>

            <main style={{
                margin: "auto",
                maxWidth: 500,
                minWidth: 350,
                fontFamily: 'Montserrat',

            }}>

                {fetching2 ?
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: 50
                    }}>
                        <ThreeDots
                            type={"ThreeDots"}
                            color="#FF9400"
                            height={20}
                            width={70}
                            timeout={5000}
                        />
                    </div>
                    :

                    <div>
                        <div style={{
                            marginTop: 10,
                            marginBottom: 10
                        }}>

                            <EmailMenu />


                        </div>
                        {
                            {
                                "emails_rfq": <EmailListRFQ
                                    list={emailList_rfq}
                                />,
                                "emails_hapi": <EmailListHAPI
                                    list={emailList_hapi}
                                />,

                            }[section]
                        }
                    </div>


                }


            </main>
        </div>
    );
}


export default Emails;


const styles = {


    menuButton: {
        borderStyle: "none",
        backgroundColor: 'transparent',
        marginRight: 10
    },
    menuButton__selected: {
        borderStyle: 'none',
        backgroundColor: 'transparent',
        borderBottomStyle: 'solid',
        borderBottomColor: 'red',
        borderBottomWidth: 2,
        marginRight: 10
    },
    badge_count: {
        backgroundColor: 'blue',
        color: "#fff",
        padding: 5,
        borderRadius: 10,
    },
    badge_count_2: {
        backgroundColor: '#FF0000',
        color: "#fff",
        padding: 5,
        borderRadius: 10,
        minWidth: 80
    },


}