
import { call, put, takeLatest, select } from 'redux-saga/effects';

import pageUserAPI from "../api/pageUserAPI";

export const getPhone = (state) => state.dataReducer.phone;


function* fn_userAccountFetch(action) {
    try {

        let phone = yield select(getPhone);

        if (!action.no_fetch_indicator) {
            yield put({
                type: "FETCHING",
                fetching: true,
            });
        }
        const data = yield call(pageUserAPI.fetch, action, (action.phone || phone), action.accountId);

        yield put({ type: "USER_DATA_SET", data });


    } catch (e) {
        console.warn(e)
        yield put({ type: "fn_userAccountFetch", message: e.message });
    }
}



export default function* rootSaga() {

    yield takeLatest('USER_DATA_FETCH', fn_userAccountFetch);


}

