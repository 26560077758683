/*
import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import createSagaMiddleware from 'redux-saga'
import sectionReducer from './features/siteSectionSlice'; 


import rootSaga from './sagas';

export const store = configureStore({
    reducer: {
        section: sectionReducer 
    }

    ,applyMiddleware(sagaMiddleware)

})


setupListeners(store.dispatch);

*/

import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga'
import reducers from './reducers';
import rootSaga from './sagas'
const sagaMiddleware = createSagaMiddleware()
export default createStore(
    reducers
    , composeWithDevTools(applyMiddleware(sagaMiddleware))
)
sagaMiddleware.run(rootSaga)