import React from 'react'

import { useTranslation, Trans } from 'react-i18next';
import Menu from '../app/components/Menu';

///import { ThreeDots } from "react-loader-spinner";
///import { useParams } from "react-router-dom";
///import pageUserAPI from '../api/pageUserAPI';
///import { ThreeDots } from "react-loader-spinner";
import moment from 'moment-timezone';
// eslint-disable-next-line
import localization from 'moment/locale/es'

import { useSelector } from 'react-redux';
import AlaniHeaderLogo from '../app/components/AlaniHeaderLogo';

moment.locale('es');

const Help = () => {


    const { t } = useTranslation();

    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        CEL_WHATSAPP,
        NUMERO_WHATSAPP,
        userData
    } = dataReducer;

    const [section, setSection] = React.useState(1)

    /* eslint-disable */
    React.useEffect(() => {
    }, []);
    /* eslint-enable */


    return (
        <div>
            <Menu />
            <header
                style={{
                    backgroundColor: "#FF9400"
                }}
            >
                <div style={{
                    margin: "auto",
                    maxWidth: 650,
                    minWidth: 350,
                    flexDirection: "row",
                    display: 'flex',
                    justifyContent: "space-between",
                    alignContent: "center",
                    alignItems: "center",

                }}>
                    <div style={{ width: 80 }} />
                    <AlaniHeaderLogo />
                    <div style={{ width: 100 }} />
                </div>
            </header>


            <main style={{
                margin: "auto",
                maxWidth: 650,
                minWidth: 350,
                fontFamily: 'Montserrat',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column'

            }}>

                <h1>Ayuda</h1>

                <div
                    style={{
                        width: '100%'
                    }}
                >
                    <hr style={{ margin: 0 }} />
                </div>

                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    width: '100%',
                    fontSize: 12,
                    paddingTop: 5,
                    paddingBottom: 5,
                    backgroundColor: '#f8f8f8',
                    flexWrap: 'wrap'
                }}>
                    <button
                        style={styles.menuItem}
                        onClick={() => setSection(1)}
                    >
                        <span style={section === 1 ? styles.menuItem__selected : styles.menuItem__notSelected}>
                            ALANI
                        </span>
                    </button>
                    <button
                        style={{
                            margin: 0,
                            padding: 5,
                            borderStyle: 'none',
                            backgroundColor: 'transparent'
                        }}
                        onClick={() => setSection(2)}

                    >
                        <span style={section === 2 ? styles.menuItem__selected : styles.menuItem__notSelected}>
                            <Trans t={t}>Membresía</Trans>
                        </span>
                    </button>
                    <button
                        style={styles.menuItem}
                        onClick={() => setSection(3)}
                    >
                        <span style={section === 3 ? styles.menuItem__selected : styles.menuItem__notSelected}>
                            PAGO
                        </span>
                    </button>
                    <button
                        style={styles.menuItem}
                        onClick={() => setSection(4)}

                    >
                        <span style={section === 4 ? styles.menuItem__selected : styles.menuItem__notSelected}>
                            ENTREGA
                        </span>
                    </button>
                    <button
                        style={styles.menuItem}
                        onClick={() => setSection(5)}
                    >
                        <span style={section === 5 ? styles.menuItem__selected : styles.menuItem__notSelected}>
                            CONTACTO
                        </span>
                    </button>
                    <button
                        style={styles.menuItem}
                        onClick={() => setSection(6)}
                    >
                        <span style={section === 6 ? styles.menuItem__selected : styles.menuItem__notSelected}>
                            PRIVACIDAD
                        </span>
                    </button>
                </div>
                <div
                    style={{
                        width: '100%'
                    }}
                >
                    <hr style={{ margin: 0 }} />
                </div>

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignContent: 'center',
                        flexDirection: 'column',
                        padding: 15

                    }}
                >

                    {{
                        "1": <div
                            style={{
                                textAlign: 'justify'
                            }}
                        >


                            <div style={{
                                width: 100,
                            }}>
                                <img
                                    src="/images/alani_logos_vertical_horizontal.svg#svgView(viewBox(0, 240, 230, 200))"
                                    alt="ALANI 360 Logo"
                                    style={{
                                        width: 86,
                                        height: 80,
                                        float: "left",
                                        marginRight: 20
                                    }}
                                />
                            </div>

                            <Trans t={t}>HelpAlani1</Trans>

                            <br />

                            <img alt='ALANI 360 logo'
                                src='/images/alani_logos_vertical_horizontal_orange_white.svg#svgView(viewBox(320, 90, 260, 70))'
                                style={{
                                    width: 210,
                                    height: 90, float: "right"
                                }}
                            />

                        </div>,
                        "2": <div
                            style={{
                                textAlign: 'justify'
                            }}
                        >
                            <Trans t={t}>MembresiaDetails</Trans>
                        </div>,
                        "3": <div
                            style={{
                                textAlign: 'justify'
                            }}
                        >

                            Puedes pagar con Visa, Mastercard, American Express, PayPal y ALANI Digital Account.

                        </div>,
                        "4": <div
                            style={{
                                textAlign: 'justify'
                            }}
                        >
                            Ingresa tu dirección de <b>Alani 360</b> como "Dirección de envío" como se muestra a continuación, siempre que compres en tiendas online de los Estados Unidos.
                            <br /><br />

                            {userData && userData.accountInfo && userData.accountInfo.firstName ?
                                <b>{userData.accountInfo.firstName + ' ' + userData.accountInfo.lastName}</b>

                                :

                                "Nombre"
                            }<br />
                            Email<br />
                            Teléfono: 305-592-0839 <br />
                            Direccion Linea 1: 7801 NW 37 St. <br />
                            Direccion Linea 2: Suite ###<br />
                            Ciudad: Doral<br />
                            Estado: Florida<br />
                            Código postal 33195-6503<br />
                            <br />
                            <b>Importante</b>: ingresa todos los 9 números del código postal para que no se te cobre el impuesto sobre las ventas

                        </div>,
                        "5": <div
                            style={{
                                textAlign: 'justify'
                            }}
                        >

                            Whatsapp <a href={CEL_WHATSAPP} >{NUMERO_WHATSAPP}</a>
                        </div>,
                        "6": <div
                            style={{
                                textAlign: 'justify'
                            }}
                        >

                            Alani 360 y los sitios afiliados solicitarán información personal de los usuarios como parte del proceso de registración. Esta información que incluye nombre, dirección de correo electrónico, país de origen e intereses personales, se usará para proveerte un número de membresía "Alani360" la cual te identificará como usuario del servicio.

                            <br /><br />

                            Alani 360 y/o sus socios también podrá solicitar información personal en ocasiones diversas a saber: concursos, promociónes, anuncio clasificados, listados de un sitios Web, compras a través de Alani 360, recepción de informes de algún problema con nuestros sitios o servicios. También podremos hacer encuestas para evaluar internamente el tráfico y los servicios que ofrecemos. Alani 360 te permitirá en la medida de lo posible revisar y verificar que esta información sea la correcta.

                            <br /><br />
                            En Alani 360 se podrá hacer uso de "cookies" para controlar y almacenar los datos personales. Un "cookie" es un pequeño set de datos que nuestro servidor Web envía a tu navegador. El "cookie" se almacena en el disco duro de tu computadora. Todos los sitios de Alani 360 en los que se te solicite que te conectes usando tu número de cuenta Alani 360, necesitas que aceptes dichos cookies. Los cookies se utilizan usualmente para recordarnos quién eres y poder acceder la información de tu cuenta (almacenada en nuestros equipos) para así ofrecerte un servicio más personalizado, también usamos estos cookies para calcular el tamaño de nuestra audiencia, para medir algunos parámetros de tráfico, conocer las áreas de la red de websites de Alani 360 que son visitadas. Tienes la posibilidad de aceptar o no los cookies al cambiar las preferencias de tu navegador. Puedes reprogramar tu navegador para que no acepte cookies o para que te indique cuando se te está enviando una a tu computadora. Si escoges no aceptar cookies, tu experiencia al visitar nuestro website puede verse disminuida y algunos de los servicios y opciones pueden no trabajar como se espera.

                            <br /><br />
                            Alani360 podrá en algunos casos suministrar esta información a otras compañías relacionadas con nosotros (uso secundario) para ofrecerte bienes y servicios adicionales.
                            <br /><br />

                            Alani360 y su red de sitios en la web, usarán tecnología de avanzada para la protección de tu información. Esta tecnología denominada Secure Sockets Layer (SSL), encripta, codifica y previene la intercepción de la información suministrada por el Internet, incluyendo números de tarjeta de crédito y direcciones de correos electrónicos.

                            <br /><br />
                            Es importante que tengas en cuenta que en algunos casos los sitios web que tienen enlaces con Alani 360 y los anunciantes en nuestra red, podrían solicitarte información personal. Alani 360 no se responsabiliza del uso que estos sitios le den a la información que les autorices.

                            <br /><br />
                            En concordancia con lo establecido en nuestros términos y condiciones, Alani 360 podrá liberar información personal de algún (os) usuario(s) cuando la Ley lo requiere o en consideración y sin prejuicio alguno de que tal acción sea necesaria para dar cumplimiento a los reglamentos de la ley y/o para proteger y/o defender las operaciones, los derechos de propiedad intelectual de Alani360, de su red de websites y/o de sus usuarios.

                            <br /><br />
                            Si tuvieras alguna pregunta con relación a nuestra política de privacidad o si consideras que alguno de los sitios de nuestra red no cumple con los principios aquí descritos, por favor escríbenos a:

                            <br /><br />
                            Merx US, LLC<br />
                            7801 N.W. 37th Street<br />
                            Miami, Florida 33195-6503<br />
                        </div>,

                    }[section]
                    }

                </div>

            </main>
        </div>
    );
}


export default Help;

const styles = {
    menuItem: {
        margin: 0,
        padding: 5,
        borderStyle: 'none',
        backgroundColor: 'transparent'
    },
    menuItem__notSelected: {
        textDecoration: 'underline'
    },
    menuItem__selected: {
        color: 'blue',
        fontWeight: 'bold',
    }
}