import React from 'react';
import moment from 'moment-timezone';
// eslint-disable-next-line
import localization from 'moment/locale/es'

import {
    IoWarning
} from "react-icons/io5";


function toTitleCase(str) {
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}

const POLinks = ({ access_tokens }) => {

    return <div>
        <h2 style={{ margin: 0 }}>Link to approve items</h2>
        {access_tokens?.map((token, ix) => {
            return <a rel="noopener noreferrer" key={ix} href={`https://alani360.app/po/${token.token}`} target="_blank" >{`https://alani360.app/po/${token.token}`}</a>
        })}
    </div>
}


const PoSummary = ({
    po,
    showDetails,
    itemsPendingPO
}) => {



    return <div
        style={{
            padding: 10
        }}
    >

        <div
            style={{

                display: 'flex',
                flexDirection: 'column',


            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 6
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                    }}
                >
                    <span><b>{po.customerReference}</b></span>
                    <span><b>{po.customerName}</b></span>
                </div>




                <span>{toTitleCase(moment(po.poCreateDate).tz('America/New_York').format('MMMM DD HH:mm'))}</span>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}>
                    <span style={{ marginRight: 10 }}>{po.itemCount} parts</span>
                    {po.itemCount === po.apiLookedUp
                        ?
                        <span style={{ backgroundColor: '#000', color: '#fff', width: 80, textAlign: 'center', borderRadius: 5 }}>Done</span>
                        :
                        <span style={{ backgroundColor: 'red', color: '#fff', width: 120, textAlign: 'center', borderRadius: 5 }}>{po.itemCount - po.apiLookedUp} pending</span>
                    }

                </div>



            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    flex: 2,
                    marginLeft: 20,
                }}
            >
                {po.itemsCount && po.itemsCount.map((item, ix2) => {

                    return <div key={`${ix2}`} style={{
                        flexDirection: 'row',
                        display: 'flex',

                    }}>
                        <div style={{
                            width: 120,
                            color: item.isQuoted === 0 ? 'red' : '#000'
                        }}>{item.statusStr}</div>


                        <div style={{
                            width: 70,
                            textAlign: "right",
                            color: item.isQuoted === 0 ? 'red' : '#000'
                        }}>{item.items}</div>


                        {item.status === 2 && itemsPendingPO.length > 0 &&
                            <div
                                style={{
                                    textAlign: "right",
                                    fontSize: 15,
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginLeft: 25,
                                    color: 'red',
                                }}
                            >

                                <IoWarning size={18} style={{ fill: 'red', marginRight: 5 }} />
                                No PO {itemsPendingPO[0] && itemsPendingPO[0].items}
                            </div>

                        }
                    </div>
                })}

            </div>

        </div>

        {showDetails && <div style={{
            marginTop: 10
        }}>
            <POLinks
                access_tokens={po.access_tokens}
            />
        </div>
        }

        {showDetails &&
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <h3 style={{ margin: 0, padding: 0, marginTop: 15 }}>Buyer</h3>

                <span>{po.userName}</span>
                <span>{po.userEmail}</span>
                <span>{po.userPhone}</span>

            </div>
        }
    </div>
}

export default PoSummary;