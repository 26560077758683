import React from 'react'
import {
    useParams,
    useNavigate
} from "react-router-dom";

import { useCookies } from 'react-cookie';
import {  ThreeDots } from "react-loader-spinner";
import AccountInfoConfirmActions from "../app/components/AccountInfoConfirmActions";

import moment from 'moment-timezone';
// eslint-disable-next-line
import localization from 'moment/locale/es'

import { useSelector, useDispatch } from 'react-redux';

moment.locale('es');

const AccountUpdateConfirm = () => {

    let token = useParams().token;

    const navigate = useNavigate();
    const cookies = useCookies(['alani360']);
    const dispatch = useDispatch();
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        userData
    } = dataReducer;



    React.useEffect(() => {

        const refreshData = () => dispatch({
            type: "USER_DATA_FETCH",
            token,
            phone: (cookies[0] && cookies[0].alani360 && ("+" + cookies[0].alani360.phoneNumber))
        });
        refreshData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);


    if (userData) {
        if (userData.accountInfo.updateRequest_email == null
            &&
            userData.accountInfo.updateRequest_phone == null) {

            navigate(`/p/${token}/account`);
        }
    }
    return (
        <div>

            <header
                style={{
                    backgroundColor: "#FF9400"
                }}
            >
                <div style={{
                    margin: "auto",
                    maxWidth: 1200,
                    minWidth: 350,
                    flexDirection: "row",
                    display: 'flex',
                    justifyContent: "space-between",
                    alignContent: "center",
                    alignItems: "center",

                }}>
                    <div style={{ width: 80 }} />
                    <img alt='ALANI 360 logo'
                        src='/images/alani_logos_vertical_horizontal_orange_white.svg#svgView(viewBox(320, 90, 260, 70))'
                        style={{
                            width: 120, height: 60
                        }}
                    />
                    <div style={{ width: 100 }} />
                </div>
            </header>


            <main style={{
                margin: "auto",
                maxWidth: 1200,
                minWidth: 350,
                fontFamily: 'Montserrat',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column'

            }}>

                <h1>Cambios Solicitados</h1>

                <div
                    style={{
                        width: '100%',
                        marginBottom: 20
                    }}
                >
                    <hr style={{ margin: 0 }} />
                </div>


                <a href={`/p/${token}/account`}>Regresar</a>

                {
                    (
                        userData != null
                        && userData.accountInfo != null
                    )
                        ?

                        <AccountInfoConfirmActions
                            accountInfo={userData.accountInfo}
                        />

                        :
                        <div>
                            <ThreeDots
                                type="ThreeDots"
                                color="#FF9400"
                                height={30}
                                width={50} 
                            />
                        </div>
                }

            </main>
        </div >
    );
}


export default AccountUpdateConfirm;
