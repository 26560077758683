const token = localStorage.getItem("token");

const API = {

    apiStatsFetch: (args) => {

        ///AlaniPO/poGet/index.js
        //CALL purchaseOrderGet( ? )

        return new Promise((resolve, reject) => {
            let {
                rfqId,
                statType
            } = args;


            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/admin/api/stats?statType=${statType}`;

            if (rfqId) {
                url += `&rfqId=${rfqId}`
            }
            console.log(url)
            var headers = new Headers();
            //const token = Cache.getItem('Merx:authtoken');
            headers.append("Authorization", token);
            headers.append("x-api-key", process.env.REACT_APP_API_KEY);
            fetch(url, {
                headers,
                method: 'GET',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    reject(err);
                });
        })
    },


}


export default API;