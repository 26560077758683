import React, { useState, useRef } from 'react'
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { Circles, ThreeDots } from "react-loader-spinner";
import moment from 'moment-timezone';
// eslint-disable-next-line
import localization from 'moment/locale/es'

//import { useDispatch, useSelector } from 'react-redux';

import Menu from '../app/components/Menu';
import {
    IoHelpCircle

} from "react-icons/io5";

import Select from "react-select";

import registrationAPI from "../api/registrationAPI";
import Styles from '../styles';
import AlaniHeaderLogo from '../app/components/AlaniHeaderLogo';

moment.locale('es');


const COUNTRY_CODES = [
    {
        "label": "USA",
        "value": "US",
        "code": 1
    },
    {
        "label": "MEXICO",
        "value": "MX",
        "code": 521
    },
    {
        "label": "COLOMBIA",
        "value": "CO",
        code: 57
    },
]


const tokenValidate = (token) => {
    //arn:aws:lambda:us-east-1:645160913840:function:ALANI_TOKEN_VALIDATE
    return new Promise((resolve, reject) => {

        let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/token/${token}`;
        var headers = new Headers();
        //headers.append("x-api-key", config.apiGateway.api_key);
        fetch(url, {
            headers,
            method: 'GET',
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (jsonResponse) {
                resolve(jsonResponse);
            }).catch(err => {
                reject(err);
            });
    })
}

const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

const SignupForm = (props) => {

    /* eslint-disable */
    const [searchParams, setSearchParams] = useSearchParams();
    /* eslint-enable */
    const telephone_url = searchParams.get("tel");
    let token = useParams().token || "";

    const textInputPhone = useRef(null);
    const textInputEmail = useRef(null);
    const textInputPIN = useRef(null);

    const [name, setName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [fetching, setFetching] = useState(false);
    const [checkingToken, setCheckingToken] = useState(false);
    const [pin, setPin] = useState("");
    const [countryCode, setCountryCode] = useState("");
    const [callingCode, setCallingCode] = useState("");

    const [confirmSignUp, setConfirmSignUp] = useState(false)

    const navigate = useNavigate();

    const fnCrearCuenta = async () => {

        setFetching(true)
        let res_registration = await registrationAPI.accountCreate({
            phone: callingCode + phone,
            countryCode: countryCode.value,
            firstName: name.toString().trim(),
            lastName: lastName.toString().trim(),
            email: email.toString().toLowerCase().trim(),
            pin: pin.toString()
        });


        if (res_registration.success) {
            navigate(`/bienvenido/${res_registration.id}`);
        } else {

            setFetching(false);
            setConfirmSignUp(false);
            alert(res_registration.msg);
        }
    }

    const ConfirmSignUp = () => {

        return <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: 10,
                alignItems: 'center',
                alignContent: 'center',
                justifyContent: 'center'
            }}
        >

            <img alt='ALANI 360 logo'
                src='/images/alani_logos_vertical_horizontal_orange_white.svg#svgView(viewBox(320, 90, 260, 70))'
                style={{
                    width: 120, height: 60
                }}
            />

            <div
                style={{
                    width: 290,
                    marginBottom: 20,
                    textAlign: 'center',
                    paddingTop: 10,
                }}
            >
                <div style={{ margin: 5, color: 'blue', marginBottom: 10 }}>

                    Confirma que tus datos sean correctos, ya que recibirás
                    la información de nueva cuenta a tu correo electrónico
                    y tu celular

                </div>


                <div style={{ margin: 5 }}>{countryCode.label}<br />+{callingCode} {phone}</div>
                <div style={{ margin: 5 }}>{name} {lastName}</div>
                <div style={{ margin: 5 }}>{email}</div>
                <div style={{ margin: 5 }}> PIN {pin}</div>
            </div>
            <Styles.ButtonContinuar
                fetching={fetching}
                disabled={fetching}
                onClick={() => fnCrearCuenta()}
            >
                {fetching ?
                    <ThreeDots
                        type={"ThreeDots"}
                        color="#ffffff"
                        height={20}
                        width={50}
                    />
                    :
                    <span style={{
                        color: '#ffffff',
                    }}>Confirmar</span>
                }
            </Styles.ButtonContinuar>


            <button
                style={{
                    borderStyle: 'none',
                    marginTop: 20,
                    backgroundColor: 'transparent'
                }}
                disabled={fetching}
                onClick={() => setConfirmSignUp(false)}
            >
                {!fetching &&
                    <span style={{
                        color: 'red',
                        textDecoration: 'underline'
                    }}>Cancelar</span>
                }

            </button>
        </div>
    }

    const handleSubmit = async (evt) => {

        evt.preventDefault();

        if (countryCode === "") {
            return (alert("Seleccione un país"))
        }

        if (
            (phone || "").toString().trim() === ""

        ) {
            alert("Debe usar un número de celular válido");
            textInputPhone.current.focus();
            return;
        }
        let _email = email.toString().toLowerCase().trim();
        if (!validateEmail(_email)) {
            alert("Debe usar una direccion de correo electrónico válida");
            textInputPIN.current.focus();
            return;
        }
        if (pin.trim().length !== 4) {
            alert("Define un PIN de 4 carácteres para acceder tu cuenta");
            textInputPIN.current.focus();
            return;
        }

        setFetching(true);
        registrationAPI.phoneLookup({
            phone: callingCode + phone,
            email: _email
        }).then(r => {
            setFetching(false);
            if (r.id != null) {
                let _msg = "";

                if (r.emailIsRegistered) {
                    textInputEmail.current.focus();
                    _msg = `El correo "${_email}"\nya está registrado`;
                }
                if (r.phoneIsRegistered) {

                    textInputPhone.current.focus();
                    _msg += `\nEl número "${phone}"\nya está registrado, solicita un código de acceso para entrar a tu cuenta`;

                }

                if (_msg !== "") {
                    alert(_msg);
                    return;
                }

            } else {
                if (
                    lastName.toString().trim() === ""
                    ||
                    name.toString().trim() === ""
                    ||
                    email.toString().toLowerCase().trim() === ""

                ) {
                    alert("Todos los campos son obligatorios");
                    return;
                }

                setConfirmSignUp(true);
            }


        });









    }

    /* eslint-disable */
    React.useEffect(() => {
        if (telephone_url) {
            setPhone('+' + telephone_url)
        } else {
            if (token) {
                setCheckingToken(true)
                tokenValidate(token).then(r => {
                    setCheckingToken(false)
                    if (r.success) {
                        let _tel_complete = "+" + r.tel;
                        let _tel_without_country_code = _tel_complete.replace("+1", "").replace("+57", "").replace("+521", "");
                        let _country_dial_code = _tel_complete.replace(_tel_without_country_code, "");

                        //SET COUNTRY <SELECT ></SELECT>
                        let _COUNTRY_CODE = COUNTRY_CODES.find(e => e.code == _country_dial_code);
                        setCountryCode(_COUNTRY_CODE);
                        setCallingCode(_country_dial_code.replace("+", ""));
                        setPhone(_tel_without_country_code);
                    }
                })
            }
        }


    }, []);
    /* eslint-enable */


    if (confirmSignUp) {
        return <ConfirmSignUp />
    }


    if (checkingToken) {
        return <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 20,
            }}
        >
            <Circles
                color={"orange"}
            />
        </div>
    }

    return (<div>

        <hr />
        <form onSubmit={handleSubmit}
            style={{
                display: 'flex',
                flexDirection: 'column',
                margin: 'auto',
                width: 330,
                paddingTop: 10
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: 10,
                    alignItems: 'center',
                    alignContent: 'center',
                    justifyContent: 'center'
                }}
            >
                <label
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                    }}
                >
                    <div style={{
                        width: 150,
                        marginBottom: 10,
                        marginLeft: 30,
                        marginRight: 40
                    }}>
                        <Select
                            onChange={e => {
                                setCountryCode(e);
                                setCallingCode(e.code);
                            }}
                            value={countryCode}
                            placeholder={'Pais'}
                            options={COUNTRY_CODES}
                        >
                        </Select>
                    </div>
                    <span style={{ marginLeft: 40 }}>Celular</span>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div style={{ width: 30, marginRight: 5, textAlign: 'right', fontWeight: 'bold' }}>{callingCode ? "+" + callingCode : ""}</div>
                        <input
                            style={{ width: 120, marginRight: 5, marginLeft: 5 }}
                            value={phone}
                            disabled={fetching}
                            onChange={e => {
                                const value = e.target.value.replace(/\D/g, "");
                                setPhone(value)
                            }}
                            type="number"
                            autoFocus
                            ref={textInputPhone}
                        />
                    </div>

                </label>
            </div>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                <label
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        width: 160
                    }}
                >
                    Nombre
                    <input
                        type="text"
                        value={name}
                        onChange={e => setName(e.target.value)}
                    />
                </label>

                <label
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        width: 160,
                        marginLeft: 5
                    }}
                >
                    Apellido
                    <input
                        type="text"
                        value={lastName}
                        onChange={e => setLastName(e.target.value)}
                    />
                </label>
            </div>

            <div style={{
                marginTop: 10,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}>
                <label
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        width: 320
                    }}
                >
                    Correo Electrónico
                    <input
                        type="text"
                        value={email}
                        placeholder="email@ejemplo.com"
                        onChange={e => setEmail(e.target.value)}
                        ref={textInputEmail}
                        autoCapitalize='none'
                    />
                </label>
            </div>
            <div style={{
                marginTop: 10,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}>
                <label
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: 150,

                    }}
                >
                    <div
                        onClick={() => alert("Utiliza tu PIN para acceder tu cuenta")}
                        style={{
                            display: 'flex', flexDirection: 'row', alignItems: 'center',
                            marginBottom: 5
                        }}
                    >Crea tu PIN&nbsp;&nbsp;<IoHelpCircle /></div>
                    <input
                        style={{
                            width: 55
                        }}
                        value={pin}
                        maxLength={4}
                        ref={textInputPIN}
                        onChange={e => {
                            setPin(e.target.value.toUpperCase());
                        }}
                    />
                </label>
            </div>
            <div
                style={{
                    flex: 1,
                    padding: 20,

                    justifyContent: 'center',
                    alignItems: 'center',
                    alignContent: 'center',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <Styles.ButtonContinuar
                    fetching={fetching}
                    disabled={fetching}
                    type="submit"
                >
                    {fetching ?
                        <ThreeDots
                            color="#ffffff"
                            height={10}
                            width={50}
                        />
                        :
                        <span style={{ color: '#fff' }}>Continuar</span>
                    }

                </Styles.ButtonContinuar>



            </div>
        </form>
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignContent: 'center',
                alignItems: 'center',
            }}
        >
            <button
                style={{
                    backgroundColor: 'transparent',
                    borderStyle: 'none',
                    marginTop: 30,
                }}
                onClick={() => {
                    navigate(`/p/${token || ""}`)
                }}
            >
                <div style={{
                    textDecoration: 'underline',
                    width: 200
                }}>Haz clic aquí si ya estás registrado en Alani360</div>
            </button>
        </div>
    </div>);


}

const SignUp = () => {

    /*
    const dispatch = useDispatch();
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        fetching
    } = dataReducer;
    */

    /* eslint-disable */
    React.useEffect(() => {

    }, []);
    /* eslint-enable */


    return (
        <div>
            <Menu />
            <header
                style={{
                    backgroundColor: "#FF9400",
                }}
            >
                <div style={{
                    margin: "auto",
                    maxWidth: 1200,
                    minWidth: 350,
                    flexDirection: "row",
                    display: 'flex',
                    justifyContent: "space-between",
                    alignContent: "center",
                    alignItems: "center",

                }}>
                    <div style={{ width: 80 }} />
                    <AlaniHeaderLogo />
                    <div style={{ width: 100 }} />

                </div>
            </header>


            <main style={{
                margin: "auto",
                maxWidth: 1200,
                minWidth: 350,
                fontFamily: 'Montserrat'
            }}>

                <h1 style={{
                    textAlign: 'center'
                }}>Membresía ALANI360</h1>


                <SignupForm />
            </main>
        </div>
    );
}


export default SignUp;