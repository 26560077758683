import React from 'react'
import Menu from '../app/components/Menu'; 
import {
    useParams,  
    useSearchParams
} from "react-router-dom";

import Styles from '../styles'; 

import b2cAPI from "../api/b2c";


var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
 
});
const B2CBuyNowV2 = () => {
    /* eslint-disable */
    const [searchParams, setSearchParams] = useSearchParams();
    /* eslint-enable */
    const token = useParams().code;
    const [fetching, setFetching] = React.useState(true)
    const [buying, setBuying] = React.useState(false)
    const [data, setData] = React.useState({account: {},data:[{}]})
    
    const [ step, setStep ] = React.useState(1);
    


    const fnBuy = ({
        token
    }) => {
        return new Promise((resolve, reject) => {
 
            let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/account/b2c/wapQuote/${token}/buy`;
 
            var headers = new Headers(  { 'Content-Type': 'application/json' }   );

            fetch(url, {
                headers,
                method: 'POST',
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (jsonResponse) {
                    resolve(jsonResponse);
                }).catch(err => {
                    alert(err)
                    reject(err);
                });
        })
    }

    const fnFetchQuote = async ({
        token
    }) => { 
        let res = await b2cAPI.fetchQuote({
            token
        });
        console.log(res)
        return res
    }

    /* eslint-disable */
    React.useEffect(() => {
       
        setFetching(true);
        fnFetchQuote({
            token
        }).then( res =>  {
            setFetching(false); 
            setData(res)
        }); 
        
    }, []);
    React.useEffect(() => {
       
        setSearchParams({ step  });
         
    }, [step]);
    
/* eslint-enable */



    const Step1 = () => <div
        style={{
            display:  'flex',
            flexDirection: 'column',
            marginTop: 10,
            maxWidth: 350,
            textAlign:'center'
        }}
    >

        <div style={{ color: 'blue', textAlign: 'center', fontSize: 18 }}>{data?.data[0].name }</div>
        <br/>
        <div style={{ 
            fontWeight: 'bold', 
            textAlign: 'center', 
            fontSize: 22 ,
        }}
            >{ formatter.format(data?.data[0].msrp) }</div>
        <div style={{ fontWeight: 'bold', textAlign: 'center', fontSize: 22 }}>3 días</div> 
        <br/>
 
  
        {data?.account.imageURL !== null && <div><img 
            style={{ 
                width: 200,
                minHeight: 100,
                margin: 'auto',
                backgroundColor: '#c0c0c0',
                padding: 0
            }}
            alt=""
            src={data?.account.imageURL} width={200} /></div>
        } 
        
        <div style={{
            textAlign: 'center'
        }}>{data?.data[0].fitment === "size" ?
        
            <label>
            Size<br/>
            <select
                style={{
                    width: 50
                }}
            >
                <option>S</option>
                <option>M</option>
                <option>L</option>
            </select>
            </label>
            : 
            data?.data[0].fitment
        }</div>


        <br/>  
        <div> 
            {data.account.name}
            <br/>
            227 36th Street
            <br/>
            Suite {data.account.suite}
            <br/>
            Doral, FL, 33178 
        </div> 
        <br/>
        <div>

            <a target="_blank" rel="noopener noreferrer" href={  data.data[0].url}>URL</a>
        </div>
        <br/>

        
    </div>


    const Step2 = () => <div>
        <div
            style={{
                display:  'flex',
                flexDirection: 'column',
                marginTop: 10,
                marginBottom: 20,
                maxWidth: 350,
                textAlign:'center'
            }}
        >

            <div style={{ color: 'blue', textAlign: 'center', fontSize: 18 }}>{data?.data[0].name }</div>
            <br/>
            <div style={{ fontWeight: 'bold', textAlign: 'center', 
                fontSize: 22 ,
                textShadow: '2px 2px #FF9400'
        
            }}>{ formatter.format(data?.data[0].msrp) }</div>
            <div style={{ fontWeight: 'bold', textAlign: 'center', fontSize: 22 }}>3 días</div> 
            <br/>
            <div> 
                {data.account.name}
                <br/>
                227 36th Street
                <br/>
                Suite {data.account.suite}
                <br/>
                Doral, FL, 33178 
            </div>  
            <br/>
                    <div 
                    style={{
                        padding: 10,
                        textAlign: 'center',
                        marginBottom: 20,
                    }}
                >
                    Enviaremos un link de pago a tu celular
                    <br/>
                    Confirma el pago para colocar tu orden !
                    <br/> 
                </div>

                <Styles.ButtonMain 
                    style={{
                        width: 200,
                        margin: 'auto',
                        backgroundColor: buying ? '#000' : '#FF9400'
                    }}
                    disabled={buying}
                    onClick = {() => {
                       
                        setBuying(true);
                        fnBuy({
                            token
                        }).then( r => {
                            setStep(3) ;
                            setBuying(false);
                        });
                    }}
                
                >
                    {
                        buying ?
                        "Confirmando...."
                        :
                        "Confirmar"
                    }
                </Styles.ButtonMain>

        </div>
            
        </div>
  
 
    
        const Step3 = () => <div>
        <div
            style={{
                display:  'flex',
                flexDirection: 'column',
                marginTop: 10,
                maxWidth: 350,
                textAlign:'center'
            }}
        >

            <div style={{ color: 'blue', textAlign: 'center', fontSize: 18 }}>{data?.data[0].name }</div>
            <br/>
            <div style={{ fontWeight: 'bold', textAlign: 'center', 
                fontSize: 22 ,
                textShadow: '2px 2px #FF9400'
        
            }}>{ formatter.format(data?.data[0].msrp) }</div>
            <div style={{ fontWeight: 'bold', textAlign: 'center', fontSize: 22 }}>3 días</div> 
            <br/>
            <div> 
                {data.account.name}
                <br/>
                227 36th Street
                <br/>
                Suite {data.account.suite}
                <br/>
                Doral, FL, 33178 
            </div>  
            <br/>
                <div 
                    style={{
                        padding: 10,
                        textAlign: 'center', 
                    }}
                > 
                    Listo! 
                    <br/><br/>
                    Recuerda confirmar tu pago 
                    <br/>
                    en WhatsApp
                    <br/><br/><br/>
                    <a href={`/p/${data.account.urlToken}`}>Mi Cuenta</a>
                </div>
 
        </div>
            
        </div>
  




    return (
        <div
            style={{ 
                height: '100%'
            }}
        >
            <Menu />
            <header
                style={{
                    backgroundColor: "#FF9400"
                }}
            >
                <div style={{
                    margin: "auto",
                    maxWidth: 1200,
                    minWidth: 350,
                    flexDirection: "row",
                    display: 'flex',
                    justifyContent: "space-between",
                    alignContent: "center",
                    alignItems: "center",

                }}>
                    <div style={{ width: 80 }} />
                    <img alt='ALANI 360 logo'
                        src='/images/alani_logos_vertical_horizontal_orange_white.svg#svgView(viewBox(320, 90, 260, 70))'
                        style={{
                            width: 120, height: 60
                        }}
                    />
                    <div style={{ width: 100 }} />
                </div>
            </header>


            <main style={{
                margin: "auto",
                maxWidth: 1200,
                minWidth: 350,
                fontFamily: 'Montserrat',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column'

            }}>

              
                <div style={{ fontSize: 22, fontWeight: 'bold', padding: 10, color: '#FF9400' }}>Alani360</div>

                

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignContent: 'center',
                        flexDirection: 'column',
                        padding: 15,
                        paddingTop: 0

                    }}
                >   
                <div
                    style={{
                        textAlign: 'justify'
                    }}
                >

 
             
            
            {fetching ?
                    <div>
                      
                        <br/>
                        <br/>

                        <img alt='ALANI 360 logo'
                            src='/images/alani_logos_vertical_horizontal_orange_white.svg#svgView(viewBox(320, 90, 260, 70))'
                            style={{
                                width: 120, height: 60
                            }}
                        />
                        <br/>
                        <br/>
                        Actualizando...
                    </div>
                :
                <div>
                {
                    data !== undefined ? 
                        <div>
                            {step === 1 && <Step1/>}
                            {step === 2 && <Step2/>}
                            {step ===3 && <Step3/>}
                        </div>
                    :
                    <div>
                        Not found
                    </div>
                }
                </div>
            }
             
               
                
  
           
             
                </div>
               
            </div>

            </main>
            {!fetching && step !== 3 && !buying &&
            <footer
                    style={{
                        position: 'sticky',
                        bottom: 0,
                        padding: 10,
                        backgroundColor: '#c0c0c0',
                        display: 'flex', 
                        flexDirection: 'row'
                    }}
                >
                {step ===  2 &&
                    <Styles.ButtonSecondary style={{
                        width: 200,
                        margin: 'auto',
                        
                    }}
                    
                        onClick = {() => setStep(1) }
                    
                    >Regresar</Styles.ButtonSecondary>
                }

                {step === 1 && 
                    <Styles.ButtonMain style={{
                        width: 200,
                        margin: 'auto',
                        
                    }}
                    
                        onClick = {() => setStep(2) }
                    
                    >Continuar &gt;</Styles.ButtonMain>
                }

                </footer>
            }
        </div>
    );
}


export default B2CBuyNowV2;
 