import React from 'react'
import Header from '../../app/AdminComponents/Header';

///import { ThreeDots } from "react-loader-spinner";
///import { useParams } from "react-router-dom";
///import pageUserAPI from '../api/pageUserAPI'; 
import moment from 'moment-timezone';
// eslint-disable-next-line
import localization from 'moment/locale/es'


import Styles from "../../styles";


moment.locale('es');

const Home = () => {



    return (
        <div>

            <Header />
            <Styles.PageTitle>Dashboard</Styles.PageTitle>

            <main style={{
                margin: "auto",
                maxWidth: 400,
                minWidth: 350,
                fontFamily: 'Montserrat',

            }}>
                <div style={{
                    padding: 10,
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <br />
                    <a href="/admin/users">Businesses</a>
                    <br />
                    <a href="/admin/rfqs/">Requests for Quote</a>
                    <br />
                    <a href="/admin/pos/">Purchase Orders</a>
                    <br />
                    <a href="/admin/emails/">Emails</a>
                </div>
            </main>
        </div>
    );
}


export default Home;