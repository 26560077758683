import React from 'react'
import { useParams } from "react-router-dom";


///import { useCookies } from 'react-cookie';
///import Menu from '../app/components/Menu';

import { ThreeDots } from "react-loader-spinner";
///import { useParams } from "react-router-dom";
///import pageUserAPI from '../api/pageUserAPI'; 
import moment from 'moment-timezone';
// eslint-disable-next-line
import localization from 'moment/locale/es'

///import { useSelector, } from 'react-redux';

import purchaseOrderAPI from "../api/purchaseOrderAPI";
import Styles from "../styles";


moment.locale('es');

function toTitleCase(str) {
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}

const POQuote = () => {
    let quoteCode = useParams().quoteCode;
    let itemId = parseInt(useParams().itemId);
    ///let cookies = useCookies(['alani360']);

    /// const dataReducer = useSelector((state) => state.dataReducer);
    const [fetching2, setFetching2] = React.useState(true);
    const [purchaseOrder, setPurchaseOrder] = React.useState();

    /*
        const {
            userData,
        } = dataReducer;
    */
    let fnPOGet = async () => {
        setFetching2(true);
        let res = await purchaseOrderAPI.fetchPartNumber({
            ref: quoteCode,
            itemId
        });
        console.log("purchaseOrderAPI.fetchPartNumber", res)
        setFetching2(false);
        setPurchaseOrder(res);
    }

    /* eslint-disable */
    React.useEffect(() => {
        fnPOGet();
    }, []);
    /* eslint-enable */




    return (
        <div>

            <header
                style={{
                    backgroundColor: "#FF9400"
                }}
            >
                <div style={{
                    margin: "auto",
                    maxWidth: 1200,
                    minWidth: 350,
                    flexDirection: "row",
                    display: 'flex',
                    justifyContent: "space-between",
                    alignContent: "center",
                    alignItems: "center",

                }}>
                    <div style={{ width: 80 }} />
                    <img alt='ALANI 360 logo'
                        src='/images/alani_logos_vertical_horizontal_orange_white.svg#svgView(viewBox(320, 90, 260, 70))'
                        style={{
                            width: 120, height: 60
                        }}
                    />
                    <div style={{ width: 100 }} />
                </div>
            </header>


            <main style={{
                margin: "auto",
                maxWidth: 1200,
                minWidth: 350,
                fontFamily: 'Montserrat',
            }}>

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignContent: 'center',
                        flexDirection: 'column'
                    }}
                >


                    <h1>
                        Cotización {quoteCode}
                    </h1>

                </div>

                {fetching2 &&
                    <Styles.ContentDiv>
                        <ThreeDots
                            type="ThreeDots"
                            color="#FF9400"
                            height={10}
                            width={150}
                            timeout={10000}
                        />
                    </Styles.ContentDiv>
                }

                {!fetching2 && purchaseOrder &&
                    <>
                        <div style={{
                            padding: 10,
                            borderTopStyle: "solid",
                            borderTopWidth: 1,
                            borderTopColor: '#000'
                        }}>

                            <Styles.ContentDiv>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: 380
                                }}>

                                    <div style={styles.row}>
                                        <span style={{
                                            fontWeight: 'bold',
                                            color: '#FF9400'
                                        }}>{purchaseOrder.po.customerName}</span>

                                        <span>{purchaseOrder.po.userName}</span>
                                    </div>

                                    <div style={styles.row}>
                                        <span style={{
                                            fontWeight: 'bold',
                                            color: '#FF9400'
                                        }}>
                                            {purchaseOrder.po_items[0].c_name}
                                        </span>
                                        <span>
                                            {purchaseOrder.po_items[0].c_cel}
                                        </span>
                                    </div>

                                    <div style={styles.row}>

                                        <span>{toTitleCase(moment(purchaseOrder.po.poCreateDate).tz('America/New_York').format('MMMM DD HH:mm'))}</span>
                                    </div>

                                    <div style={{
                                        padding: 5,
                                        color: '#fffff',
                                        backgroundColor: '#FF9400'
                                    }}>
                                        La siguiente parte ha sido solicitada, por favor confirme
                                        que es la correcta.
                                    </div>

                                    {[
                                        ...purchaseOrder.po_items,
                                        ...purchaseOrder.po_items_same_cel
                                    ].map((e, ix) => {


                                        return <div
                                            key={ix}
                                            style={styles.row}
                                        >
                                            <div
                                                style={{ ...styles.row, ...{ width: '100%', backgroundColor: ix % 2 ? '#c0c0c0' : '#fff' } }}
                                            >

                                                <div
                                                    style={styles.col}
                                                >
                                                    <span>{e.ref1}</span>
                                                    <span>{e.ref2}</span>
                                                    <span>{e.ref3}</span>
                                                    <span>{e.ref4}</span>
                                                    <span>{e.ref5}</span>
                                                </div>
                                                {e.quotedTotal !== null ?
                                                    <Styles.ButtonMain
                                                        style={{
                                                            margin: 'auto'
                                                        }}
                                                    >
                                                        Aprobar
                                                        </Styles.ButtonMain>
                                                    :
                                                    <Styles.ButtonSecondary
                                                        style={{
                                                            margin: 'auto'
                                                        }}
                                                    >
                                                        Falta Info
                                                        </Styles.ButtonSecondary>
                                                }

                                            </div>


                                        </div>
                                    })}
                                </div>





                            </Styles.ContentDiv>




                        </div>

                    </>
                }


            </main>
        </div >
    );
}


export default POQuote;


const styles = {
    col: {
        display: 'flex',
        flexDirection: 'column',
        padding: 5,
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        minWidth: 330,
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 5,
        marginBottom: 5,
        padding: 5,
    }
}