import React, { useState } from 'react';

import purchaseOrderAPI from "../../api/purchaseOrderAPI";


import Header from '../../app/AdminComponents/Header';

import { useNavigate } from "react-router-dom";
import {   ThreeDots } from "react-loader-spinner";
///import { useParams } from "react-router-dom"; 

import PoSummary from "../../app/AdminComponents/POSummary";
import moment from 'moment-timezone';
// eslint-disable-next-line
import localization from 'moment/locale/es'
import Styles from "../../styles";

import { useDispatch, useSelector } from 'react-redux';

moment.locale('es');


const RFQList = ({
    purchaseOrderList,
    puchaseOrdersItems,
    itemsPendingPO
}) => {

    const navigate = useNavigate();


    return <div>

        {
            purchaseOrderList.map((po, ix) => {
                po.itemsCount = puchaseOrdersItems.filter(i => i.poId === po.id);
                return <button
                    key={ix}
                    style={{
                        backgroundColor: ix % 2 ? '#e0e0e0' : '#fff',
                        borderStyle: 'none',
                        width: '100%',
                        textAlign: 'left',
                        padding: 5
                    }}
                    onClick={() => navigate(`/admin/rfq/${po.id}`)}
                >

                    <PoSummary
                        po={po}
                        itemsPendingPO={itemsPendingPO.filter(i => i.poId === po.id)}
                    />
                </button>
            })
        }
    </div>
}

const RFQS = () => {

    const navigate = useNavigate();
    const [fetching2, setFetching2] = useState(false);
    const [puchaseOrdersList, setPuchaseOrdersList] = useState([]);
    const [puchaseOrdersItems, setPuchaseOrdersItems] = useState([]);
    const [itemsPendingPO, setItemsPendingPO] = useState([]);
    //const [autoPricingStatus, setAutoPricingStatus] = useState({ status: "STOPPED" });
    const dispatch = useDispatch();
    const dataReducer = useSelector((state) => state.dataReducer);
    let {
        autoPricingStatus
    } = dataReducer;

    const setAutoPricingStatus = ({ status }) => {
        dispatch({
            type: "AUTOPRICE_STATUS_SET",
            status: status
        })
    }

    let fnPOListGet = async () => {
        setFetching2(true);
        let res = await purchaseOrderAPI.poListGet();
        setFetching2(false);

        if (res.success) {
            setPuchaseOrdersList(res.pos);
            setPuchaseOrdersItems(res.po_items);
            setItemsPendingPO(res.items_accepted_pending_po);

            if (res.autopricing) {
                let _autopricing = res.autopricing[0];
                setAutoPricingStatus({ status: _autopricing.status });
                if (_autopricing.status === "RUNNING") {
                    setTimeout(() => {
                        fnCheckAutoPricingStatus();
                    }, 5000);
                }

            }


        }
    }

    /* eslint-disable */

    React.useEffect(() => {
        fnPOListGet();
    }, []);
    /* eslint-enable */

    if (!localStorage.getItem("token")) {
        navigate("/login")
        return null
    }


    const fnCheckAutoPricingStatus = () => {
        purchaseOrderAPI.autoPriceStatusGet().then(res => {
            if (res.autopricing[0].status === "RUNNING") {
                setAutoPricingStatus({ status: "RUNNING" });
                setTimeout(() => {
                    fnCheckAutoPricingStatus();
                }, 5000);
            } else {
                // setAutoPricingStatus({ status: "STOPPED" });
                fnPOListGet();
            }
        });
    }

    const fnAutoPrice = async () => {
        setAutoPricingStatus({ status: "RUNNING" });
        setFetching2(true);
        await purchaseOrderAPI.autoPriceStart();
        setFetching2(false);
        setTimeout(fnPOListGet, 1000)
        //fnCheckAutoPricingStatus();

    }

    return (
        <div>

            <Header />

            <Styles.PageTitle>Requests for Quote</Styles.PageTitle>

            <main style={{
                margin: "auto",
                maxWidth: 500,
                minWidth: 350,
                fontFamily: 'Montserrat',

            }}>
                <div style={{
                    padding: 10,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    {(
                        fetching2
                        ||
                        autoPricingStatus.status === "RUNNING"
                    ) ?
                        <span></span>
                        :
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                flex: 1
                            }}
                        >
                            <Styles.ButtonMain
                                style={{
                                    width: 120
                                }}
                                onClick={() => {
                                    fnAutoPrice();
                                }}
                            >
                                <span style={{ color: '#fff' }}>AutoPrice</span>
                            </Styles.ButtonMain>

                            <Styles.ButtonSecondary
                                onClick={() => navigate("/admin/hapi")}
                            >
                                Request Human API
                            </Styles.ButtonSecondary>
                        </div>

                    }


                    {autoPricingStatus?.status === "RUNNING" ?

                        <span style={{
                            backgroundColor: 'green',
                            borderRadius: 5,
                            padding: 5,
                            color: '#fff',
                            flexDirection: 'row',
                            alignItems: 'center',
                            display: 'flex'
                        }}>
                            &nbsp;
                            Pricing &nbsp;
                            &nbsp;
                            <ThreeDots
                                type={"ThreeDots"}
                                color="#fff"
                                height={20}
                                width={50}
                                timeout={25000}
                            />
                            &nbsp;
                        </span>
                        :
                        ""

                    }
                </div>

                {fetching2 ?
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: 50
                    }}>
                        <ThreeDots 
                            color="#FF9400"
                            height={20}
                            width={70}
                            timeout={15000}
                        />
                    </div>
                    :


                    <RFQList
                        purchaseOrderList={puchaseOrdersList}
                        puchaseOrdersItems={puchaseOrdersItems}
                        itemsPendingPO={itemsPendingPO}
                    />


                }


            </main>
        </div>
    );
}


export default RFQS;