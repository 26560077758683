import React from 'react'

import Menu from '../app/components/Menu';

///import { ThreeDots } from "react-loader-spinner";
///import { useParams } from "react-router-dom";
///import pageUserAPI from '../api/pageUserAPI';
///import { ThreeDots } from "react-loader-spinner";
import moment from 'moment-timezone';
// eslint-disable-next-line
import localization from 'moment/locale/es'
 
import { useSelector } from 'react-redux';

import {IoLogoWhatsapp   } from "react-icons/io5";
moment.locale('es');

const Help = () => {

    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        CEL_WHATSAPP,
        NUMERO_WHATSAPP, 
    } = dataReducer;

    return (
        <div>
            <Menu />
            <header
                style={{
                    backgroundColor: "#FF9400"
                }}
            >
                <div style={{
                    margin: "auto",
                    maxWidth: 1200,
                    minWidth: 350,
                    flexDirection: "row",
                    display: 'flex',
                    justifyContent: "space-between",
                    alignContent: "center",
                    alignItems: "center",

                }}>
                    <div style={{ width: 80 }} />
                    <img alt='ALANI 360 logo'
                        src='/images/alani_logos_vertical_horizontal_orange_white.svg#svgView(viewBox(320, 90, 260, 70))'
                        style={{
                            width: 120, height: 60
                        }}
                    />
                    <div style={{ width: 100 }} />
                </div>
            </header>


            <main style={{
                margin: "auto",
                maxWidth: 550,
                minWidth: 350,
                fontFamily: 'Montserrat',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column'

            }}>


                 
                <div style={{
                    display: 'flex',
                    flexDirection: 'row', 
                    width: '100%',
                    fontSize: 12, 
                    paddingBottom: 5,
                    backgroundColor: '#f8f8f8',
                    flexWrap: 'wrap'
                }}>
                     
                </div> 

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignContent: 'center',
                        flexDirection: 'column',
                        padding: 15

                    }}
                >   
                <div
                style={{
                    textAlign: 'justify'
                }}
            >


            <div style={{  
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',  
                paddingLeft: 40
            }}>
               
                 
                <h1>Alani Garage</h1> 

                <img alt='ALANI 360 logo'
                    src='/images/alani_logos_vertical_horizontal_orange_white.svg#svgView(viewBox(320, 90, 260, 70))'
                    style={{
                        width: 130, 
                        height: 60, 
                        margin: 'auto'
                    }}
                />
            </div>
        


            <div
                style={{
                    marginTop: 10,
                    textAlign: 'center'
                }}
            >
                
                Alani360 te ofrece autopartes, incluyendo el costo de envío y aduana.
                <br/><br/>
                Contáctanos desde tu número de WhatsApp para cotizaciones y compras.
                <br/><br/>
                <a href={CEL_WHATSAPP} ><IoLogoWhatsapp size={20} /> Whatsapp  {NUMERO_WHATSAPP}</a>
           
                <br/>

                <h2>Ejemplos:</h2> 
                <a href={`${CEL_WHATSAPP}?text=Nissan%20G60339UEAA`} >Nissan G60339UEAA</a>
                
                <br/><br/>

                <a href={`${CEL_WHATSAPP}?text=Mazda%20PEHH15200`} >Mazda PEHH15200</a>
                <br/><br/>

                <a href={`${CEL_WHATSAPP}?text=Toyota%206700535311`} >Toyota 6700535311</a>
           
            </div>
             
            <br/><br/>


 
            
             

            </div>
                   
                </div>

            </main>
        </div>
    );
}


export default Help;
 