import React from 'react'
import { useParams, useNavigate } from "react-router-dom";


import { useCookies } from 'react-cookie';
import Menu from '../app/components/Menu';

import moment from 'moment-timezone';
// eslint-disable-next-line
import localization from 'moment/locale/es'

import { useSelector, useDispatch } from 'react-redux';


import Paquetes from "../app/components/Paquetes";
import AlaniHeaderLogo from "../app/components/AlaniHeaderLogo";

import {
    IoPersonCircle,
} from "react-icons/io5";
import { Circles } from 'react-loader-spinner';
moment.locale('es');





const Prealerta = () => {
    let token = useParams().token;
    let cookies = useCookies(['alani360']);
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        userData,
    } = dataReducer;

    /* eslint-disable */
    React.useEffect(() => {
        let _phone = (cookies[0] && cookies[0].alani360 && ("+" + cookies[0].alani360.phoneNumber))

        dispatch({
            type: "PHONE_SET",
            phone: _phone
        });
        dispatch({
            type: "USER_DATA_FETCH",
            token,
            phone: _phone
        });

    }, [token]);
    /* eslint-enable */



    return (
        <div>

            <Menu />
            <header
                style={{
                    backgroundColor: "#FF9400"
                }}
            >
                <div style={{
                    margin: "auto",
                    maxWidth: 1200,
                    minWidth: 350,
                    flexDirection: "row",
                    display: 'flex',
                    justifyContent: "space-between",
                    alignContent: "center",
                    alignItems: "center",

                }}>
                    <div style={{ width: 80 }} />
                    <AlaniHeaderLogo />
                    <div style={{ width: 100 }} />
                </div>
            </header>


            <div
                style={{

                    fontFamily: 'Montserrat',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignContent: 'center',
                    width: '100%',
                    maxWidth: 650,
                    minWidth: 350,
                    flexDirection: 'column',
                }}
            >


                <div style={{
                    marginTop: 0,
                    paddingTop: 0,
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>

                    {userData && userData.accountInfo && (userData.accountInfo.firstName) ?
                        <div
                            style={{
                                width: '100%',
                                borderBottomStyle: 'solid',
                                borderBottomWidth: 1,
                                borderBottomColor: '#c0c0c0',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <button
                                onClick={() => navigate(`/p/${token}`)}
                                style={{
                                    textAlign: 'center',
                                    flex: 1,
                                    justifyContent: 'center',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    borderStyle: 'none',
                                    backgroundColor: 'transparent',
                                    padding: 10
                                }}>

                                <IoPersonCircle
                                    size={20}
                                    style={{
                                        marginRight: 5
                                    }}
                                />
                                {userData.accountInfo.firstName} {userData.accountInfo.lastName}

                            </button>
                        </div>
                        :
                        <div style={{ padding: 20 }}>
                            <Circles
                                color={`#FF9400`}
                            />
                        </div>
                    }
                </div>



            </div>
            <br />
            <Paquetes
                token={token}
            />
        </div>
    );
}


export default Prealerta;