import React from "react";
import { useNavigate } from "react-router-dom";

import { ThreeDots } from "react-loader-spinner";
import Styles from "../styles";

import authAPI from "../api/authAPI";

function Login() {
    const [fetching2, setFetching2] = React.useState(false);
    const [email, setEmail] = React.useState(localStorage.getItem("email") || "");
    const [password, setPassword] = React.useState("");

    const SetEmail = (e) => {
        e = e.toLowerCase();
        setEmail(e)
    }

    const navigate = useNavigate();
    const login = () => {

        setFetching2(true)
        authAPI.login({
            u: email.toLowerCase(),
            p: password
        }).then(res => {
            setFetching2(false)

            if (res.success) {
                localStorage.setItem("email", email.toLowerCase());
                localStorage.setItem("token", res.token);
                localStorage.setItem("userName", res.name);
                navigate("/admin/?x=" + Math.floor(Math.random() * 10));
                window.location.reload();
            } else {
                alert("[Sorry]")
            }
        });

    }

    return (<div

    >

        <header
            style={{
                backgroundColor: "#FF9400",
                padding: 0
            }}
        >
            <div style={{
                margin: "auto",
                maxWidth: 1200,
                minWidth: 350,
                flexDirection: "row",
                display: 'flex',
                justifyContent: "space-between",
                alignContent: "center",
                alignItems: "center",

            }}>
                <div style={{ width: 80 }} />
                <button
                    style={{
                        backgroundColor: 'transparent',
                        borderStyle: 'none'
                    }}
                    onClick={() => navigate("/admin/")}
                >
                    <img alt='ALANI 360 logo'
                        src='/images/alani_logos_vertical_horizontal_orange_white.svg#svgView(viewBox(320, 90, 260, 70))'
                        style={{
                            width: 120, height: 60
                        }}
                    />
                </button>
                <div style={{ width: 100 }} />
            </div>
        </header>
        <div style={{
            margin: "auto",
            marginTop: 20,
            width: 350,
            flexDirection: "column",
            display: 'flex',
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
        }}>

            <label
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: 250,
                }}
            >
                Email
                <input

                    type="text"
                    value={email}
                    style={{
                        width: 250,
                        marginBottom: 5,
                        textTransform: "lowercase"
                    }}
                    onChange={e => SetEmail(e.target.value)}

                />
            </label>

            <label
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: 250
                }}
            >
                Password
                <input
                    type="password"
                    value={password}
                    maxLength={10}
                    style={{
                        width: 100,
                        marginBottom: 5
                    }}
                    onChange={e => setPassword(e.target.value)}

                />

                <Styles.ButtonMain onClick={login}>

                    {fetching2 ?
                        <ThreeDots 
                            color="#FFFFFF"
                            height={10}
                            width={80}
                            timeout={5000}
                        />
                        :

                        <span style={{
                            width: 80
                        }}>Login</span>

                    }
                </Styles.ButtonMain>
            </label>


        </div>
    </div>
    );
}

export default Login;