import React from 'react'

import { useCookies } from 'react-cookie';
import {
    useParams,
    useNavigate,
} from "react-router-dom";

import { ThreeDots } from "react-loader-spinner";
import moment from 'moment-timezone';

// eslint-disable-next-line
import localization from 'moment/locale/es'
import {
    IoReload,
    IoPersonCircle,
    IoChevronBackOutline
} from "react-icons/io5";




import { useDispatch, useSelector } from 'react-redux';

import UserAccount from "../app/components/UserAccount";


moment.locale('es');




const Loading = () => {
    return <div
        style={{
            margin: 50,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}
    ><ThreeDots
            type="ThreeDots"
            color="#FF9400"
            height={50}
            width={50}
        /></div>
}


const PageInfo = ({
    token
}) => {

    // eslint-disable-next-line
    const [cookies, setCookie] = useCookies(['alani360']);
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        userData,
        fetching,
        account_address_select
    } = dataReducer;


    const Header = () => <div
        style={{
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'space-between',
            alignContent: 'center',
            alignItems: 'center',
            borderBottomStyle: "solid",
            borderBottomColor: "#c0c0c0",
            borderBottomWidth: 1,
            padding: 5,
            backgroundColor: '#e0e0e0',

        }}
    >
        <span style={{
            fontWeight: 'bold',
        }}>{userData.accountInfo.firstName} {userData.accountInfo.lastName}</span>
        <div
            style={{
                flexDirection: 'row',
                display: 'flex',
                alignContent: 'center',
                alignItems: 'center',

            }}
        >
            <span style={{
                marginRight: 10
            }}>
                {userData.accountInfo.transexpressPoBox}
            </span>

            <IoPersonCircle
                style={{ fill: userData.accountInfo.shippingAddressConfirmed === 0 ? 'red' : userData.accountInfo.shippingAddressConfirmed === 1 ? 'orange' : 'blue' }}
                size={30}
            />


        </div>

    </div>


    if (fetching || !userData) {
        return <Loading />
    }
    if (!userData.success) {
        return <div>TOKEN NO ES VALIDO</div>
    }


    return <div
        style={{
            padding: 0,
            textAlign: 'center'
        }}

    >
        {account_address_select === undefined && <Header />
        }
        <UserAccount
            token={token}
        />



    </div>
}


const Page = () => {
    const phone_cookie = useCookies(['alani360']);
    let phone = phone_cookie[0]?.alani360?.phoneNumber

    let token = useParams().token;

    const navigate = useNavigate();

    // eslint-disable-next-line
    const [cookies, setCookie] = useCookies(['alani360']);


    const dispatch = useDispatch();
    const dataReducer = useSelector((state) => state.dataReducer);
    const {
        fetching,
        userData,
    } = dataReducer;


    /* eslint-disable */
    React.useLayoutEffect(() => {
        dispatch({
            type: "USER_DATA_FETCH",
            token,
            phone
        });
    }, [token]);

    React.useLayoutEffect(() => {
        if (userData && !userData.success) {
            navigate(`/p/${token}`)
        };
    }, [userData]);
    /* eslint-enable */


    return (
        <div>
            <header
                style={{
                    backgroundColor: "#FF9400"
                }}
            >

                <div style={{
                    margin: "auto",
                    maxWidth: 1200,
                    minWidth: 350,
                    flexDirection: "row",
                    display: 'flex',
                    justifyContent: "space-between",
                    alignContent: "center",
                    alignItems: "center",

                }}>
                    <button
                        style={{
                            width: 50,
                            paddingRight: 10,
                            height: 40,
                            backgroundColor: 'transparent',
                            borderStyle: 'none'
                        }}
                        onClick={() => navigate(`/p/${token}`)}
                    >
                        <IoChevronBackOutline
                            size={30}
                        />
                    </button>
                    <img alt='ALANI 360 logo'
                        src='/images/alani_logos_vertical_horizontal_orange_white.svg#svgView(viewBox(320, 90, 260, 70))'
                        style={{
                            width: 120, height: 60
                        }}
                    />
                    <button
                        onClick={() => {

                            dispatch({
                                type: "USER_DATA_FETCH",
                                phone,
                                token
                            });
                        }
                        }
                        style={{
                            backgroundColor: 'transparent',
                            borderWidth: 0,
                            width: 70
                        }}
                    >
                        <IoReload
                            size={25}
                        />
                    </button>

                </div>
            </header>

            <main style={{
                margin: "auto",
                maxWidth: 1200,
                minWidth: 350,
                fontFamily: 'Montserrat'
            }}>
                {fetching ?
                    <Loading />
                    :
                    <PageInfo
                        token={token}
                    />
                }

            </main>


        </div>
    );
}


export default Page;

