import React from 'react';
import { useCookies } from 'react-cookie';
import {
    useParams,
    useNavigate,
    useSearchParams
} from "react-router-dom";



const tokenValidate = ( token ) => {
    //arn:aws:lambda:us-east-1:645160913840:function:ALANI_TOKEN_VALIDATE
    return new Promise((resolve, reject) => {
        
        let url = `https://ra964zmy81.execute-api.us-east-1.amazonaws.com/v1/token/${token}`;
        var headers = new Headers(); 
        //headers.append("x-api-key", config.apiGateway.api_key);
        fetch(url, {
            headers,
            method: 'GET',
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (jsonResponse) {
                resolve(jsonResponse);
            }).catch(err => {
                reject(err);
            });
    })
}

const WhatsappLandingPage = () => {
      /* eslint-disable */
      const [searchParams, setSearchParams] = useSearchParams();
      /* eslint-enable */

    let tel = searchParams.get("p"); 
    const navigate = useNavigate();

    let token_encoded = useParams().token_encoded;

    /* eslint-disable */
    const [cookies, setCookie] = useCookies(['alani360']);
    React.useEffect(() => {
        //let tk = atob(token_encoded) ; 
        if (!tel){
            console.log("get phone using token")
            tokenValidate(token_encoded).then( res =>{
               
                if (res.success){
                    setCookie('alani360', {
                        phoneNumber: res.tel,
    
                    }, { path: '/' });
    
                    navigate(`/p/${token_encoded}?${searchParams}`);
                } 
            });
        }else{ 
            if (token_encoded) {
                setCookie('alani360', {
                    phoneNumber: tel,

                }, { path: '/' });

                navigate(`/p/${token_encoded}`);
            }
        }

    }, []);
    /* eslint-enable */



    return <div
                style={{
                    paddingTop: 50,
                    width: 300,
                    margin: 'auto', 
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <div
                    style={{
                        backgroundColor: '#FF9400',
                        padding: 20,
                        paddingTop: 10,
                        paddingBottom: 10,
                        margin: 20,
                        borderRadius: 10
                    }}
                >
                    <img alt='ALANI 360 logo'
                        src='/images/alani_logos_vertical_horizontal_orange_white.svg#svgView(viewBox(320, 90, 260, 70))'
                        style={{
                            width: 120, height: 60
                        }}
                    />  
                </div>
                
                Actualizando...
    </div>
}



export default WhatsappLandingPage;